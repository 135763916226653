<div class="virtual-voucher" *ngIf="!!this.tokenStorageService.getToken() && !this.tokenStorageService.isAdmin()">
  <img class="logo" src="../app/assets/img/leisurebreaks.svg" [alt]="'app-title' | translate">
  <div class="text">
    <img src="../../../app/assets/img/voucher.png" height="21" width="30" alt="virtual voucher"/>
    <span>Virtual Voucher</span>
  </div>
</div>

<mat-toolbar class="main-toolbar">
  <mat-toolbar-row class="main-toolbar-row mat-elevation-z2">

    <ng-container *ngIf="!!this.tokenStorageService.getToken()">
      <ng-template *ngFor="let item of appConfiguration; index as i" [ngxPermissionsOnly]="item.permissions || ['ROLE_ADMIN']">
        <button class="menu-button" mat-button routerLinkActive="active" [routerLink]="item.route" [disabled]="item.disabled" (click)="onClick(i)" [matTooltip]="item.name | translate" [matTooltipDisabled]="this.tokenStorageService.isAdmin()">
          <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
            <mat-icon fxHide fxShow.lt-md>{{ item.icon }}</mat-icon>
            <span class="menu-text" fxShow fxHide.lt-md>{{ item.name | translate }}</span>
          </span>
        </button>
      </ng-template>
    </ng-container>
    <span class="spacer"></span>

    <button class="menu-button" mat-button [matMenuTriggerFor]="menu" [matTooltip]="'settings' | translate | uppercase">
        <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <span fxShow fxHide.lt-md *ngIf="!!this.tokenStorageService.getToken()">{{ (this.tokenStorageService.getUser()?.golfclub?.name || 'Admin') | translate }}</span>
          <mat-icon>settings</mat-icon>
        </span>
    </button>

    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item [matMenuTriggerFor]="languageMenu">
        <mat-icon>language</mat-icon>
        <span>{{ 'change language' | translate }}</span>
      </button>

      <button *ngIf="!!this.tokenStorageService.getToken()" mat-menu-item (click)="onLogout()">
        <mat-icon>logout</mat-icon>
        <span>{{ 'logout' | translate }}</span>
      </button>
    </mat-menu>

    <mat-menu #languageMenu="matMenu" xPosition="before">
      <button *ngFor="let lang of languages" mat-menu-item (click)="onChangeLanguage(lang)" [disabled]="translate.currentLang === lang">
        <span class="mat-icon flag-icon flag-icon-{{ lang === 'en' ? 'gb' : lang }}"></span>
        <span>{{ lang | translate  }}</span>
      </button>
    </mat-menu>

  </mat-toolbar-row>

  <mat-toolbar-row class="main-toolbar-row mat-elevation-z0" [@slideInOut]="appConfiguration[currentIndex]?.children ? 'in' : 'out'">
    <span fxHide fxShow.gt-md class="spacer"></span>
    <button class="menu-button" *ngFor="let child of appConfiguration[currentIndex]?.children" mat-button routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="openRoute(appConfiguration[currentIndex].route + '/' + child.route, child.endpoint)" [matTooltip]="child.name | translate" [matTooltipDisabled]="this.tokenStorageService.isAdmin()">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.lt-md>{{ child.icon }}</mat-icon>
        <span fxShow fxHide.lt-md>{{ child.name | translate }}</span>
      </span>
    </button>
    <span class="spacer"></span>
  </mat-toolbar-row>


</mat-toolbar>

<!--<div [ngClass]="this.tokenStorageService.isAdmin() ? 'main-container' : ''"  [ngStyle]="appConfiguration[currentIndex]?.children ? {height: 'calc(100% - 128px)'} : {height: 'calc(100% - 64px)'}">-->
<div [ngClass]="this.tokenStorageService.isAdmin() ? 'main-container' : ''">
  <div class="loading-shade" *ngIf="isLoading">
    <mat-spinner [diameter]="size" [style.top]="offset" [style.left]="offset" color="primary"></mat-spinner>
  </div>
  <router-outlet></router-outlet>
</div>
<footer class="footer" *ngIf="!this.tokenStorageService.isAdmin()">
  <p>© LeisureBREAKS GmbH <small>powered by <a href="https://www.soul-surf.com/" target="_blank">SoulSurf</a></small></p>
  <p>
    {{ 'Do you need help with our system?' | translate  }}<br>
    {{ 'Please contact our support via phone' | translate }}: {{ 'app-phone' | translate }}
    {{ 'or email' | translate }}: <a href="mailto:{{ 'app-email' | translate }}">{{ 'app-email' | translate }}</a>
  </p>
</footer>

<router-outlet name="edit"></router-outlet>
<router-outlet name="detail"></router-outlet>

<ng-template #helpModal>
  <h1 mat-dialog-title>{{ 'Do you need help with our system?' | translate  }}</h1>
  <mat-dialog-content>
    <h3>
      {{ 'Please contact our support via phone' | translate }}: {{ 'app-phone' | translate }}
      {{ 'or email' | translate }}: <a href="mailto:{{ 'app-email' | translate }}">{{ 'app-email' | translate }}</a>
    </h3>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-dialog-close mat-stroked-button color="primary">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-container *ngIf="this.tokenStorageService.getToken() && !this.tokenStorageService.isAdmin()">
  <mat-card class="news-card mat-elevation-z0" *ngFor="let n of news; index as i" style="padding-bottom: 0;">

    <button mat-flat-button class="close-button" (click)="dismiss(i)" style="right: -16px; top: -16px; border-top-right-radius: 5px !important;">
      <mat-icon class="close-icon">close</mat-icon>
    </button>

    <h3>{{ n.title }}</h3>

    <mat-card-content>
      <div style="font-size: small" [innerHTML]="n.txt"></div>
    </mat-card-content>

    <mat-card-actions></mat-card-actions>

  </mat-card>
</ng-container>
