import { Component, OnInit } from '@angular/core';
import { APIPlatformPagedCollection, AppService, NewsText } from '../../shared/services/app.service';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public news: NewsText[] = [];
  public date = moment().format();
  public dateRage: Moment[] = [];
  public weekDays: string[] = moment.weekdays(true);

  private dateFormat = 'dd.MM.yyyy';

  constructor(private appService: AppService) {
    const start = moment().startOf('month').startOf('week');
    const end = moment().endOf('month').endOf('week');
    this.dateRage = this.appService.getRangeOfDates(start, end, 'day');
  }

  ngOnInit(): void {
    this.appService.isLoading.next(true);
    const today = new Date().toISOString();
    const params = new HttpParams().set('expiry_tstamp[after]', today).set('release_tstamp[before]', today).set('status', 'A');
    this.appService.list('newstexts', params).subscribe(
      (result: APIPlatformPagedCollection) => {
        this.news = result['hydra:member'];
      },
      () => null,
      () => this.appService.isLoading.next(false)
    );
  }

  public isToday(date: Moment): boolean {
    return moment(date).format(this.dateFormat.toUpperCase()) === moment().format(this.dateFormat.toUpperCase());
  }

}
