import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
import { Action, APIPlatformPagedCollection, AppService, Book, Condition, Configuration, Country } from '../../../shared/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { ExcelService } from '../../../shared/services/excel.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: string[] = [];
  public dataSource: MatTableDataSource<APIPlatformPagedCollection>;

  public filterChanged: EventEmitter<string> = new EventEmitter();
  public countryChanged: EventEmitter<string> = new EventEmitter();
  public statusChanged: EventEmitter<string> = new EventEmitter();
  public bookChanged: EventEmitter<string> = new EventEmitter();
  public paymentChanged: EventEmitter<string> = new EventEmitter();

  private dataSubscription: Subscription;
  private routeSubscription: Subscription;

  public countryList: Country[] = [];
  public bookList: Book[] = [];
  public paymentStatus: string[] = ['', 'paid', 'underpaid', 'paid-changed', 'initialized', 'not-finished'];

  public filter: string;
  public status: string[] = [];
  public countryId: number[] = [];
  public bookId: string[] = [];
  public payment: string;

  public defaultPageSizeOptions = [10, 15, 30, 50, 100];
  public defaultPageSize: number;
  public resultsLength: number | undefined;

  public currentRoute: string;
  public currentConfiguration: Configuration | undefined;
  public showScrollButton: boolean;

  @HostListener('window:scroll') checkScroll(): void {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.showScrollButton = scrollPosition >= 100;
  }

  constructor(private currency: CurrencyPipe, private route: ActivatedRoute, private router: Router, @Inject(LOCALE_ID) private locale: string, private changeDetector: ChangeDetectorRef, private appService: AppService, private translate: TranslateService, private excelService: ExcelService) {
    this.filterChanged.subscribe((text: string) => this.filter = text);
    this.countryChanged.subscribe((id: number[]) => this.countryId = id);
    this.statusChanged.subscribe((status: string[]) => this.status = status);
    this.bookChanged.subscribe((id: string[]) => this.bookId = id);
    this.paymentChanged.subscribe((status: string) => this.payment = status);
    this.appService.refresh.subscribe(() => this.initTable());
  }

  ngOnInit(): void {
    this.appService.getCountries().subscribe((result: APIPlatformPagedCollection) => this.countryList = result['hydra:member'] as Country[]);
    this.appService.getBooks(true).subscribe((result: APIPlatformPagedCollection) => this.bookList = result['hydra:member'] as Book[]);
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.params.subscribe((params: Params) => {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }

      this.currentRoute = Object.values(params).join('/');
      this.currentConfiguration = this.appService.getCurrentConfiguration(this.currentRoute);
      this.defaultPageSize = Number(localStorage.getItem('pageSize')) || this.defaultPageSizeOptions[0];
      this.payment = '';
      this.status = [];
      this.filter = '';
      this.countryId = [];
      this.bookId = [];
      this.displayedColumns = [];
      this.changeDetector.detectChanges();

      this.initTable();
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  /** inits table data   */
  private initTable(): void {
    // define columns and actions
    const endpoint = this.currentRoute;
    const currentColumns = [...this.currentConfiguration?.columns || [], ...this.currentConfiguration?.actions?.map((action: Action) => action.column) || []];
    this.paginator.firstPage();
    this.paginator.pageSize = this.defaultPageSize;
    this.sort.active = currentColumns[0];
    this.sort.direction = 'desc' as SortDirection;

    this.dataSubscription = merge(
      this.sort.sortChange,
      this.paginator.page,
      this.paymentChanged.pipe(map(() => this.resetPaging())),
      this.bookChanged.pipe(map(() => this.resetPaging())),
      this.countryChanged.pipe(map(() => this.resetPaging())),
      this.statusChanged.pipe(map(() => this.resetPaging())),
      this.filterChanged.pipe(debounceTime(this.appService.filterDelay), map(() => this.resetPaging())))
      .pipe(
        startWith({}),
        switchMap(() => {
          localStorage.setItem('pageSize', String(this.paginator.pageSize));
          // this.appService.isLoading.next(true);

          return this.appService.list(endpoint, this.getHttpParams()).pipe(
            map((result: APIPlatformPagedCollection) => {
              if (endpoint === 'guides') {
                result['hydra:member'].map((guide: any) => guide.book = this.bookList.find((book: Book) => book['@id'] === guide.book)?.name);
              }
              return result;
            })
          );
        }),
        map((data: APIPlatformPagedCollection) => {
          // this.appService.isLoading.next(false);
          this.resultsLength = data['hydra:totalItems'];
          this.displayedColumns = currentColumns;
          return data;
        }),
        catchError(() => {
          // this.appService.isLoading.next(false);
          return observableOf([]);
        })
      ).subscribe((data: any) => this.dataSource = new MatTableDataSource(data['hydra:member']));
  }

  private getHttpParams(override?: Params[]): HttpParams {
    let paramsObj = {
      'country_id[]': String(this.countryId),
      'delivery_country_code[]': this.countryList.filter((c: Country) => this.countryId.includes(c.id)).map((c: Country) => c.code),
      'status[]': this.status.filter(item => item !== 'is_activated'),
      search: String(this.filter && !isNaN(Number(this.filter)) ? Number(this.filter) : String(this.filter).trim()),

      page: String(this.paginator.pageIndex + 1),
      ['order[' + this.sort.active + ']']: this.sort.direction,
      itemsPerPage: String(this.paginator.pageSize)
    };

    if (this.payment === 'not-finished') {
      paramsObj['exists[payment_status]'] = 'false';
    } else if (this.payment !== '') {
      paramsObj.payment_status = this.payment;
    }

    if (this.status.find(item => item === 'is_activated')) {
      paramsObj.is_activated = 'true';
    }

    if (this.bookId.length) {
      paramsObj[this.currentRoute === 'books' ? 'id[]' : 'book[]'] = this.bookId;
      paramsObj['bookGolfClub.book'] = this.bookId;
      // paramsObj['bookGolfClub.status'] = 'A';
    }

    override?.map((value: { [key: string]: string | number }) => paramsObj = Object.assign(paramsObj, value));
    Object.keys(paramsObj).forEach((key: string) => (paramsObj[key] === null || paramsObj[key] === '' || paramsObj[key] === 'undefined' || paramsObj[key] === undefined) && delete paramsObj[key]);
    return new HttpParams({fromObject: paramsObj});
  }

  public export(): void {
    this.appService.isLoading.next(true);
    this.appService.list(this.currentRoute, this.getHttpParams([{itemsPerPage: this.resultsLength}])).subscribe(
      (result: APIPlatformPagedCollection) => this.excelService.exportAsExcelFile(result['hydra:member'], this.currentRoute),
      (error: HttpErrorResponse) => console.log(error),
      () => this.appService.isLoading.next(false)
    );
  }

  /** reset page index */
  public resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  /** handle row click */
  public routerLink(uuid: string, type: string): void {
    if (this.currentConfiguration?.edit) {
      type = type ? '_' + type.toLowerCase() : '';
      this.router.navigate([{outlets: {edit: uuid.substr(1) + '/edit' + type}}]).then();
    }
  }

  /** format row cell */
  public formatRow(column: string | any, row: string[]): string {
    let value = row[column];

    // format boolean columns
    if (this.currentConfiguration?.format?.toBoolean?.includes(column)) {
      return `<span class="material-icons primary">${!!value ? 'check_box' : 'check_box_outline_blank'}</span>`;
    }

    if (column === 'payment_status') {
      value = value || 'not-finished';
    }

    if (column === 'guide_own_usage') {
      return `<span class="material-icons primary">${value === 'true' ? 'check_box' : 'check_box_outline_blank'}</span>`;
    }

    if (['used_vouchers_cnt', 'associated_clubs', 'associated_guides'].includes(column)) {
      value = value || '0';
    }

    if (!value) {
      return '';
    }

    // format date columns
    if (this.currentConfiguration?.format?.toDate?.includes(column)) {
      const dateTime: Moment = moment(value, ['YYYY-MM-DD HH:mm:ss']);
      return (dateTime.isDST() ? dateTime.add(2, 'hour') : dateTime.add(1, 'hour')).format('DD.MM.YYYY HH:mm');
    }

    if (column === 'price') {
      value = this.currency.transform(value, 'EUR') || '';
    }

    // translate payment status
    if (column === 'payment_status' && this.paymentStatus.includes(value)) {
      value = this.translate.instant(value);
    }

    if (column === 'country_id') {
      // @ts-ignore
      return this.countryList.find((c: Country) => c.id === value).name;
    }

    if (['serial_nr', 'serial_nr_from', 'serial_nr_to'].includes(column)) {
      value = String(value).padStart(5, '0');
    }

    return value;
  }

  /** handle table filter */
  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterChanged.emit(filterValue);
  }

  /** scroll to top action */
  public scrollToTop(): void {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  }

  /** either is an action or not */
  public isAction(column: string): boolean {
    return !!this.currentConfiguration?.actions?.some((action: Action) => action.column === column);
  }

  /** either condition is true or false */
  public checkCondition(conditions: Condition[] | undefined, row: any): boolean {
    return !conditions?.length || conditions.every((condition: Condition) => {
      return condition.key === 'used_vouchers_cnt' ? row.used_vouchers_cnt >= condition.value : row[condition.key] === condition.value;
    });
  }

  /** get action object */
  public getAction(column: string): Action | undefined {
    return this.currentConfiguration?.actions?.find((action: Action) => action.column === column);
  }

  /** get status object */
  public getStatus(status: string): { text: string, icon: string, color: string } {
    const statusText = this.translate.instant(status || '-');
    switch (status) {
      case 'O':
        return {text: statusText, icon: 'wifi_off', color: 'warn'};
      case 'A':
        return {text: statusText, icon: 'public', color: 'primary'};
      case 'S':
        return {text: statusText, icon: 'block', color: 'warn'};
      case 'D':
        return {text: statusText, icon: 'delete_forever', color: 'warn'};
      default:
        return {text: status, icon: 'report_problem', color: 'warn'};
    }
  }

  public getCountryByCode(code: string): Country | undefined {
    return this.countryList.find((c: Country) => c.code === code);
  }

  /** get country object */
  public getCountry(column: string | any, row: string[]): Country | undefined {
    return this.countryList.find((c: Country) => c.id === Number(row[column]));
  }

  public clearFilter(): void {
    this.status = [];
    this.filter = '';
    this.countryId = [];
    this.bookId = [];
    this.payment = '';
    this.statusChanged.emit('');
  }
}
