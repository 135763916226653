import { Component, OnInit } from '@angular/core';
import { GuidesComponent } from '../guides.component';
import { Params } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { APIPlatformPagedCollection, Book, Country, Guide } from '../../../../../shared/services/app.service';

@Component({
  selector: 'app-edit-guide',
  templateUrl: '../guides.component.html',
  styleUrls: ['../guides.component.scss']
})
export class EditGuideComponent extends GuidesComponent implements OnInit {

  private params: Params;

  ngOnInit(): void {
    this.editMode = true;
    this.appService.isLoading.next(true);
    this.route.params.subscribe((params: Params) => {

        this.params = params;

        const subscriptions: Observable<any>[] = [
          this.appService.get(params.route, params.uuid),
          this.appService.getCountries()
        ];

        combineLatest(subscriptions).subscribe(
          (result: APIPlatformPagedCollection[]) => {
            const guide = result[0] as any;
            this.guide = guide;

            if (!guide.activation_code) {
              guide.activation_code = String(this.appService.getRandomNumberBetween(100000, 999999));
              this.appService.patch('guides', this.appService.getIdFromUrlString(guide['@id']), guide).subscribe();
            }

            this.countries = result[1]['hydra:member'] as Country[];
            Object.keys(guide).map((key: string) => this.form.patchValue({[key]: guide[key]}));
            this.appService.get(guide.book.substr(1)).subscribe((currentBook: Book) => {
              this.books = [currentBook];
              this.form.patchValue({book: currentBook});
              this.form.get('book')?.disable();
              this.form.get('serial_nr')?.disable();
              this.form.get('activation_code')?.disable();
              this.form.markAsUntouched();
              this.form.markAsPristine();
              this.appService.isLoading.next(false);
              this.format();
            });
          });
      }
    );
  }

  public onSubmit(): void {
    if (this.form.valid && this.form.touched) {
      this.appService.isLoading.next(true);
      const guide = this.form.getRawValue();
      guide.book = (guide.book as Book)['@id'];
      guide.serial_nr = +guide.serial_nr;
      this.appService.patch(this.params.route, this.params.uuid, guide).subscribe(
        () => this.appService.openSnackBar('Daten wurden erfolgreich gespeichert.'),
        () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
        () => {
          this.form.markAsUntouched();
          this.form.markAsPristine();
          this.appService.isLoading.next(false);
        }
      );
    }
  }
}
