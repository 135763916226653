<mat-dialog-content class="mat-typography" *ngIf="news">
  <div>
    <h1>{{ news.title }}</h1>
    <span>
    (<span *ngIf="news.release_tstamp"> {{ 'online from' | translate }}: {{ news.release_tstamp | date }}</span>
    <span *ngIf="news.release_tstamp && news.expiry_tstamp"> - </span>
    <span *ngIf="news.expiry_tstamp">{{ 'online to' | translate }}: {{ news.expiry_tstamp | date }} </span>)
  </span>
  </div>
  <div style="margin: 30px" [innerHTML]="news.txt"></div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close color="warn">
       <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
       </span>
  </button>

  <span class="spacer"></span>

  <button mat-dialog-close mat-stroked-button color="primary" type="button" (click)="onSubmit()">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'delete and close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">delete_forever</mat-icon>
      </span>
  </button>
</mat-dialog-actions>
