import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { APIPlatformPagedCollection, AppService, Book, Country, Guide } from '../../../../shared/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-guide',
  templateUrl: './guides.component.html',
  styleUrls: ['./guides.component.scss']
})
export class GuidesComponent implements OnInit {

  @ViewChild('guideModal', {static: true}) guideModal: TemplateRef<any>;
  private dialogRef: MatDialogRef<TemplateRef<any>, MatDialogConfig>;

  @ViewChild('serialInput', {static: true}) serialInput: ElementRef;
  @ViewChild('emailInput', {static: true}) emailInput: ElementRef;

  public form: FormGroup;
  public books: Book[] | undefined = [];
  public countries: Country[] | undefined = [];
  public idRange: { serial_nr_from: number, serial_nr_to: number } = {serial_nr_from: 0, serial_nr_to: 0};
  public editMode = false;
  public bookId: string;
  public guide: Guide | undefined;

  constructor(public route: ActivatedRoute, public formBuilder: FormBuilder, public appService: AppService, private router: Router, public dialog: MatDialog) {
    this.form = this.formBuilder.group({
      book: new FormControl('0', []),
      serial_nr: new FormControl(null, []),
      activation_code: new FormControl(null, [Validators.pattern('^[0-9]*$')]),
      salutation_id: new FormControl(null, []),
      first_name: new FormControl(null, []),
      last_name: new FormControl(null, []),
      email: new FormControl(null, [Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]),
      street: new FormControl(null, []),
      postal_code: new FormControl(null, []),
      city: new FormControl(null, []),
      country_id: new FormControl(null, []),
      status: new FormControl('A', []),
      is_activated: new FormControl({value: null, disabled: true}),
    });
  }


  ngOnInit(): void {
  }

  public openModal(data: 'delete' | 'reset'): void {
    this.dialogRef = this.dialog.open(this.guideModal, {disableClose: true, data});
    this.dialogRef.keydownEvents().subscribe(event => event.key === 'Escape' ? this.dialogRef.close() : null);
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'delete' && this.guide) {
        this.appService.delete('guides', this.appService.getIdFromUrlString(this.guide['@id'])).subscribe({
          next: () => this.appService.openSnackBar('your data has been deleted successfully'),
          error: () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
          complete: () => this.dialog.closeAll(),
        });
      }
      if (result === 'reset') {
        ['salutation_id', 'first_name', 'last_name', 'email', 'street', 'postal_code', 'city', 'country_id'].map((key: string) => this.form.get(key)?.reset());
        this.form.get('status')?.setValue('A');
        this.form.get('is_activated')?.setValue(null);
        this.form.markAsTouched();
        this.form.markAsDirty();
      }
    });
  }

  public setCurrentBook(currentBook: Book): void {
    if (this.books?.length) {
      const serial_nr_from = currentBook.serial_nr_from;
      const serial_nr_to = currentBook.serial_nr_to;
      this.idRange = {serial_nr_from, serial_nr_to};
      this.form.patchValue({book: currentBook});
      const control = this.form.get('serial_nr') as FormControl;
      control.setValue(null);
      // const email = this.form.get('email') as FormControl;
      // email.setValue(null);
      control.clearValidators();
      control.setValidators([Validators.min(serial_nr_from || 0), Validators.max(serial_nr_to || 10000000), Validators.required]);
      control.updateValueAndValidity();
      control.markAsTouched();
    }
  }

  public setActivationCode(): void {
    this.form.get('activation_code')?.patchValue(String(this.appService.getRandomNumberBetween(100000, 999999)));
  }

  public onSubmit(): void {
  }

  public format(): void {
    const sn = this.form.get('serial_nr');
    sn?.patchValue(String(sn?.value).padStart(5, '0'));
  }

  public updateFormWithExistingData(): void {
    const serial = this.form.get('serial_nr') as FormControl;
    const book = this.form.get('book') as FormControl;
    if (!serial.value || !book.value) {
      return;
    }

    this.appService.list('guides', new HttpParams().set('serial_nr', serial.value).set('book', String((book.value as Book).id))).subscribe({
      next: (result: APIPlatformPagedCollection) => {
        if (result['hydra:totalItems'] > 0) {
          this.dialog.closeAll();
          setTimeout(() => this.router.navigate([{outlets: {edit: ['guides', (result['hydra:member'] as Guide[])[0].id, 'edit_guide']}}]).then(), 500);
        }
      }
    });
  }

  public canDelete(): boolean {
    return this.editMode && !!this.guide && (this.guide?.used_vouchers_cnt <= 0 || !this.guide?.used_vouchers_cnt);
  }

}
