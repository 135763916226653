import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { APIPlatformPagedCollection, AppService, AppUser, Book, BookGolfClubResource, Country, GolfClub, GolfClubData } from '../../../../shared/services/app.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-golfclubs',
  templateUrl: './golfclubs.component.html',
  styleUrls: ['./golfclubs.component.scss']
})
export class GolfClubsComponent implements OnInit {

  @ViewChild('sendEmailModal', {static: true}) sendEmailModal: TemplateRef<any>;

  public form: FormGroup;
  public vouchers: number[] | undefined = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public countries: Country[] = [];
  public user: AppUser;

  public displayedColumns: string[] = ['cover_image', 'name', 'single_player', 'vouchers_cnt', 'status'];
  public dataSource: MatTableDataSource<Book>;
  public editMode = true;
  public emailExists = false;

  private params: Params;
  public books: Book[] = [];
  private golfClub: GolfClub;
  private bookGolfClubsData: GolfClubData[] = [];

  constructor(public route: ActivatedRoute, public formBuilder: FormBuilder, public appService: AppService, public dialog: MatDialog, public router: Router) {
    this.form = this.formBuilder.group({
      club_nr: new FormControl(null, []),
      name: new FormControl(null, []),
      login: new FormControl(null, [Validators.required]),
      pwd: new FormControl(null, []),
      salutation_id: new FormControl(null, []),
      title: new FormControl(null, []),
      first_name: new FormControl(null, []),
      last_name: new FormControl(null, []),
      city: new FormControl(null, []),
      postal_code: new FormControl(null, []),
      country_id: new FormControl(null, []),
      email: new FormControl(null, [Validators.email, Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]),
      person_email: new FormControl(null, [Validators.email, Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]),
      website: new FormControl(null, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      vouchers_cnt: new FormControl(null, []),
      single_player: new FormControl(null, []),
      status: new FormControl(null, []),
      user: this.formBuilder.group({
        username: new FormControl(null, []),
        securityRoles: new FormControl(null, []),
        active: new FormControl(null, []),
        email: new FormControl(null, [Validators.email, Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]),
      })
    });
  }

  ngOnInit(): void {
    this.appService.isLoading.next(true);
    this.route.params.subscribe((params: Params) => {
        this.params = params;
        const subscriptions: Observable<any>[] = [this.appService.get(params.route, params.uuid), this.appService.getCountries(), this.appService.getBooks(true), this.appService.getOne('users', new HttpParams().set('golfclub', params.uuid))];
        combineLatest(subscriptions).subscribe(
          (result: APIPlatformPagedCollection[]) => {
            this.golfClub = result[0] as unknown as GolfClub;
            this.countries = result[1]['hydra:member'] as Country[];
            this.user = result[3] as unknown as AppUser;

            result[2]['hydra:member'].map((book: Book) => {
              const userGuide = this.golfClub.bookGolfClub.find((bgc: any) => (bgc.book as Book)['@id'] === book['@id']);
              book.status = userGuide?.status || 'N';
              book.vouchers_cnt = Number(userGuide?.vouchersCnt) || 0;
              book.single_player = !!userGuide?.singlePlayer;
              book.endpoint = userGuide ? userGuide['@id'] : undefined;
              this.books.push(book);
            });

            this.dataSource = new MatTableDataSource(this.books);
            Object.keys(this.golfClub).map((key: string) => this.form.patchValue({[key]: (this.golfClub as any)[key]}));
            if (this.user) {
              Object.keys(this.user).map((key: string) => this.form.get('user')?.patchValue({[key]: (this.user as any)[key]}));
            }

            this.observeLogin(this.form.get('login')?.value);
            this.observeEmail(this.form.get('user.email')?.value);

            this.form.get('user')?.get('id')?.disable();
            this.form.markAllAsTouched();
            this.appService.isLoading.next(false);

            this.form.get('login')?.valueChanges.subscribe({next: (value: any) => this.observeLogin(value)});
            this.form.get('user.email')?.valueChanges.subscribe({next: (value: any) => this.observeEmail(value)});
          });
      }
    );
  }

  public getNestedForm(name: string): FormGroup {
    return this.form.get(name) as FormGroup;
  }

  public openDialog(dialog: TemplateRef<any>, data: any = {}): void {
    this.dialog.open(dialog, {disableClose: true, width: '50vw', data});
  }

  public sendLogin(email: string): void {
    this.appService.isLoading.next(true);
    this.appService.post('reset-password/token', {email}).subscribe(
      () => this.appService.openSnackBar('Email wurde gesendet.'),
      () => this.appService.openSnackBar('Ein Fehler ist aufgetreten.'),
      () => this.appService.isLoading.next(false),
    );
  }

  public onChangeRow(event: MatCheckboxChange | MatSelectChange, row: Book, column: string, index: number): void {
    switch (column) {
      case 'status':
        row.status = (event as MatCheckboxChange).checked ? 'A' : 'N';
        break;
      case 'vouchers_cnt':
        row.vouchers_cnt = (event as MatSelectChange).value;
        break;
      case 'single_player':
        row.single_player = (event as MatCheckboxChange).checked;
        break;
    }
    const book: Book = this.books[index];

    const resource: BookGolfClubResource = {
      book: book['@id'],
      golfClub: this.golfClub['@id'],
      vouchersCnt: String(row.vouchers_cnt),
      status: row.status,
      singlePlayer: row.single_player || false
    };

    const objIdx = this.bookGolfClubsData.findIndex((data: GolfClubData) => data.id === book.id);
    (objIdx === -1) ? this.bookGolfClubsData.push({id: book.id, endpoint: book.endpoint, resource}) : this.bookGolfClubsData[objIdx] = {id: book.id, endpoint: book.endpoint, resource};
    this.form.markAsDirty();
    this.form.markAsTouched();
  }

  public onSubmit(resetPassword: boolean = false): void {
    if (this.form.valid && this.form.touched && !this.emailExists) {
      this.appService.isLoading.next(true);
      const club = this.form.getRawValue();
      club.pwd = '*************'; // hide real password

      const subscriptions: Observable<any>[] = [];
      this.bookGolfClubsData.map((data: GolfClubData) => subscriptions.push(
        data.endpoint ? this.appService.patch('book_golf_clubs', this.appService.getIdFromUrlString(data.endpoint), data.resource) : this.appService.post('book_golf_clubs', data.resource)
      ));

      this.appService.patch(this.params.route, this.params.uuid, club).subscribe(
        () => this.appService.openSnackBar('Daten wurden erfolgreich gespeichert.'),
        () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
        () => {
          this.form.markAsUntouched();
          this.form.markAsPristine();

          this.updateUser(resetPassword);

          if (subscriptions.length) {
            combineLatest(subscriptions).subscribe(
              () => this.appService.openSnackBar('Daten wurden erfolgreich gespeichert.'),
              () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
              () => {
                this.bookGolfClubsData = [];
              }
            );
          }
          this.appService.isLoading.next(false);
        }
      );
    }
  }

  private updateUser(resetPassword: boolean = false): void {
    const values = {...this.form.get('user')?.value, ...{active: this.form.get('status')?.value === 'A', isActive: this.form.get('status')?.value === 'A', username: this.form.get('login')?.value, password: this.form.get('pwd')?.value, golfclub: this.golfClub['@id']}};
    if (!resetPassword) {
      delete values.password;
    }
    const observable: Observable<AppUser> = this.user ? this.appService.patch('users', this.appService.getIdFromUrlString(this.user['@id']), values, false) : this.appService.post('users', values);
    observable.subscribe({next: (user: AppUser) => this.user = user});
  }

  public observeLogin(value: string): void {
    this.appService.list('users', new HttpParams().set('username', value)).subscribe({
      next: (result: APIPlatformPagedCollection) => this.form.get('login')?.setErrors(
        (this.golfClub ? result['hydra:member'].filter((user: any) => user.golfclub !== this.golfClub['@id']).length : result['hydra:totalItems']) >= 1 ? {incorrect: true} : null
      )
    });

  }

  public observeEmail(value: string): void {
    this.appService.list('users', new HttpParams().set('email', value)).subscribe({
      next: (result: APIPlatformPagedCollection) => this.emailExists = (this.golfClub ? result['hydra:member'].filter((user: any) => user.golfclub !== this.golfClub['@id']).length : result['hydra:totalItems']) >= 1
    });

  }
}
