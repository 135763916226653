import { MatPaginatorIntl } from '@angular/material/paginator';

const germanRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 von ${length}`;
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
  return `${startIndex + 1} - ${endIndex} von ${length}`;
};


export function getGermanPaginatorIntl(): MatPaginatorIntl {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = 'Zeilen pro Seite:';
  paginatorIntl.firstPageLabel = 'erste Seite';
  paginatorIntl.lastPageLabel = 'letzte Seite';
  paginatorIntl.nextPageLabel = 'nächste Seite';
  paginatorIntl.previousPageLabel = 'vorherige Seite';
  paginatorIntl.getRangeLabel = germanRangeLabel;
  return paginatorIntl;
}
