<mat-dialog-content class="mat-typography">

  <div class="filter-container">
    <mat-form-field>
      <mat-label>{{ 'select year' | translate}}</mat-label>
      <mat-select [value]="yearRange" (selectionChange)="dateChanged.emit( $event.value)" multiple>
        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'filter golf club' | translate }}</mat-label>
      <input #value type="text" matInput [formControl]="golfClubCtrl" [matAutocomplete]="auto">
      <button mat-button *ngIf="value.value" matSuffix mat-icon-button aria-label="Clear" (click)="value.value = ''; reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="clubSelected($event.option.value)">
        <mat-option *ngIf="isLoading" class="is-loading">
          <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
        </mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let option of golfClubs" [value]="option">
            {{option.name}}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <ngx-chartjs #ref0 type="bar" [data]="yearlyUsageDate" [options]="options"></ngx-chartjs>

  <table class="table">
    <tr>
      <th>{{ 'year' | translate }}</th>
      <th>{{ 'multi_player' | translate }}</th>
      <th>{{ 'single_player' | translate }}</th>
      <th>{{ 'total' | translate }}</th>
    </tr>
    <tr *ngFor="let u of usage; index as i">
      <td>{{ yearRange[i] }}</td>
      <td>{{ u.multi_player || 0 }}</td>
      <td>{{ u.single_player || 0 }}</td>
      <td>{{ (u.single_player + u.multi_player) || 0 }}</td>
    </tr>
  </table>
</mat-dialog-content>
