<div class="container mat-elevation-z0">
  <div class="table-container">

    <div class="filter-container" *ngIf="currentConfiguration?.filter">
      <mat-form-field>
        <mat-label>{{ 'filter text' | translate }}</mat-label>
        <input [(ngModel)]="filter" matInput (keyup)="applyFilter($event)" [placeholder]="'filter data' | translate">
        <button mat-button *ngIf="filter" matSuffix mat-icon-button aria-label="Clear" (click)="filter=''; filterChanged.emit('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'filter countries' | translate }}</mat-label>
        <mat-select [(value)]="countryId" (selectionChange)="countryChanged.emit($event.value)" multiple [placeholder]="'show all' | translate">
          <mat-option *ngFor="let country of countryList" [value]="country.id">
            <span class="flag-icon flag-icon-{{ country?.code?.toLowerCase() }}"></span>
            {{country.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'filter guides' | translate }}</mat-label>
        <mat-select [(value)]="bookId" (selectionChange)="bookChanged.emit($event.value)" multiple [placeholder]="'show all' | translate">
<!--          <mat-option *ngIf="bookId" value="-">{{ 'show all' | translate }}</mat-option>-->
          <mat-option *ngFor="let book of bookList" [value]="book.id">{{ book.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'filter status' | translate }}</mat-label>
        <mat-select [(value)]="status" (selectionChange)="statusChanged.emit($event.value)" multiple [placeholder]="'show all' | translate">
          <mat-option *ngFor="let status of ['A', 'D', 'S', 'O', 'is_activated' ]" [value]="status">{{ status | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="currentRoute === 'orders'">
        <mat-label>{{ 'filter payment status' | translate }}</mat-label>
        <mat-select [(value)]="payment" (selectionChange)="paymentChanged.emit($event.value)">
          <mat-option *ngFor="let status of paymentStatus" [value]="status">{{ (status || 'show all') | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-stroked-button (click)="clearFilter()" [matTooltip]="'clear filter' | translate">
        <mat-icon class="menu-icon">clear</mat-icon>
      </button>

      <button mat-stroked-button (click)="export()" [matTooltip]="'export data' | translate">
        <mat-icon class="menu-icon">file_download</mat-icon>
      </button>

    </div>

    <table mat-table [dataSource]="dataSource" class="table" [ngClass]="{'striped': currentConfiguration?.edit}" [matSortDisabled]="currentRoute === 'custom/guide_list'" matSort (matSortChange)="resetPaging()">

      <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column" [ngSwitch]="column">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | translate | uppercase }}</th>

        <!-- STATUS COLUMN -->
        <ng-container *ngSwitchCase="'status'">
          <td mat-cell *matCellDef="let row" (click)="routerLink(row['@id'], row['@type'])">
            <span class="material-icons" [ngClass]="getStatus(row[column]).color" [matTooltip]="getStatus(row[column]).text">{{ getStatus(row[column]).icon }}</span>
          </td>
        </ng-container>

        <!-- COUNTRY COLUMN -->
        <ng-container *ngSwitchCase="'country_id'">
          <td mat-cell *matCellDef="let row" (click)="routerLink(row['@id'], row['@type'])">
            <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center" style="height: 58px;">
              <span class="flag-icon flag-icon-{{ getCountry(column, row)?.code?.toLowerCase() }}" [matTooltip]="(getCountry(column, row)?.name || '') | translate"></span>
              <span fxShow fxHide.lt-lg>{{ (getCountry(column, row)?.name || '') | translate }}</span>
            </span>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="'delivery_country_code'">
          <td mat-cell *matCellDef="let row" (click)="routerLink(row['@id'], row['@type'])">
            <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center" style="height: 58px;">
              <span class="flag-icon flag-icon-{{ row[column].toLowerCase() }}" [matTooltip]="(getCountryByCode(row[column])?.name || '') | translate"></span>
              <span fxShow fxHide.lt-lg>{{ (getCountryByCode(row[column])?.name || '') | translate }}</span>
            </span>
          </td>
        </ng-container>

        <!-- ACTION HANDLER -->
        <ng-container *ngSwitchCase="isAction(column) ? column : ''">
          <td mat-cell class="button-cell" *matCellDef="let row">
            <button [disabled]="!checkCondition(getAction(column)?.condition, row)" mat-stroked-button [ngClass]="getAction(column)?.class || ''" (click)="getAction(column)?.event(row['@id'])" [matTooltip]="(getAction(column)?.text || '') | translate">
              <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
                <span fxShow fxHide.lt-lg>{{ (getAction(column)?.text || '') | translate }}</span>
                <mat-icon *ngIf="getAction(column)?.icon">{{ getAction(column)?.icon }}</mat-icon>
              </span>
            </button>
          </td>
        </ng-container>

        <!-- DEFAULT COLUMNS -->
        <ng-container *ngSwitchDefault>
          <td mat-cell *matCellDef="let row" [innerHTML]="formatRow(column, row)" (click)="routerLink(row['@id'], row['@type'])"></td>
        </ng-container>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [length]="resultsLength" [pageSizeOptions]="defaultPageSizeOptions" [pageSize]="defaultPageSize" showFirstLastButtons></mat-paginator>
</div>

<button mat-fab color="primary" class="scroll-button" *ngIf="showScrollButton" (click)="scrollToTop()">
  <mat-icon>keyboard_arrow_up</mat-icon>
</button>
