import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { APIPlatformPagedCollection, AppService, AppUser, GolfClub } from '../../../../../shared/services/app.service';
import { combineLatest, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

export interface Task {
  name: string;
  text?: string;
  completed: boolean;
  subtasks?: Task[];
}

@Component({
  selector: 'app-send-login',
  templateUrl: './send-login.component.html',
  styleUrls: ['./send-login.component.scss']
})
export class SendLoginComponent implements OnInit {

  public allComplete = false;
  public task: Task = {name: 'send to all email addresses', completed: false, subtasks: []};

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private appService: AppService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {

      const subscriptions: Observable<any>[] = [this.appService.get(params.route, params.uuid), this.appService.getOne('users', new HttpParams().set('golfclub', params.uuid))];
      combineLatest(subscriptions).subscribe(
        (result: APIPlatformPagedCollection[]) => {
          const golfClub: GolfClub = result[0] as unknown as GolfClub;
          const user: AppUser = result[1] as unknown as AppUser;

          this.task.subtasks = [
            {text: 'club email', name: golfClub.email, completed: false},
            {text: 'personal email', name: golfClub.person_email, completed: false},
          ];
          if (user && user.email) {
            this.task.subtasks.push({text: 'login email', name: user.email, completed: false});
          }
        });
    });
  }

  public updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  public someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  public setAll(completed: boolean): void {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => t.completed = completed);
  }

  public onSubmit(): void {
    this.appService.isLoading.next(true);
    const mails: string[] = [];
    this.task.subtasks?.filter((task: Task) => task.completed).map((task: Task) => mails.push(task.name));
    const subscriptions: Observable<any>[] = [];
    [...new Set(mails)].map((email: string) => subscriptions.push(this.appService.post('reset-password/token', {email})));
    combineLatest(subscriptions).subscribe(
      () => this.appService.openSnackBar('emails has been send successfully'),
      () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
      () => this.appService.isLoading.next(false),
    );
  }
}
