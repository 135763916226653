<h4 mat-dialog-title *ngIf="book">{{ 'for' | translate }} {{ book.name }}</h4>
<mat-dialog-content class="mat-typography">

  <div class="filter-container">
    <mat-form-field>
      <mat-label>{{ 'filter text' | translate }}</mat-label>
      <input #filter matInput (keyup)="applyFilter($event)" [placeholder]="'filter data' | translate">
      <button mat-button *ngIf="filter.value" matSuffix mat-icon-button aria-label="Clear" (click)="filter.value=''; filterChanged.emit('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'filter countries' | translate }}</mat-label>
      <mat-select [(value)]="countryId" (selectionChange)="countryChanged.emit($event.value)" multiple [placeholder]="'show all' | translate">
        <!--        <mat-option *ngIf="countryId" value="-">{{ 'show all' | translate }}</mat-option>-->
        <mat-option *ngFor="let country of countryList" [value]="country.id">
          <span class="flag-icon flag-icon-{{ country?.code?.toLowerCase() }}"></span>
          {{country.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <table mat-table [dataSource]="dataSource" class="table striped fixed"  matSort matSortDisableClear>
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column" [ngSwitch]="column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | translate | uppercase }}</th>

      <!-- CHECKBOX COLUMN -->
      <ng-container *ngSwitchCase="['single_player', 'has_guide'].includes(column) ? column : ''">
        <td mat-cell *matCellDef="let row">
          <mat-checkbox [checked]="!!row[column]" color="primary" (change)="onChangeRow($event, row, column)"></mat-checkbox>
        </td>
      </ng-container>

      <!-- COUNTRY COLUMN -->
      <ng-container *ngSwitchCase="'country_id'">
        <td mat-cell *matCellDef="let row">
            <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center" style="height: 58px;">
              <span class="flag-icon flag-icon-{{ getCountry(column, row)?.code?.toLowerCase() }}" [matTooltip]="(getCountry(column, row)?.name || '') | translate"></span>
              <span fxShow fxHide.lt-lg>{{ (getCountry(column, row)?.name || '') | translate }}</span>
            </span>
        </td>
      </ng-container>

      <ng-container *ngSwitchCase="'vouchers_cnt'">
        <td mat-cell *matCellDef="let row">
          <mat-form-field appearance="outline" style="max-height: 58px; width: 50%; text-align: center;">
            <mat-select [value]="row[column]" (selectionChange)="onChangeRow($event, row, column)">
              <mat-option *ngFor="let voucher of vouchers" [value]="voucher">{{voucher}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <!-- DEFAULT COLUMNS -->
      <ng-container *ngSwitchDefault>
        <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
      </ng-container>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [length]="resultsLength" [pageSizeOptions]="defaultPageSizeOptions" [pageSize]="defaultPageSize" showFirstLastButtons></mat-paginator>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
      </span>
  </button>

  <span class="spacer"></span>

  <button mat-stroked-button color="accent" type="submit" [disabled]="!bookGolfClubsData.length" (click)="onSubmit()">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">save_alt</mat-icon>
      </span>
  </button>

  <button mat-dialog-close mat-stroked-button color="primary" type="submit" [disabled]="!bookGolfClubsData.length" (click)="onSubmit()">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save and close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
  </button>
</mat-dialog-actions>
