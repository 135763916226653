<div class="container mat-elevation-z0">
  <div class="filter-container">
    <mat-form-field>
      <mat-label>{{ 'filter guides' | translate}}</mat-label>
      <mat-select [value]="booksList" (selectionChange)="booksChanged.emit( $event.value)" multiple>
        <mat-option *ngFor="let book of books" [value]="book.id">{{ book.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'filter golf club' | translate }}</mat-label>
      <input #value type="text" matInput [formControl]="golfClubCtrl" [matAutocomplete]="auto" (keyup)="value.value === '' ? reset() : null">
      <button mat-button *ngIf="value.value" matSuffix mat-icon-button aria-label="Clear" (click)="value.value = ''; reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="clubSelected($event.option.value)">
        <mat-option *ngIf="isLoading" class="is-loading">
          <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
        </mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let option of golfClubs" [value]="option">
            {{option.name}}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <table mat-table class="table striped" [dataSource]="dataSource" matSort>

    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column" [ngSwitch]="column">

      <ng-container *ngSwitchCase="'name'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | translate }}</th>
        <td mat-cell *matCellDef="let row" [style.cursor]="getRowTotal(row) > 0 ? 'pointer' : 'default'" (click)="getRowTotal(row) > 0 ? routerLink(row.id) : null">{{ row[column] }}</td>
      </ng-container>

      <ng-container *ngSwitchCase="'total'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | translate }}</th>
        <td mat-cell *matCellDef="let row" [style.cursor]="getRowTotal(row) > 0 ? 'pointer' : 'default'" (click)="getRowTotal(row) > 0 ? routerLink(row.id) : null">{{ getRowTotal(row) }}</td>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ getBookNameById(column) }}</th>
        <td mat-cell *matCellDef="let row" [style.cursor]="getRowTotal(row) > 0 ? 'pointer' : 'default'" (click)="getRowTotal(row) > 0 ? routerLink(row.id) : null">{{ row[column] || 0 }}</td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator  [pageSizeOptions]="defaultPageSizeOptions" [pageSize]="defaultPageSize" showFirstLastButtons></mat-paginator>
</div>
