import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { FrontendComponent } from '../frontend.component';
import { APIPlatformPagedCollection, Book, BookGolfClub, Guide, VoucherUsage } from '../../../shared/services/app.service';
import { HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import moment from 'moment';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['../frontend.component.scss', 'validation.component.scss']
})
export class ValidationComponent extends FrontendComponent implements AfterViewInit {

  @ViewChild('confirmModal', {static: true}) confirmModal: TemplateRef<any>;
  @ViewChild('successModal', {static: true}) successModal: TemplateRef<any>;
  @ViewChild('errorModal', {static: true}) errorModal: TemplateRef<any>;

  public voucher: number[] = [];
  public currentDate = new FormControl(moment(new Date()).format('YYYY-MM-DDTHH:mm'));
  public submit: boolean[] = [];
  public outOfRange: boolean[] = [];
  public singlePlayer = false;
  public isLoading = true;
  public showDate = false;

  ngAfterViewInit(): void {
    this.appService.isLoading.subscribe((value: boolean) => {
      Promise.resolve(null).then(() => this.isLoading = value);
    });
  }

  public isValid(book: Book, serialNr: number): boolean {
    return serialNr >= book.serial_nr_from && serialNr <= book.serial_nr_to;
  }

  public onKeyUp(event: any, bookId: number, nextIndex: number): boolean | void {
    this.outOfRange[bookId] = false;
    if (event.target.value.match(/^[0-9]$/) && nextIndex <= this.numbers.length) {
      const inputElement = this.renderer.selectRootElement(`#id-${bookId}-${nextIndex}`);
      inputElement.focus();
      inputElement.select();
    }
  }

  public refresh(): void {
    window.location.reload();
  }

  public openDialog(dialog: TemplateRef<any>, data: any = {}): void {
    this.dialog.open(dialog, {disableClose: true, width: '50vw', data});
  }

  private getVoucherArray(voucherCnt: number, guide: Guide | undefined): void {
    const length = voucherCnt - (guide?.voucher_usage || 0);
    this.voucher = Array.from({length}, (value, key: number) => key + 1);
  }

  public onSubmit(bgc: BookGolfClub, form: any): void {
    this.outOfRange[bgc.book.id] = false;
    const serialNr = Object.values(this.values).join('');
    if (!this.isValid(bgc.book, Number(serialNr))) {
      this.outOfRange[bgc.book.id] = true;
      return;
    }
    this.appService.isLoading.next(true);
    this.appService.getOne('guides', new HttpParams().set('serial_nr', serialNr).set('book', bgc.book['@id'])).subscribe((guide: Guide) => {

        if (!guide) {
          this.appService.post('guides', this.getGuideBody(bgc, serialNr)).subscribe(
            (g: Guide) => {
              this.guide[bgc.book.id] = g;
              this.submit[bgc.book.id] = true;
            },
            (error) => console.log(error),
            () => this.getVoucherUsage(bgc, serialNr)
          );
        } else {

          if (!guide.activation_code) {
            guide.activation_code = String(this.appService.getRandomNumberBetween(100000, 999999));
            this.appService.patch('guides', this.appService.getIdFromUrlString(guide['@id']), guide).subscribe();
          }

          this.guide[bgc.book.id] = guide;
          this.submit[bgc.book.id] = true;
          this.getVoucherUsage(bgc, serialNr);
        }
      },
      (error) => console.log(error),
      () => this.appService.isLoading.next(false)
    );
  }

  private getVoucherUsage(bgc: BookGolfClub, serialNr: string): void {
    this.appService.isLoading.next(true);
    this.appService.list('voucher_usages', new HttpParams().set('guide.serial_nr', serialNr).set('bookGolfClub', bgc['@id'])).subscribe((result: APIPlatformPagedCollection) => {
        const voucherUsage: VoucherUsage[] = result['hydra:member'];
        if (this.guide[bgc.book.id] !== undefined) {
          this.guide[bgc.book.id].voucher_usage = voucherUsage.reduce((a: number, b: VoucherUsage) => a + (b.used_quantity || 0), 0);
        }
        this.getVoucherArray(bgc.vouchersCnt, this.guide[bgc.book.id]);
      },
      () => null,
      () => this.appService.isLoading.next(false)
    );
  }

  public onValidate(guide: Guide, voucherCnt: number, bgc: BookGolfClub): void {
    this.appService.isLoading.next(true);
    this.appService.post('voucher_usages', this.getVoucherUsageBody(bgc, guide, voucherCnt)).subscribe(
      () => null,
      (error) => {
        console.log(error);
        this.openDialog(this.errorModal, error);
      },
      () => {
        const subscriptions: Observable<any>[] = [this.appService.patch('guides', this.appService.getIdFromUrlString(guide['@id']), {used_vouchers_cnt: (guide.used_vouchers_cnt || 0) + voucherCnt})];
        if (guide.email) {
          subscriptions.push(this.appService.sendVoucherEmail(this.appService.getIdFromUrlString(guide['@id'])));
        }
        combineLatest(subscriptions).subscribe(
          () => this.openDialog(this.successModal),
          (error) => this.openDialog(this.errorModal, error),
          () => this.appService.isLoading.next(false)
        );
      }
    );
  }

  public keyDownFunction(event: KeyboardEvent, bgc: BookGolfClub, form: any): void {
    if (event.keyCode === 13) {
      this.onSubmit(bgc, form);
    }
  }

  private getGuideBody(bgc: BookGolfClub, serialNr: string): any {
    const date = new Date();
    return {
      oldId: null,
      book: bgc.book['@id'],
      serial_nr: Number(serialNr),
      salutation_id: null,
      first_name: null,
      last_name: null,
      email: null,
      street: null,
      postal_code: null,
      city: null,
      country_id: null,
      created_tstamp: date,
      changed_tstamp: date,
      created_by: String(this.tokenStorage.getUser().id),
      changed_by: String(this.tokenStorage.getUser().id),
      status: 'A',
      activation_code: String(this.appService.getRandomNumberBetween(100000, 999999)),
      is_activated: null,
      activated_tstamp: null,
      used_vouchers_cnt: 0,
      vouchers_cnt: null,
      createdAt: date,
      lastModified: date,
      changed_by_club: Number(this.appService.getIdFromUrlString(bgc.golfClub['@id'])),
      created_by_club: Number(this.appService.getIdFromUrlString(bgc.golfClub['@id'])),
      created_by_club_browser: navigator.userAgent,
      changed_by_club_browser: navigator.userAgent,
      created_by_club_tstamp: date,
      changed_by_club_tstamp: date,
    };
  }

  private getVoucherUsageBody(bgc: BookGolfClub, guide: Guide, voucherCnt: number): any {
    const date = new Date();
    return {
      bookGolfClub: bgc['@id'],
      changed_by_club: this.appService.getIdFromUrlString(bgc.golfClub['@id']),
      created_by_club: this.appService.getIdFromUrlString(bgc.golfClub['@id']),
      created_by_club_browser: navigator.userAgent,
      changed_by_club_browser: navigator.userAgent,
      guide: guide['@id'],
      single_player: this.singlePlayer,
      status: 'A',
      used_quantity: voucherCnt,
      used_tstamp: moment(this.currentDate.value).format(),
      oldId: null,
      created_by_tstamp: date,
      changed_by_tstamp: date,
      created_by_club_tstamp: date,
      changed_by_club_tstamp: date,
      created_by_club_ip: null,
      changed_by_club_ip: null,
      created_by: String(this.tokenStorage.getUser().id),
      changed_by: String(this.tokenStorage.getUser().id),
    };
  }

}
