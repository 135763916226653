import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ListComponent } from './components/backend/list/list.component';
import { EditComponent } from './components/backend/edit/edit.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from './shared/shared.module';
import { SendLoginComponent } from './components/backend/edit/golfclubs/send-login/send-login.component';
import { VoucherComponent } from './components/backend/edit/guides/voucher/voucher.component';
import { ClubsComponent } from './components/backend/edit/clubs/clubs.component';
import { AssignComponent } from './components/backend/edit/clubs/assign/assign.component';
import { AssumeComponent } from './components/backend/edit/clubs/assume/assume.component';
import { GuidesComponent } from './components/backend/edit/guides/guides.component';
import { EditGuideComponent } from './components/backend/edit/guides/edit-guide/edit-guide.component';
import { AddGuideComponent } from './components/backend/edit/guides/add-guide/add-guide.component';
import { GolfClubsComponent } from './components/backend/edit/golfclubs/golfclubs.component';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getGermanPaginatorIntl } from './shared/helper/german-paginator-intl';
import { ChartjsModule } from '@ctrl/ngx-chartjs';
import { NewsComponent } from './components/backend/edit/news/news.component';
import { AddNewsComponent } from './components/backend/edit/news/add-news/add-news.component';
import { EditNewsComponent } from './components/backend/edit/news/edit-news/edit-news.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import localeDe from '@angular/common/locales/de';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { DeleteNewsComponent } from './components/backend/edit/news/delete-news/delete-news.component';
import { HomeComponent } from './components/home/home.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DashboardComponent } from './components/backend/dashboard/dashboard.component';
import { ValidationComponent } from './components/frontend/validation/validation.component';
import { DevaluationComponent } from './components/frontend/devaluation/devaluation.component';
import { StatisticsComponent } from './components/frontend/statistics/statistics.component';
import { FrontendComponent } from './components/frontend/frontend.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { APP_DATE_FORMATS } from './shared/helper/custom-date-adapter';
import { OrdersComponent } from './components/backend/edit/orders/orders.component';
import { ImportOrdersComponent } from './components/backend/edit/orders/import-orders/import-orders.component';
import { EditOrdersComponent } from './components/backend/edit/orders/edit-orders/edit-orders.component';
import { DetailComponent } from './components/frontend/statistics/detail/detail.component';
import { VoucherUsageComponent } from './components/backend/dashboard/voucher-usage/voucher-usage.component';
import { YearlyVoucherUsageComponent } from './components/backend/dashboard/yearly-voucher-usage/yearly-voucher-usage.component';
import { VoucherDetailComponent } from './components/backend/dashboard/voucher-detail/voucher-detail.component';
import { BookComponent } from './components/backend/edit/book/book.component';
import { LastVoucherUsageComponent } from './components/backend/dashboard/last-voucher-usage/last-voucher-usage.component';
import { CommonMaterialModule } from './shared/helper/common-material.module';
import { ExcelService } from './shared/services/excel.service';
import { AddGolfclubComponent } from './components/backend/edit/golfclubs/add-golfclub/add-golfclub.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader  {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    EditComponent,
    LoginComponent,
    SendLoginComponent,
    GuidesComponent,
    VoucherComponent,
    ClubsComponent,
    AssignComponent,
    AssumeComponent,
    EditGuideComponent,
    AddGuideComponent,
    GolfClubsComponent,
    DashboardComponent,
    NewsComponent,
    AddNewsComponent,
    EditNewsComponent,
    DeleteNewsComponent,
    HomeComponent,
    ValidationComponent,
    DevaluationComponent,
    StatisticsComponent,
    FrontendComponent,
    OrdersComponent,
    ImportOrdersComponent,
    EditOrdersComponent,
    DetailComponent,
    VoucherUsageComponent,
    YearlyVoucherUsageComponent,
    VoucherDetailComponent,
    BookComponent,
    LastVoucherUsageComponent,
    AddGolfclubComponent
  ],
  imports: [
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({loader: {provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpClient]}}),
    ChartjsModule,
    AngularEditorModule,
    NgxPermissionsModule.forRoot(),
    CommonMaterialModule
  ],
  providers: [
    ExcelService,
    CurrencyPipe,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always', appearance: 'outline'}},
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {position: 'above'}},
    {provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl()},
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ],
  exports: [
    ListComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
