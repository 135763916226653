<div class="outer">

  <div *ngIf="noGolfClub">
    <h3 style="text-align: center; color: white">{{ 'You are currently not represented in any LeisureBREAKS guide.' | translate }}</h3>
  </div>

  <div *ngIf="!bgc && bookGolfClubs.length >= 2" fxFlex fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-around start">
    <div *ngFor="let book of bookGolfClubs; index as i" class="books" (click)="setBGC(book)">
      <img src="https:{{book.book.cover_image}}" [alt]="book.book.name"/>
      <p>{{book.book.name}}</p>
    </div>
  </div>

  <div *ngIf="bgc" fxFlex fxLayout="row" fxLayout.lt-sm="column-reverse" fxLayoutGap="20px" fxLayoutAlign="space-around start" fxLayoutAlign.lt-sm="center center">

    <div class="books" (click)="bookGolfClubs.length >= 2 ? setBGC(null) : null">
      <img src="https:{{bgc.book.cover_image}}" [alt]="bgc.book.name"/>
      <button *ngIf="bookGolfClubs.length >= 2" mat-button type="submit">
        <span fxFlex fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-around center">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <span>{{ 'choose book' | translate }}</span>
        </span>
      </button>
    </div>

    <mat-card class="pin-card mat-elevation-z0">

      <mat-card-header>
        <mat-card-subtitle>{{ 'please enter your pin' | translate }}</mat-card-subtitle>
      </mat-card-header>

      <form autocomplete="off" #form="ngForm" (keyup)="form.valid ? keyDownFunction($event, bgc, form.value) : null">
        <mat-card-content>

          <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
            <mat-form-field *ngFor="let n of numbers; index as i" appearance="outline" class="voucher-code">
              <input [id]="'id-' + bgc.book.id + '-' + n" matInput type="text" pattern="\d*" maxlength="1" required autofocus [(ngModel)]='values[i]' [name]="'input-' + bgc.book.id + '-' + n" (keyup)="onKeyUp($event, bgc.book.id, (n + 1))" #myInput (click)="myInput.select();">
            </mat-form-field>
          </div>

        </mat-card-content>

        <mat-card-actions>
          <button id="submit" mat-raised-button class="my-button" type="button" [disabled]="form.invalid" (click)="onSubmit(bgc, form.value)">{{ 'find a guide' | translate }}</button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>

  <ng-container *ngIf="bgc && outOfRange[bgc.book.id]">
    <h2 style="text-align: center; color: white">{{ 'no guide with this number could be found.' | translate }}</h2>
  </ng-container>

  <ng-container *ngIf="bgc && submit[bgc.book.id] && guide[bgc.book.id] && !outOfRange[bgc.book.id]">
    <form autocomplete="off" *ngIf="!isLoading">
      <mat-card-content style="text-align: center; flex-direction: column; color: white;">

        <ng-container *ngIf="guide[bgc.book.id]?.status === 'A'">
          <ng-container *ngIf="guide[bgc.book.id]?.email">
            <h2>{{ 'this guide is registered to' | translate }}</h2>
            <h1><span *ngIf="guide[bgc.book.id]?.last_name">{{ 'salutation.' + guide[bgc.book.id]?.salutation_id | translate }}</span> {{ guide[bgc.book.id]?.first_name }} {{ guide[bgc.book.id]?.last_name }}<span *ngIf="guide[bgc.book.id]?.first_name || guide[bgc.book.id]?.last_name">,</span> {{ guide[bgc.book.id]?.email }}</h1>
            <h1>({{ 'registration code' | translate }}: {{ guide[bgc.book.id]?.activation_code }})</h1>
          </ng-container>

          <ng-container *ngIf="!guide[bgc.book.id]?.email">
            <h3>{{ 'guide has been found but has not yet been registered by the customer.' | translate }}</h3>
            <h1>{{ 'registration code' | translate }}: {{ guide[bgc.book.id]?.activation_code }}</h1>
            <h2>({{ 'please tell the customer the code!' | translate }})</h2>
          </ng-container>

          <span *ngIf="voucher.length" fxFlex fxLayout="column" fxLayoutGap="10px">
            <mat-label (click)="showDate = !showDate" style="cursor: pointer">{{ 'choose a date' | translate }}</mat-label>
              <mat-form-field *ngIf="showDate" style=" width: 100%">
                <input matInput [formControl]="currentDate" type="datetime-local" style="text-align: center; color: black;">
              </mat-form-field>
            <mat-checkbox fxFlex [(ngModel)]="singlePlayer" name="single_player" [disabled]="guide[bgc.book.id]?.status !== 'A'">{{ 'single_player' | translate}}</mat-checkbox>
          </span>

          <h3 *ngIf="!voucher.length">{{ 'all vouchers for your club have already been used for this guide.' | translate }}</h3>

        </ng-container>

        <ng-container *ngIf="guide[bgc.book.id]?.status !== 'A'">
          <h3>{{ 'This guide no. has been locked by administrator.' | translate }}</h3>
        </ng-container>

      </mat-card-content>

      <div class="voucher-buttons">
        <button *ngFor="let voucher of voucher" [disabled]="guide[bgc.book.id]?.status !== 'A'" mat-raised-button class="my-button" type="button" (click)="openDialog(confirmModal, {guide: guide[bgc.book.id], voucher: voucher, bgc: bgc})">{{ 'validate voucher' | translate: {value: voucher}  }}</button>
      </div>

    </form>
  </ng-container>

</div>

<ng-template #confirmModal let-data>
  <h1 mat-dialog-title>{{ 'validate voucher' | translate: {value: data.voucher} }}?</h1>
  <mat-dialog-content>
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon>event</mat-icon>
        {{ 'devaluation_date' | translate }}:  {{ currentDate.value | date:"dd.MM.YYYY HH:mm" }} </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>group</mat-icon>
        {{ 'single_player' | translate }}:  {{ (singlePlayer ? 'true' : 'false') | translate }}</mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>pin</mat-icon>
        {{ 'voucher_cnt' | translate }}:  {{ data.voucher }} </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>vpn_key</mat-icon>
        {{ 'registration code' | translate }}:  {{ data.guide.activation_code }} </mat-list-item>
    </mat-list>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase  }}</span>
      </span>
    </button>

    <span class="spacer"></span>

    <button mat-dialog-close mat-stroked-button color="primary" type="submit" (click)="onValidate(data.guide, data.voucher, data.bgc)">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'validate' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #successModal>
  <h1 mat-dialog-title>{{ 'validate voucher' | translate: {value: ''} }}</h1>
  <mat-dialog-content>
    <p>{{ 'voucher has been successfully devalued' | translate }}</p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-dialog-close mat-stroked-button color="primary" type="submit" (click)="refresh()">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #errorModal let-error>
  <h1 mat-dialog-title>{{ 'an error occurred' | translate }}</h1>
  <mat-dialog-content>
    <h3>{{ 'an error occurred. please try again later.' | translate }}</h3>

    <ul>
      <li *ngIf="error.error['hydra:title']">{{ error.error['hydra:title'] }}</li>
      <li *ngIf="error.error['hydra:description']">{{ error.error['hydra:description'] }}</li>
      <li>{{ error.name }}</li>
      <li>{{ error.message }}</li>
      <li>{{ error.status }}</li>
      <li>{{ error.url }}</li>
    </ul>

  </mat-dialog-content>

  <mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-dialog-close mat-stroked-button color="primary" type="submit" (click)="refresh()">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</ng-template>
