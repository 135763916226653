import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ChartjsComponent } from '@ctrl/ngx-chartjs';
import { FormControl } from '@angular/forms';
import { APIPlatformPagedCollection, AppService, Book, GolfClub } from '../../../../shared/services/app.service';
import moment from 'moment';
import { ChartData, ChartOptions } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { VoucherUsageData } from '../../../frontend/statistics/detail/detail.component';

@Component({
  selector: 'app-yearly-voucher-usage',
  templateUrl: './yearly-voucher-usage.component.html',
  styleUrls: ['./yearly-voucher-usage.component.scss']
})
export class YearlyVoucherUsageComponent implements OnInit {


  @ViewChild('ref0', {static: false}) ref0!: ChartjsComponent;

  public golfClubCtrl = new FormControl();
  public golfClubs: GolfClub[];
  public isLoading = false;

  public years: number[] = [];
  public yearRange: number[] = [moment().subtract(2, 'year').year(), moment().subtract(1, 'year').year(), moment().year()];

  public yearlyUsageDate: ChartData = {datasets: []};
  public options: ChartOptions = {responsive: true, plugins: {legend: {display: true}}};
  public dateChanged: EventEmitter<{ year: number }> = new EventEmitter();

  public usage: { single_player: number, multi_player: number }[];

  private books: Book[];
  private golfClubId = '';
  private backgroundColor = ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)'];
  private borderColor = ['rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)'];

  constructor(private appService: AppService, public translate: TranslateService) {
    this.dateChanged.subscribe((year: number[]) => {
      this.yearRange = year;
      this.initData();
    });
  }

  ngOnInit(): void {
    this.appService.isLoading.next(true);
    this.getGolfClubs();
    const yearRange: number[] = [];
    this.appService.getBooks(true).subscribe((result: APIPlatformPagedCollection) => {
      this.books = result['hydra:member'];
      this.books.map((book: Book) => {
        yearRange.push(moment(book.active_from).year());
        yearRange.push(moment(book.active_to).year());
      });
      this.years = [...new Set(yearRange)].sort();
      this.initData();
    });
  }

  private getGolfClubs(): void {
    this.golfClubCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.golfClubs = [];
          this.isLoading = true;
        }),
        switchMap(value => this.appService.list('golfclubs', new HttpParams().set('status[]', 'A').set('search', value)).pipe(finalize(() => this.isLoading = false))))
      .subscribe((data: APIPlatformPagedCollection) => this.golfClubs = data['hydra:member']);
  }

  private initData(): void {
    this.appService.isLoading.next(true);
    this.yearlyUsageDate = {datasets: []};
    this.usage = [];
    const subscriptions: Observable<any>[] = [];
    this.yearRange.map((year: number) => {
      const params = new HttpParams().set('after_date', moment().year(year).startOf('year').toISOString()).set('before_date', moment().year(year).endOf('year').toISOString()).set('golf_club_id', this.golfClubId);
      subscriptions.push(this.appService.list('custom/yearly_voucher_usage_total', params));
    });

    combineLatest(subscriptions).subscribe(
      (results: APIPlatformPagedCollection[]) => {
        this.yearlyUsageDate.labels = Array.from({length: 12}, (value, key: number) => moment().month(key).format('MMM'));
        results.map(
          (result: APIPlatformPagedCollection, index: number) => {
            const values: VoucherUsageData[] = result['hydra:member'];
            this.usage.push({single_player: values.reduce((a: number, b: VoucherUsageData) => a + (b.single_player || 0), 0), multi_player: values.reduce((a: number, b: VoucherUsageData) => a + (b.multi_player || 0), 0)});

            const data: any[] = [];
            Array.from({length: 12}, (_, key: number) => moment().month(key).format('MM')).map((month: string) => {
              data.push(values.find((value: VoucherUsageData) => value.month === month)?.sum || 0);
            });
            this.yearlyUsageDate.datasets.push({label: String(this.yearRange[index]), data, backgroundColor: this.backgroundColor[index + 1], borderColor: this.borderColor[index + 1], borderWidth: 1});
            this.ref0.chartInstance.update();
          }
        );
      },
      () => null,
      () => this.appService.isLoading.next(false)
    );
  }

  public clubSelected(golfClub: GolfClub): void {
    if (golfClub && golfClub['@id']) {
      this.golfClubId = this.appService.getIdFromUrlString(golfClub['@id']);
      this.initData();
    }
  }

  public displayFn(golfClub: GolfClub): string {
    return golfClub && golfClub.name ? golfClub.name : '';
  }

  public reset(): void {
    this.golfClubId = '';
    this.initData();
  }
}
