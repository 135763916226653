<form [formGroup]="form" novalidate>

  <mat-dialog-content class="mat-typography" fxLayout="column">

    <div fxLayout="column">

      <mat-form-field>
        <mat-label>{{ 'assume clubs from other books' | translate }}</mat-label>
        <mat-select formControlName="target" [compareWith]="compareObjects">
          <mat-option *ngFor="let book of [currentBook]" [value]="book?.id">{{ book?.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="currentBookGolfClubIds.length" style="margin: -20px 5px 10px 5px; font-size: 75%;">
        <mat-error>{{ 'There are active records for this guide. These cannot be deleted or overwritten.' | translate: {value: currentBookGolfClubIds.length} }}</mat-error>
      </div>

      <!-- SELECT BOOKS -->
      <mat-form-field>
        <mat-label>{{ 'assume clubs from other books' | translate }}</mat-label>
        <mat-select formControlName="origin" (selectionChange)="onBookChanged($event.value)">
          <mat-option *ngFor="let book of books" [value]="book.id" [disabled]="book.id === currentBook?.id">{{ book.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- SELECT VOUCHER -->
      <mat-form-field>
        <mat-label>{{ 'number of vouchers for each club' | translate }}</mat-label>
        <mat-select formControlName="voucherCnt">
          <mat-option *ngFor="let voucher of vouchers" [value]="voucher">{{ voucher }} <small *ngIf="this.selectedBook?.vouchers_cnt === voucher">({{ 'assumed from club' | translate }})</small></mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="deprecatedBookGolfClubIds.length" style="margin: 0 12px 20px 0; text-align: right;">
        <mat-checkbox color="warn" (change)="deleteDeprecated = !deleteDeprecated" labelPosition="before">
          <div style="white-space:normal;">{{ 'There are inactive records for this guide. These will be deleted or overwritten.' | translate: {value: deprecatedBookGolfClubIds.length} }}</div>
        </mat-checkbox>
      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
      </span>
    </button>

    <span class="spacer"></span>

    <button mat-stroked-button color="primary" type="button" [disabled]="form.invalid || (deprecatedBookGolfClubIds.length ? !deleteDeprecated : false)" (click)="submit()">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save and close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</form>
