import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService, Book } from '../../../../shared/services/app.service';
import { ActivatedRoute, Params } from '@angular/router';
import moment, { Moment } from 'moment';

export interface BookForm extends Book {
  hour_from: number;
  minute_from: number;
  hour_to: number;
  minute_to: number;
}

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  public form: FormGroup;
  private params: Params;

  public size = 15;
  public hours: number[] = Array.from({length: 24}, (value, key) => key);
  public minutes: number[] = Array.from({length: 60}, (value, key) => key);

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private appService: AppService) {
    this.form = this.formBuilder.group({
      name: new FormControl(null, []),
      active_from: new FormControl(null, [Validators.required]),
      active_to: new FormControl(null, [Validators.required]),
      hour_from: new FormControl(null, [Validators.required]),
      minute_from: new FormControl(null, [Validators.required]),
      hour_to: new FormControl(null, [Validators.required]),
      minute_to: new FormControl(null, [Validators.required]),
      status: new FormControl('A', [Validators.required]),
      serial_nr_from: new FormControl(0, [Validators.required]),
      serial_nr_to: new FormControl(0, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.appService.isLoading.next(true);
    this.route.params.subscribe((params: Params) => {
      this.params = params;
      this.appService.get('books', params.uuid).subscribe(
        (book: any) => {
          Object.keys(book).map((key: string) => this.form.patchValue({[key]: book[key]}));
          const dateTimeFrom: Moment = moment(book.active_from);
          this.form.patchValue({hour_from: dateTimeFrom.hour()});
          this.form.patchValue({minute_from: dateTimeFrom.minute()});

          const dateTimeTo: Moment = moment(book.active_to);
          this.form.patchValue({hour_to: dateTimeTo.hour()});
          this.form.patchValue({minute_to: dateTimeTo.minute()});
        },
        () => null,
        () => this.appService.isLoading.next(false)
      );
    });
  }

  public onSubmit(): void {
    if (this.form.valid && this.form.touched) {
    this.appService.isLoading.next(true);
    const book = this.form.getRawValue() as BookForm;
    const offsetFrom: number = moment(book.active_from).isDST() ? 2 : 1;
    const offsetTo: number = moment(book.active_to).isDST() ? 2 : 1;

    book.active_from = moment(book.active_from).set('hour', (book.hour_from - offsetFrom)).set('minute', book.minute_from).format('YYYY-MM-DDTHH:mm:ss');
    book.active_to = moment(book.active_to).set('hour', (book.hour_to - offsetTo)).set('minute', book.minute_to).format('YYYY-MM-DDTHH:mm:ss');

      this.appService.patch(this.params.route, this.params.uuid, book).subscribe(
        () => this.appService.openSnackBar('Daten wurden erfolgreich gespeichert.'),
        () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
        () => {
          this.form.markAsUntouched();
          this.form.markAsPristine();
          this.appService.isLoading.next(false);
        }
      );
    }
  }

}
