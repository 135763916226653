import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../../shared/services/token-storage.service';
import { AppService, AppUser, GolfClub } from '../../shared/services/app.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public login = true;
  public forgotPassword = false;
  public resetPassword = false;
  public resetMessage = false;

  public errorMessage: string;
  public forgotMessage: string;

  private resetToken: string;

  constructor(private appService: AppService, private tokenStorage: TokenStorageService, private route: ActivatedRoute, private router: Router, private permissionsService: NgxPermissionsService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.router.navigate([this.appService.isAdmin() ? 'custom/guide_list' : 'validate']).then();
    }

    this.dialog.closeAll();

    this.route.params.subscribe((params: Params) => {
      if (params.token) {
        this.login = false;
        this.forgotPassword = false;
        this.resetPassword = true;
        this.resetMessage = false;

        if (params.token === 'resend') {
          this.forgotPassword = true;
          this.resetPassword = false;
          return;
        }

        this.resetToken = params.token;
        this.appService.post('reset-password/validate', {token: this.resetToken}).subscribe({error: () => this.resetMessage = true});
      }
    });
  }

  public onLogin(value: { username: string, password: string, saveLogin: string | boolean }): void {
    this.appService.isLoading.next(true);
    this.appService.login(value.username.trim(), value.password.trim()).subscribe(
      data => {
        this.tokenStorage.saveToken(data.token);
        this.tokenStorage.saveUser(data, !!value.saveLogin);
        this.permissionsService.loadPermissions(data.securityRoles.split(','));
        this.appService.isLoading.next(false);
        this.appService.loggedIn.next(true);
        this.router.navigate([this.appService.isAdmin() ? 'custom/guide_list' : 'validate']).then();
      },
      err => {
        this.permissionsService.flushPermissions();
        this.errorMessage = 'Login failed: Wrong username or password';
      },
      () => {
        if (!this.tokenStorage.isAdmin()) {
          const user: AppUser = this.tokenStorage.getUser();
          this.appService.get('golfclubs', String(user.golfclub.id)).subscribe(
            (golfClub: GolfClub) => user.golfclub = {...user.golfclub, ...golfClub},
            () => null,
            () => this.tokenStorage.saveUser(user)
          );
        }
      }
    );
  }

  public onReset(value: { email: string }, form: NgForm): void {
    form.resetForm('');
    this.appService.isLoading.next(true);
    this.appService.post('reset-password/token', {email: value.email}).subscribe(
      () => this.forgotMessage = 'Your password has been reset. Please check your email.',
      () => this.forgotMessage = 'An error occurred. Please enter a valid email address.',
      () => this.appService.isLoading.next(false),
    );
  }

  public setNewPassword(value: { password: string, confirm: string }, form: NgForm): void {
    form.resetForm('');
    if (value.password === value.confirm) {
      this.appService.isLoading.next(true);
      this.appService.post('reset-password/reset', {token: this.resetToken, password: value.password}).subscribe(
        () => this.router.navigate(['login']).then(),
        () => this.resetMessage = true,
        () => this.appService.isLoading.next(false),
      );
    }
  }
}
