import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AssumeComponent } from './clubs/assume/assume.component';
import { AssignComponent } from './clubs/assign/assign.component';
import { SendLoginComponent } from './golfclubs/send-login/send-login.component';
import { VoucherComponent } from './guides/voucher/voucher.component';
import { AddGuideComponent } from './guides/add-guide/add-guide.component';
import { EditGuideComponent } from './guides/edit-guide/edit-guide.component';
import { GolfClubsComponent } from './golfclubs/golfclubs.component';
import { EditNewsComponent } from './news/edit-news/edit-news.component';
import { AddNewsComponent } from './news/add-news/add-news.component';
import { DeleteNewsComponent } from './news/delete-news/delete-news.component';
import { EditOrdersComponent } from './orders/edit-orders/edit-orders.component';
import { ImportOrdersComponent } from './orders/import-orders/import-orders.component';
import { VoucherUsageComponent } from '../dashboard/voucher-usage/voucher-usage.component';
import { YearlyVoucherUsageComponent } from '../dashboard/yearly-voucher-usage/yearly-voucher-usage.component';
import { VoucherDetailComponent } from '../dashboard/voucher-detail/voucher-detail.component';
import { BookComponent } from './book/book.component';
import { LastVoucherUsageComponent } from '../dashboard/last-voucher-usage/last-voucher-usage.component';
import { AppService, Order } from '../../../shared/services/app.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AddGolfclubComponent } from './golfclubs/add-golfclub/add-golfclub.component';

export interface ActionComponent {
  action: string;
  component: any;
  print?: boolean;
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  @ViewChild('mailModal', {static: true}) mailModal: TemplateRef<any>;
  @ViewChild('modal', {static: true}) modal: TemplateRef<any>;
  public dialogRef: MatDialogRef<TemplateRef<any>, MatDialogConfig>;

  private components: ActionComponent[] = [
    {action: 'assume_clubs', component: AssumeComponent},
    {action: 'assign_clubs', component: AssignComponent},
    {action: 'send_login', component: SendLoginComponent},
    {action: 'edit_golfclub', component: GolfClubsComponent},
    {action: 'new_club', component: AddGolfclubComponent},
    {action: 'new_guide', component: AddGuideComponent},
    {action: 'edit_guide', component: EditGuideComponent},
    {action: 'show_voucher', component: VoucherComponent},
    {action: 'edit_newstext', component: EditNewsComponent},
    {action: 'add_newstexts', component: AddNewsComponent},
    {action: 'delete_news', component: DeleteNewsComponent},
    {action: 'edit_order', component: EditOrdersComponent, print: true},
    {action: 'import_order', component: ImportOrdersComponent},
    {action: 'voucher_usage', component: VoucherUsageComponent},
    {action: 'yearly_voucher_usage', component: YearlyVoucherUsageComponent},
    {action: 'voucher_usages', component: VoucherDetailComponent},
    {action: 'last_voucher_usage', component: LastVoucherUsageComponent},
    {action: 'edit_book', component: BookComponent},
  ];
  private id: number;

  public currentComponent: ActionComponent | undefined;

  constructor(private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private appService: AppService) {
    this.route.params.subscribe((params: Params) => {
      this.id = params.uuid;
      this.currentComponent = this.components.find((component: ActionComponent) => component.action === params.action);
    });
  }

  ngOnInit(): void {
    this.dialogRef = this.dialog.open(this.modal, {disableClose: true, minWidth: '95vw'});
    this.dialogRef.keydownEvents().subscribe(event => event.key === 'Escape' ? this.dialogRef.close() : null);
    this.dialogRef.afterClosed().subscribe(() => this.router.navigate([{outlets: {edit: null}}]));
  }

  public openDialog(): void {
    if (this.id) {
      this.appService.get('orders', String(this.id)).subscribe(
        (order: Order) => this.dialog.open(this.mailModal, {disableClose: true, width: '50vw', data: order}),
        (error: HttpErrorResponse) => console.log(error),
      );
    }
  }

  public sendMail(): void {
    if (this.id) {
      this.appService.get('email/order?order=' + this.id).subscribe(
        () => this.appService.openSnackBar('Email has been send.'),
        () => this.appService.openSnackBar('Oops, an error occurred!'),
      );
    }
  }

  public printPage(): void {
    const printData = document.getElementById('dataToPrint')?.cloneNode(true);
    if (printData) {
      document.body.appendChild(printData);
      window.print();
      document.body.removeChild(printData);
    }
  }

}
