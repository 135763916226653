import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppUser } from './app.service';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor(private permissionsService: NgxPermissionsService) {
  }

  public signOut(): void {
    this.permissionsService.flushPermissions();
    window.sessionStorage.clear();
    localStorage.removeItem(USER_KEY);
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {

    try {
      const localUser: AppUser = JSON.parse(localStorage.getItem(USER_KEY) || '');
      this.saveToken(localUser.token);
      this.saveUser(localUser);
    } catch (e) {
    }

    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any, saveLogin?: boolean): void {

    if (saveLogin) {
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public isAdmin(): boolean {
    return 'ROLE_ADMIN' in this.permissionsService.getPermissions();
  }

  public getUser(): AppUser | any {
    const user = window.sessionStorage.getItem(USER_KEY);
    return user ? JSON.parse(user) : {};
  }
}
