import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { TokenStorageService } from '../shared/services/token-storage.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AppService } from '../shared/services/app.service';
import { Router } from '@angular/router';

const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService, private appService: AppService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const start = performance.now();
    let authReq = req;
    const token = this.token.getToken();
    if (token != null) {
      authReq = req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)});
    }
    this.appService.isBuffering.next(true);
    return next.handle(authReq).pipe(
      tap(res => {
        const time = Math.round(performance.now() - start);
        if (time > 1000) {
          console.log('long request time: ' + time + 'ms');
        }
        return res;
      }),
      finalize(() => this.appService.isBuffering.next(false)),
      catchError((error: HttpErrorResponse) => {
        // if 401 -> Expired JWT Token
        if (error.status === 401) {
          this.token.signOut();
          this.router.navigate(['login']).then();
        }
        this.appService.isLoading.next(false);
        return throwError(error);
      })
    );
  }
}
