import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { APIPlatformPagedCollection, AppService, Book, BookGolfClub, BookGolfClubResource, Country, GolfClub, GolfClubData } from '../../../../../shared/services/app.service';
import { combineLatest, merge, Observable, of as observableOf, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-assign',
  templateUrl: './assign.component.html',
  styleUrls: ['./assign.component.scss']
})
export class AssignComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: string[] = ['id', 'club_nr', 'name', 'city', 'postal_code', 'country_id', 'single_player', 'vouchers_cnt', 'has_guide'];
  public dataSource: MatTableDataSource<any>;
  public vouchers: number[] | undefined = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public bookGolfClubsData: GolfClubData[] = [];
  public countryList: Country[] = [];
  public countryId: string[] = [];

  public filterChanged: EventEmitter<string> = new EventEmitter();
  public countryChanged: EventEmitter<string> = new EventEmitter();

  private dataSubscription: Subscription;
  private routeSubscription: Subscription;
  public book: Book;
  private filter = '';

  public defaultPageSizeOptions = [10, 15, 30, 50, 100];
  public defaultPageSize: number;
  public resultsLength: number | undefined;

  constructor(private route: ActivatedRoute, private router: Router, @Inject(LOCALE_ID) private locale: string, private changeDetector: ChangeDetectorRef, private appService: AppService, private translate: TranslateService) {
    this.filterChanged.subscribe((text: string) => this.filter = text);
    this.countryChanged.subscribe((id: string[]) => this.countryId = id);
  }

  ngOnInit(): void {
    this.appService.getCountries().subscribe((result: APIPlatformPagedCollection) => this.countryList = result['hydra:member'] as Country[]);
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.params.subscribe((params: Params) => {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      this.defaultPageSize = Number(localStorage.getItem('pageSize')) || this.defaultPageSizeOptions[0];
      this.appService.get('books', params.uuid).subscribe((book: Book) => this.book = book);
      this.filter = '';
      this.countryId = [];
      this.changeDetector.detectChanges();
      this.initTable();
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  /** inits table data   */
  private initTable(): void {
    this.paginator.firstPage();
    this.paginator.pageSize = this.defaultPageSize;
    this.sort.active = this.displayedColumns[0];
    this.sort.direction = 'desc' as SortDirection;
    this.changeDetector.detectChanges();
    this.dataSubscription = merge(this.countryChanged.pipe(map(() => this.resetPaging())), this.filterChanged.pipe(debounceTime(this.appService.filterDelay), map(() => this.resetPaging())))
      .pipe(
        startWith({}),
        switchMap(() => {
          localStorage.setItem('pageSize', String(this.paginator.pageSize));
          this.appService.isLoading.next(true);
          return this.appService.list('golfclubs', this.getHttpParams()).pipe(
            map((result: APIPlatformPagedCollection) => {
              result['hydra:member'].map((r: GolfClub) => {
                const currentBookGolfClub: BookGolfClub | undefined = r.bookGolfClub.find((bgc: any) => bgc.book['@id'] === this.book['@id']);
                r.has_guide = currentBookGolfClub?.status === 'A';
                r.vouchers_cnt = Number(currentBookGolfClub?.vouchersCnt || 0);
                r.single_player = !!currentBookGolfClub?.singlePlayer;
              });
              return result;
            })
          );
        }),
        map((data: APIPlatformPagedCollection) => {
          this.resultsLength = data['hydra:totalItems'];
          return data;
        }),
        catchError(() => {
          this.appService.isLoading.next(false);
          return observableOf([]);
        })
      ).subscribe((data: any) => {
        this.dataSource = new MatTableDataSource(data['hydra:member']);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.appService.isLoading.next(false);
      });
  }

  private getHttpParams(): HttpParams {
    const paramsObj = {
      'country_id[]': this.countryId,
      search: this.filter,
      page: String(this.paginator.pageIndex + 1),
      ['order[' + this.sort.active + ']']: this.sort.direction,
      itemsPerPage: '1000',
      'groups[]': 'golfclub:details',
      'status[]': 'A',
    };

    return new HttpParams({fromObject: paramsObj});
  }

  /** reset page index */
  public resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  /** handle table filter */
  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterChanged.emit(filterValue);
  }

  public onChangeRow(event: MatCheckboxChange | MatSelectChange, row: GolfClub, column: string): void {
    switch (column) {
      case 'has_guide':
        row.status = (event as MatCheckboxChange).checked ? 'A' : 'N';
        break;
      case 'vouchers_cnt':
        row.vouchers_cnt = (event as MatSelectChange).value;
        break;
      case 'single_player':
        row.single_player = (event as MatCheckboxChange).checked;
        break;
    }

    const resource: BookGolfClubResource = {
      book: this.book['@id'],
      golfClub: row['@id'],
      vouchersCnt: String(row.vouchers_cnt),
      status: row.status,
      singlePlayer: row.single_player || false
    };
    const bookGolfClub: any = row.bookGolfClub.find((bgc: BookGolfClub) => bgc.book.id === Number(this.book.id));
    const objIdx = this.bookGolfClubsData.findIndex((data: GolfClubData) => data.id === row.id);
    (objIdx === -1) ? this.bookGolfClubsData.push({id: row.id, endpoint: bookGolfClub?.id, resource}) : this.bookGolfClubsData[objIdx] = {id: row.id, endpoint: bookGolfClub?.id, resource};
  }

  public onSubmit(): void {
    this.appService.isLoading.next(true);
    const subscriptions: Observable<any>[] = [];
    this.bookGolfClubsData.map((data: GolfClubData) => subscriptions.push(
      data.endpoint ? this.appService.patch('book_golf_clubs', data.endpoint, data.resource) : this.appService.post('book_golf_clubs', data.resource)
    ));

    combineLatest(subscriptions).subscribe(
      () => this.appService.openSnackBar('Daten wurden erfolgreich gespeichert.'),
      () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
      () => {
        this.bookGolfClubsData = [];
        this.appService.isLoading.next(false);
      }
    );
  }

  /** get country object */
  public getCountry(column: string | any, row: string[]): Country | undefined {
    return this.countryList.find((c: Country) => c.id === Number(row[column]));
  }

}
