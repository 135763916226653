import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListComponent } from './components/backend/list/list.component';
import { EditComponent } from './components/backend/edit/edit.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './shared/services/auth-guard.service';
import { HomeComponent } from './components/home/home.component';
import { DashboardComponent } from './components/backend/dashboard/dashboard.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ValidationComponent } from './components/frontend/validation/validation.component';
import { DevaluationComponent } from './components/frontend/devaluation/devaluation.component';
import { StatisticsComponent } from './components/frontend/statistics/statistics.component';
import { DetailComponent } from './components/frontend/statistics/detail/detail.component';

const routes: Routes = [

  // login
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'login/:token', component: LoginComponent},

  // static routes
  // {path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: {permissions: {only: ['ROLE_USER', 'ROLE_ADMIN'], redirectTo: 'login'}}},
  {path: 'validate', component: ValidationComponent, canActivate: [AuthGuard, NgxPermissionsGuard], data: {permissions: {only: ['ROLE_USER'], redirectTo: 'login'}}},
  {path: 'devaluation', component: DevaluationComponent, canActivate: [AuthGuard, NgxPermissionsGuard], data: {permissions: {only: ['ROLE_USER'], redirectTo: 'login'}}},
  {path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuard, NgxPermissionsGuard], data: {permissions: {only: ['ROLE_USER'], redirectTo: 'login'}}},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, NgxPermissionsGuard], data: {permissions: {only: ['ROLE_ADMIN'], redirectTo: 'login'}}},

  // list views
  {path: ':route', component: ListComponent, canActivate: [AuthGuard, NgxPermissionsGuard], data: {permissions: {only: ['ROLE_ADMIN'], redirectTo: 'login'}}},
  {path: ':route/:mode', component: ListComponent, canActivate: [AuthGuard, NgxPermissionsGuard], data: {permissions: {only: ['ROLE_ADMIN'], redirectTo: 'login'}}},

  // edit views
  {path: ':route/:uuid/:action', outlet: 'edit', component: EditComponent, canActivate: [AuthGuard, NgxPermissionsGuard], data: {permissions: {only: ['ROLE_ADMIN'], redirectTo: 'login'}}},
  // detail view
  {path: ':year/:month', outlet: 'detail', component: DetailComponent, canActivate: [AuthGuard, NgxPermissionsGuard], data: {permissions: {only: ['ROLE_USER'], redirectTo: 'login'}}},
  {path: ':year/:month/:book', outlet: 'detail', component: DetailComponent, canActivate: [AuthGuard, NgxPermissionsGuard], data: {permissions: {only: ['ROLE_USER'], redirectTo: 'login'}}},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
