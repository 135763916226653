
<!--<a *ngIf="order && getOrderID()" mat-flat-button class="action-button" [href]="'https://www.leisurebreaks.de/wp-admin/post.php?post=' + getOrderID() + '&action=edit'" target="_blank">-->
<!--  <mat-icon>shopping_cart</mat-icon>-->
<!--</a>-->

<mat-dialog-content class="mat-typography">
  <div id="dataToPrint">
    <img class="logo" src="../../app/assets/img/leisurebreaks.svg" [alt]="'app-title' | translate">
    <div class="container" *ngIf="order">

      <h2>{{ 'order data' | translate }}</h2>
      <table class="table">
        <tr>
          <td>{{ 'order_nr' | translate }}</td>
          <td>{{ order.billing_country_code }}-{{ getOrderID() }}</td>
        </tr>
        <tr>
          <td>{{ 'order_id' | translate }}</td>
          <td>{{ order.id }}</td>
        </tr>
        <tr>
          <td>{{ 'order_date' | translate }}</td>
          <td>{{ order.createdAt| date:"dd.MM.YYYY" }}</td>
        </tr>
        <tr>
          <td>{{ 'payment_kind' | translate }}</td>
          <td>{{ order.payment_kind | translate  }}</td>
        </tr>
        <tr>
          <td>{{ 'payment_status' | translate }}</td>
          <td>{{ (order.payment_status || 'not-finished') | translate }}</td>
        </tr>
        <tr *ngIf="order.paid_at">
          <td>{{ 'paid_at' | translate }}</td>
          <td>{{ order.paid_at | date:"dd.MM.YYYY H:m" }}</td>
        </tr>
        <tr *ngIf="order.paid_status_changed_at">
          <td>{{ 'paid_status_changed_at' | translate }}</td>
          <td>{{ order.paid_status_changed_at | date:"dd.MM.YYYY H:m" }}</td>
        </tr>
        <tr>
          <td>{{ 'guide_own_usage' | translate }}</td>
          <td>{{ order.guide_own_usage | translate }}</td>
        </tr>
      </table>

      <h2>{{ 'delivery data' | translate }}</h2>
      <table class="table">
        <tr>
          <td>{{ 'name' | translate }}</td>
          <td>{{ 'salutation.' + order.delivery_salutation | translate }} {{ order.delivery_first_name }} {{ order.delivery_last_name }}</td>
        </tr>
        <tr>
          <td>{{ 'address' | translate }}</td>
          <td>{{ order.delivery_street }} {{ order.delivery_postal_code }} {{ order.delivery_city }} <span class="flag-icon flag-icon-{{ (order.delivery_country_code).toLowerCase() }}"></span></td>
        </tr>
        <tr>
          <td>{{ 'email' | translate }}</td>
          <td>{{ order.delivery_email }}</td>
        </tr>
        <tr>
          <td>{{ 'phone' | translate }}</td>
          <td>{{ order.delivery_phone }}</td>
        </tr>
      </table>

      <h2>{{ 'payment data' | translate }}</h2>
      <table class="table" *ngIf="equals()">
        <tr>
          <td>{{ 'same as delivery data' | translate }}</td>
        </tr>
      </table>
      <table class="table" *ngIf="!equals()">
        <tr>
          <td>{{ 'name' | translate }}</td>
          <td>{{ 'salutation.' + order.billing_salutation | translate }} {{ order.billing_first_name }} {{ order.billing_last_name }}</td>
        </tr>
        <tr>
          <td>{{ 'address' | translate }}</td>
          <td>{{ order.billing_street }} {{ order.billing_postal_code }} {{ order.billing_city }} <span class="flag-icon flag-icon-{{ (order.billing_country_code).toLowerCase() }}"></span></td>
        </tr>
        <tr>
          <td>{{ 'email' | translate }}</td>
          <td>{{ order.billing_email }}</td>
        </tr>
        <tr>
          <td>{{ 'phone' | translate }}</td>
          <td>{{ order.billing_phone }}</td>
        </tr>
      </table>

      <h2>{{ 'product data' | translate }}</h2>
      <table class="table product-table">
        <tr>
          <th>{{ 'product' | translate }}</th>
          <th style="text-align: right">{{ 'quantity' | translate }}</th>
          <th style="text-align: right">{{ 'single_price' | translate }}</th>
          <th style="text-align: right">{{ 'price' | translate }}</th>
        </tr>
        <tr *ngFor="let product of products">
          <td>{{ product.description }}</td>
          <td align="right">{{ product.quantity }}</td>
          <td align="right">{{ product.price_per_unit | currency:'EUR' }}</td>
          <td align="right">{{ product.price | currency:'EUR' }}</td>
        </tr>
        <tr>
          <td colspan="3">{{ 'delivery_price' | translate }}</td>
          <td align="right">{{ order.delivery_price | currency:'EUR' }}</td>
        </tr>
        <tr>
          <td colspan="3">{{ 'total' | translate }}</td>
          <td align="right">{{ order.price | currency:'EUR' }}</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<!--<mat-accordion>-->
<!--  <mat-expansion-panel hideToggle>-->
<!--    <mat-expansion-panel-header>-->
<!--      <mat-panel-title>-->
<!--        This is the expansion title-->
<!--      </mat-panel-title>-->
<!--      <mat-panel-description>-->
<!--        This is a summary of the content-->
<!--      </mat-panel-description>-->
<!--    </mat-expansion-panel-header>-->
<!--    <p>This is the primary content of the panel.</p>-->
<!--  </mat-expansion-panel>-->

<!--  <mat-expansion-panel>-->
<!--    <mat-expansion-panel-header>-->
<!--      <mat-panel-title>-->
<!--        Self aware panel-->
<!--      </mat-panel-title>-->
<!--      <mat-panel-description>-->

<!--      </mat-panel-description>-->
<!--    </mat-expansion-panel-header>-->
<!--    <p>I'm visible because I am open</p>-->
<!--  </mat-expansion-panel>-->
<!--</mat-accordion>-->
