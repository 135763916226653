import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import moment, { Moment } from 'moment';
import { APIPlatformPagedCollection, AppService, AppUser, Book, GolfClub } from '../../../../shared/services/app.service';
import { HttpParams } from '@angular/common/http';
import { ChartjsComponent } from '@ctrl/ngx-chartjs';
import { ChartData, ChartOptions } from 'chart.js';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TokenStorageService } from '../../../../shared/services/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export interface VoucherUsageData {
  name?: string;
  year?: string;
  month?: string;
  used_tstamp: string;
  single_player: number;
  multi_player: number;
  sum: number;
}

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss', '../statistics.component.scss']
})
export class DetailComponent implements OnInit {

  @ViewChild('modal', {static: true}) modal: TemplateRef<any>;
  @ViewChild('ref0', {static: false}) ref0!: ChartjsComponent;
  @ViewChild('ref1', {static: false}) ref1!: ChartjsComponent;
  @ViewChild('ref2', {static: false}) ref2!: ChartjsComponent;

  private golfClub: GolfClub;
  // private backgroundColor = ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)'];
  // private borderColor = ['rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)'];

  private backgroundColor = ['#9daf77', '#c5ce9033'];
  private borderColor = ['#77913f', '#c5ce90'];


  public dialogRef: MatDialogRef<TemplateRef<any>, MatDialogConfig>;
  public date: Moment;
  public dailyUsageData: ChartData = {datasets: []};
  public weeklyUsageData: ChartData = {
    datasets: [
      {
        data: [],
        fill: false,
        backgroundColor: this.backgroundColor[0],
        borderColor: this.borderColor[0],
        borderWidth: 1,
      },
    ],
  };
  public hourlyUsageData: ChartData = {
    datasets: [
      {
        data: [],
        fill: false,
        backgroundColor: this.backgroundColor[0],
        borderColor: this.borderColor[0],
        borderWidth: 1,
      },
    ],
  };
  public options: ChartOptions = {responsive: true, plugins: {legend: {display: true}}, scales: {xAxes: {stacked: true}, yAxes: {stacked: true}}};
  public barOptions: ChartOptions = {...this.options, plugins: {legend: {display: false}}};
  public displayedColumns: string[] = ['day'];
  public dataSource: any[] = [];
  public totalUsage: number;
  public book: Book;

  constructor(public route: ActivatedRoute, public router: Router, public dialog: MatDialog, public appService: AppService, public tokenStorage: TokenStorageService, public translate: TranslateService) {
    const user: AppUser = this.tokenStorage.getUser();
    this.golfClub = user.golfclub;
  }

  ngOnInit(): void {
    this.dialogRef = this.dialog.open(this.modal, {disableClose: true, width: '60vw'});
    this.dialogRef.keydownEvents().subscribe(event => event.key === 'Escape' ? this.dialogRef.close() : null);
    this.dialogRef.afterClosed().subscribe(() => this.router.navigate([{outlets: {detail: null}}]));

    this.route.params.subscribe((params: Params) => {
      console.log(params);
      this.date = moment().year(params.year).month(params.month - 1);
      if (params.book) {
        const param = new HttpParams().set('club_id', this.appService.getIdFromUrlString(this.golfClub['@id'])).set('book_id', params.book).set('month_nr', params.month).set('year_nr', params.year);
        this.appService.get('books', params.book).subscribe((book: Book) => this.book = book);
        this.initData(this.appService.list('custom/daily_book_voucher_usage', param));
      } else {
        const param = new HttpParams().set('club_id', this.appService.getIdFromUrlString(this.golfClub['@id'])).set('after_date', this.date.startOf('month').toISOString()).set('before_date', this.date.endOf('month').toISOString());
        this.initData(this.appService.list('custom/daily_voucher_usage', param));
      }
    });
  }

  private initData(obs: Observable<APIPlatformPagedCollection>): void {
    this.appService.isLoading.next(true);
    obs.subscribe(
      (result: APIPlatformPagedCollection) => {
        const values: VoucherUsageData[] = result['hydra:member'];
        const dayRange = Array.from(Array(this.date.daysInMonth()), (_, i: number) => i + 1);
        const singlePlayerData: number[] = [];
        const multiPlayerData: number[] = [];

        this.totalUsage = values.reduce((a: number, b: VoucherUsageData) => a + (b.sum || 0), 0);
        // daily usage chart
        this.dailyUsageData.labels = dayRange;
        dayRange.map((day: number) => {
          singlePlayerData.push(values.filter(v => moment(v.used_tstamp).date() === day).reduce((a: number, b: VoucherUsageData) => a + (b.single_player || 0), 0));
          multiPlayerData.push(values.filter(v => moment(v.used_tstamp).date() === day).reduce((a: number, b: VoucherUsageData) => a + (b.multi_player || 0), 0));
        });
        this.dailyUsageData.datasets.push({label: this.translate.instant('single_player'), data: singlePlayerData, backgroundColor: this.backgroundColor[0], borderColor: this.borderColor[0], borderWidth: 1});
        this.dailyUsageData.datasets.push({label: this.translate.instant('multi_player'), data: multiPlayerData, backgroundColor: this.backgroundColor[1], borderColor: this.borderColor[1], borderWidth: 1});

        // daily usage table
        const singleData: any = {day: 'single_player'};
        const multiData: any = {day: 'multi_player'};
        dayRange.forEach((key: number, i: number) => {
          singleData[key] = singlePlayerData[i];
          multiData[key] = multiPlayerData[i];
        });
        this.displayedColumns = this.displayedColumns.concat(dayRange.map((d: number) => String(d)));
        this.displayedColumns.push('total');
        this.dataSource = [singleData, multiData];

        // weekly usage chart
        const weeklyData: any = [1, 2, 3, 4, 5, 6, 7].map((i: number) => moment().day(i).format('dddd')).reduce((acc: any, curr: any) => (acc[curr] = 0, acc), {});
        values.map(v => weeklyData[moment(v.used_tstamp).format('dddd')] = weeklyData[moment(v.used_tstamp).format('dddd')] + v.sum);
        this.weeklyUsageData.datasets[0].data = weeklyData;

        // hourly usage chart
        const hours = Array.from({length: 24}, (_, key: number) => key + 1);
        const hourlyData: any = hours.reduce((acc: any, curr: any) => (acc[curr] = 0, acc), {});
        values.map(v => hourlyData[moment(v.used_tstamp).hour()] = hourlyData[moment(v.used_tstamp).hour()] + v.sum);
        this.hourlyUsageData.datasets[0].data = hourlyData;
      },
      () => null,
      () => {
        this.ref0.chartInstance.update();
        this.ref1.chartInstance.update();
        this.ref2.chartInstance.update();
        this.appService.isLoading.next(false);
      }
    );
  }

  public getRowTotal(row: any[]): number {
    return Object.values(row).reduce((a, b) => a + (isNaN(b) ? 0 : b));
  }

  public getTotal(source: any[], column: string): number {
    return source.map(t => t[column]).reduce((acc, value) => acc + value, 0);
  }

}
