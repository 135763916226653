<form [formGroup]="form" (submit)="onSubmit()" autocomplete="off">

  <mat-dialog-content class="mat-typography" fxLayout="column">

    <mat-form-field>
      <mat-label>{{ 'guides' | translate }}</mat-label>
      <mat-select formControlName="book" required (valueChange)="setCurrentBook($event)">
        <mat-option *ngFor="let book of books; index as i" [value]="book">{{book.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <span fxFlex fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'serial_nr' | translate }}</mat-label>
        <input type="number" matInput formControlName="serial_nr" required [readonly]="editMode" #serialInput>
        <mat-hint *ngIf="!editMode" align="end">{{ 'please type a number between' | translate: {value: (idRange.serial_nr_from + ' - ' + idRange.serial_nr_to)} }}</mat-hint>
        <mat-error align="end" *ngIf="form.controls.serial_nr.errors?.min || form.controls.serial_nr.errors?.max">
          <span *ngIf="form.controls.serial_nr.errors?.min">{{ 'number is to low' | translate }}! </span>
          <span *ngIf="form.controls.serial_nr.errors?.max">{{ 'number is to height' | translate }}! </span>
          <span>{{ 'please type a number between' | translate: {value: (idRange.serial_nr_from + ' - ' + idRange.serial_nr_to)} }}</span>
        </mat-error>
        <mat-error align="end" *ngIf="form.controls.serial_nr.errors?.alreadyExists">
          {{ 'this serial number already exists for this book' | translate }}
        </mat-error>
        <button type="button" *ngIf="form.controls.serial_nr.errors?.alreadyExists" matSuffix mat-icon-button [matTooltip]="'edit existing data'| translate" (click)="updateFormWithExistingData()">
          <mat-icon>manage_accounts</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'activation_code' | translate }}</mat-label>
        <input type="text" matInput formControlName="activation_code" readonly>
      </mat-form-field>
    </span>

    <span fxFlex fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'salutation.text' | translate }}</mat-label>
        <mat-select formControlName="salutation_id">
          <mat-option *ngFor="let salutation of [1,2]" [value]="salutation">{{ 'salutation.' + salutation | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'first_name' | translate }}</mat-label>
        <input type="text" matInput formControlName="first_name">
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'last_name' | translate }}</mat-label>
        <input type="text" matInput formControlName="last_name">
      </mat-form-field>
    </span>

    <mat-form-field>
      <mat-label>{{ 'email' | translate }}</mat-label>
      <input type="email" matInput formControlName="email" #emailInput>
      <mat-error align="end" *ngIf="form.controls.email.errors?.pattern">{{ 'please enter a valid email' | translate }}</mat-error>
      <mat-error align="end" *ngIf="form.controls.email.errors?.alreadyExists">
        {{ 'this email already exists for this book' | translate }}
      </mat-error>
    </mat-form-field>

    <span fxFlex fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'street' | translate }}</mat-label>
        <input type="text" matInput formControlName="street">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'postal_code' | translate }}</mat-label>
        <input type="text" matInput formControlName="postal_code">
        <mat-error align="end" *ngIf="form.controls.postal_code.errors?.pattern">{{ 'please enter a valid postal_code' | translate }}</mat-error>
      </mat-form-field>
    </span>

    <span fxFlex fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'city' | translate }}</mat-label>
        <input type="text" matInput formControlName="city">
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'country_id' | translate }}</mat-label>
        <mat-select formControlName="country_id">
          <mat-option *ngFor="let country of countries" [value]="country.id">
            <span class="flag-icon flag-icon-{{ country?.code?.toLowerCase() }}"></span>
            {{country.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>

    <span fxFlex fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'status' | translate }}</mat-label>
        <mat-select formControlName="status" required>
          <mat-option *ngFor="let status of ['A', 'S', 'D', 'O']" [value]="status" [disabled]="['D', 'O'].includes(status)">{{ status | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </span>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn">
       <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
       </span>
    </button>

    <span class="spacer"></span>

    <button [disabled]="!canDelete()" mat-stroked-button color="warn" type="button" [matTooltip]="'delete guide data' | translate" (click)="openModal('delete')">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'delete' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">delete</mat-icon>
      </span>
    </button>

    <button [disabled]="!canDelete()" mat-stroked-button color="warn" type="button" [matTooltip]="'reset guide data' | translate" (click)="openModal('reset')">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'reset' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">restart_alt</mat-icon>
      </span>
    </button>

    <span class="spacer"></span>

    <button *ngIf="editMode" mat-stroked-button color="accent" type="submit" [disabled]="form.invalid || !form.dirty || !form.touched">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">save_alt</mat-icon>
      </span>
    </button>

    <button mat-dialog-close mat-stroked-button color="primary" type="submit" [disabled]="form.invalid || !form.dirty || !form.touched">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save and close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</form>


<ng-template #guideModal let-data>

  <h2 mat-dialog-title>{{ data + ' guide data' | translate }}</h2>

  <mat-dialog-content class="mat-typography">
    <h2 *ngIf="data === 'delete'">{{ 'are you sure you want to delete this entry' | translate }}</h2>
    <h2 *ngIf="data === 'reset'">{{ 'are you sure you want to reset this entry' | translate }}</h2>
    <h3 *ngIf="data === 'delete'">{{ 'this action cannot be undone' | translate }}</h3>

  </mat-dialog-content>

  <div mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="null" cdkFocusInitial>{{ 'close' | translate }}</button>
    <span class="spacer"></span>
    <button color="warn" mat-stroked-button [mat-dialog-close]="data">{{ data + ' and close' | translate }}</button>
  </div>

</ng-template>
