<mat-dialog-content class="mat-typography">
  <mat-checkbox class="margin" [checked]="allComplete" color="primary" [indeterminate]="someComplete()" (change)="setAll($event.checked)">
    {{ task.name | translate }}
  </mat-checkbox>
  <ul>
    <li *ngFor="let subtask of task.subtasks">
      <mat-checkbox [(ngModel)]="subtask.completed" color="primary" (ngModelChange)="updateAllComplete()">
        {{ (subtask.text || '') | translate }} <small>({{ subtask.name }})</small>
      </mat-checkbox>
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
      </span>
  </button>

  <span class="spacer"></span>

  <button mat-stroked-button color="primary" (click)="onSubmit()" [disabled]="!someComplete() && !allComplete">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'send' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">forward_to_inbox</mat-icon>
      </span>
  </button>
</mat-dialog-actions>
