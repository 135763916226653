import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaMarshaller } from '@angular/flex-layout';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CommonMaterialModule {
  private lastValue: any[];

  public constructor(m: MediaMarshaller) {
    // hack until resolve: https://github.com/angular/flex-layout/issues/1201
    // @ts-ignore
    m.subject.subscribe((x) => {
      // @ts-ignore
      if (m.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0) {
        // @ts-ignore
        this.lastValue = [...m.activatedBreakpoints];
      } else {
        // @ts-ignore
        m.activatedBreakpoints = [...this.lastValue];
        // @ts-ignore
        m.hook.collectActivations = () => {
        };
        // @ts-ignore
        m.hook.deactivations = [...this.lastValue];
      }
    });
  }

}
