<div class="virtual-voucher">
  <img class="logo" src="../app/assets/img/leisurebreaks.svg" [alt]="'app-title' | translate">
  <div class="text">
    <img src="../../../app/assets/img/voucher.png" height="21" width="30" alt="virtual voucher"/>
    <span>Virtual Voucher</span>
  </div>
</div>

<form *ngIf="login" #form="ngForm" (ngSubmit)="onLogin(form.value)" autocomplete="off">
  <mat-card class="mat-elevation-z0">

    <mat-card-header>
      <button color="primary" class="mat-elevation-z0" mat-mini-fab mat-card-avatar>
        <mat-icon>golf_course</mat-icon>
      </button>
      <mat-card-title>{{ 'app-name' | translate }} - {{ 'login' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'Please login with your username and password.' | translate }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <div fxFlex fxLayout="column" fxLayoutGap="10px" style="margin-top: 30px;">
        <mat-form-field>
          <mat-label>{{ 'username' | translate }}</mat-label>
          <input matInput type="text" required ngModel name="username">
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'password' | translate }}</mat-label>
          <input matInput type="password" required minlength="3" ngModel name="password">
        </mat-form-field>

        <mat-checkbox ngModel name="saveLogin">{{ 'remain signed in' | translate }}</mat-checkbox>

        <mat-hint *ngIf="form.submitted && errorMessage" class="mat-error">{{ errorMessage | translate }}</mat-hint>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayout="row">
      <a href="javascript:" (click)="forgotPassword = true; login = false">{{ 'forgot password?' | translate }}</a>
      <span class="spacer"></span>
      <button mat-stroked-button color="primary" type="submit" [disabled]="form.invalid">{{ 'login' | translate }}</button>
    </mat-card-actions>
  </mat-card>
</form>

<form *ngIf="forgotPassword" #form="ngForm" (ngSubmit)="onReset(form.value, form)" autocomplete="off">
  <mat-card class="mat-elevation-z5">

    <mat-card-header>
      <button color="primary" class="mat-elevation-z0" mat-mini-fab mat-card-avatar>
        <mat-icon>golf_course</mat-icon>
      </button>
      <mat-card-title>{{ 'app-name' | translate }} - {{ 'forgot password?' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'Please enter your email address.' | translate }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <div fxFlex fxLayout="column" fxLayoutGap="10px" style="margin-top: 30px;">
        <mat-form-field>
          <mat-label>{{ 'email' | translate }}</mat-label>
          <input matInput type="email" required ngModel name="email">
        </mat-form-field>

        <mat-hint *ngIf="forgotMessage" class="mat-error">{{ forgotMessage | translate }}</mat-hint>
      </div>
    </mat-card-content>

    <mat-card-actions fxLayout="row">
      <a href="javascript:" (click)="forgotPassword = false; login = true; forgotMessage = ''">{{ 'back' | translate }}</a>
      <span class="spacer"></span>
      <button mat-stroked-button color="primary" type="submit" [disabled]="form.invalid">{{ 'reset password' | translate }}</button>
    </mat-card-actions>
  </mat-card>
</form>

<form *ngIf="resetPassword" #form="ngForm" (ngSubmit)="setNewPassword(form.value, form)" autocomplete="off">
  <mat-card class="mat-elevation-z5">

    <mat-card-header>
      <button color="primary" class="mat-elevation-z0" mat-mini-fab mat-card-avatar>
        <mat-icon>golf_course</mat-icon>
      </button>
      <mat-card-title>{{ 'app-name' | translate }} - {{ 'reset password' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'Please enter and confirm a new password.' | translate }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <div fxFlex fxLayout="column" fxLayoutGap="10px" style="margin-top: 30px;">
        <mat-form-field>
          <mat-label>{{ 'password' | translate }}</mat-label>
          <input matInput type="password" required minlength="3" ngModel name="password" #p1="ngModel">
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'confirm password' | translate }}</mat-label>
          <input matInput type="password" required minlength="3" ngModel name="confirm" #p2="ngModel">
        </mat-form-field>

        <mat-hint>
          <span *ngIf="p1.dirty && p2.dirty && (p1.invalid || p2.invalid)" class="mat-error">{{ 'Please enter at least 3 characters.' | translate }}</span>
          <span *ngIf="p1.dirty && p2.dirty && (p1.value !== p2.value)" class="mat-error">{{ 'The passwords must match.' | translate }}</span>
        </mat-hint>

        <mat-hint *ngIf="resetMessage" class="mat-error">{{ 'token already expired' | translate }}</mat-hint>

      </div>
    </mat-card-content>
    <mat-card-actions fxLayout="row">
      <a href="#" routerLink="/login">{{ 'login' | translate }}</a>
      <span class="spacer"></span>
      <button *ngIf="!resetMessage" mat-stroked-button color="primary" type="submit" [disabled]="form.invalid || (p1.value !== p2.value) || resetMessage">{{ 'reset password' | translate }}</button>
      <button *ngIf="resetMessage" mat-stroked-button color="primary" type="button" [routerLink]="'/login/resend'">{{ 'resend email' | translate }}</button>
    </mat-card-actions>
  </mat-card>
</form>
