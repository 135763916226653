import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppService, HydraMember } from '../../../../shared/services/app.service';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';

export interface News extends HydraMember {
  'changed_by': number;
  'changed_tstamp': Date;
  'createdAt': Date;
  'created_by': number;
  'created_tstamp': Date;
  'expiry_tstamp': Date;
  'id': number;
  'lastModified': Date;
  'release_tstamp': Date;
  'status': string;
  'title': string;
  'txt': string;
}

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent {

  public form: FormGroup;
  public editMode = false;
  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']]
  };

  constructor(public route: ActivatedRoute, public formBuilder: FormBuilder, public appService: AppService) {
    this.form = this.formBuilder.group({
      title: new FormControl(null, []),
      txt: new FormControl(null, []),
      release_tstamp: new FormControl(null, []),
      expiry_tstamp: new FormControl(null, []),
      status: new FormControl('A', []),
    });
  }

  public onSubmit(): void {
  }

}
