<form [formGroup]="form" (submit)="onSubmit()">

  <mat-dialog-content class="mat-typography" fxLayout="column">

    <mat-form-field fxLayout="column">
      <mat-label>{{ 'guide name' | translate }}</mat-label>
      <input type="text" matInput formControlName="name">
    </mat-form-field>

    <span fxFlex fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'active_from' | translate }}</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="active_from">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field [fxFlex]="size">
        <mat-label>{{ 'hour' | translate }}</mat-label>
        <mat-select formControlName="hour_from">
          <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour < 10 ? '0' + hour : hour }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [fxFlex]="size">
        <mat-label>{{ 'minute' | translate }}</mat-label>
        <mat-select formControlName="minute_from">
          <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute < 10 ? '0' + minute : minute }}</mat-option>
        </mat-select>
      </mat-form-field>
    </span>

    <span fxFlex fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'active_to' | translate }}</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="active_to">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field [fxFlex]="size">
        <mat-label>{{ 'hour' | translate }}</mat-label>
        <mat-select formControlName="hour_to">
          <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [fxFlex]="size">
        <mat-label>{{ 'minute' | translate }}</mat-label>
        <mat-select formControlName="minute_to">
          <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute < 10 ? '0' + minute : minute }}</mat-option>
        </mat-select>
      </mat-form-field>
    </span>

    <span fxFlex fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'serial_nr_from' | translate }}</mat-label>
        <input type="number" matInput formControlName="serial_nr_from">
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'serial_nr_to' | translate }}</mat-label>
        <input type="number" matInput formControlName="serial_nr_to">
      </mat-form-field>
    </span>

    <mat-form-field>
      <mat-label>{{ 'status' | translate }}</mat-label>
      <mat-select formControlName="status" required>
        <mat-option *ngFor="let status of ['A', 'O']" [value]="status">{{ status | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
      </span>
    </button>

    <span class="spacer"></span>

    <button mat-stroked-button color="accent" type="submit" [disabled]="form.invalid || !form.dirty || !form.touched">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">save_alt</mat-icon>
      </span>
    </button>

    <button mat-dialog-close mat-stroked-button color="primary" type="submit" [disabled]="form.invalid || !form.dirty || !form.touched">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save and close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</form>
