import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { APIPlatformPagedCollection, AppService, Book, GolfClub } from '../../../shared/services/app.service';
import { FormControl } from '@angular/forms';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public defaultPageSizeOptions = [10, 15, 30, 50, 100];
  public defaultPageSize: number;
  public golfClubCtrl = new FormControl();
  public booksChanged: EventEmitter<{ book: Book }> = new EventEmitter();
  public golfClubs: GolfClub[];
  public books: Book[] = [];
  public booksList: number[];
  public isLoading = false;

  public displayedColumns: string[] = ['name'];
  public dataSource: MatTableDataSource<any>;

  private golfClubId = '';

  constructor(private appService: AppService, private router: Router) {
    this.defaultPageSize = Number(localStorage.getItem('pageSize')) || this.defaultPageSizeOptions[0];
    this.booksChanged.subscribe((books: number[]) => {
      this.booksList = books;
      this.initData();
    });
  }

  ngOnInit(): void {
    this.getGolfClubs();
    this.appService.getBooks(true).subscribe((result: APIPlatformPagedCollection) => {
      this.books = result['hydra:member'];
      this.booksList = this.books.map((book: Book) => book.id).slice(0, 3);
      this.initData();
    });
  }

  private initData(): void {
    this.appService.isLoading.next(true);
    this.displayedColumns = ['name'];
    this.dataSource = new MatTableDataSource();
    const subscriptions: Observable<any>[] = [];
    this.booksList.map((id: number) => {
      const params = new HttpParams().set('book_id', String(id)).set('golf_club_id', this.golfClubId);
      subscriptions.push(this.appService.list('custom/total_voucher_usages', params));
    });

    this.displayedColumns = [...this.displayedColumns, ...this.booksList.map((i: number) => String(i)), ...['total']];

    combineLatest(subscriptions).subscribe(
      (results: APIPlatformPagedCollection[]) => {
        results.map(
          (result: APIPlatformPagedCollection) => {
            const data = Object.values([...this.dataSource.data, ...result['hydra:member']].reduce(
              (res, {name, ...rest}) => {
                res[name] = {...(res[name] || {}), name, ...rest};
                return res;
              }, {}));

            this.dataSource = new MatTableDataSource(data);
          }
        );
      },
      () => null,
      () => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.appService.isLoading.next(false);
      }
    );
  }

  private getGolfClubs(): void {
    this.golfClubCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.golfClubs = [];
          this.isLoading = true;
        }),
        switchMap(value => this.appService.list('golfclubs', new HttpParams().set('status[]', 'A').set('search', value)).pipe(finalize(() => this.isLoading = false))))
      .subscribe((data: APIPlatformPagedCollection) => this.golfClubs = data['hydra:member']);
  }

  public getBookNameById(id: number | string): string {
    return this.books.find((book: Book) => this.appService.getIdFromUrlString(book['@id']) === id)?.name || String(id);
  }

  public clubSelected(golfClub: GolfClub): void {
    if (golfClub && golfClub['@id']) {
      this.golfClubId = this.appService.getIdFromUrlString(golfClub['@id']);
      this.initData();
    }
  }

  public displayFn(golfClub: GolfClub): string {
    return golfClub && golfClub.name ? golfClub.name : '';
  }

  public reset(): void {
    this.golfClubId = '';
    this.initData();
  }

  public getRowTotal(row: any[]): number {
    let sum = 0;
    this.booksList.map((key: number) => sum = sum + (row[key] || 0));
    return sum;
  }

  public routerLink(uuid: string): void {
    this.router.navigate([{outlets: {edit: `voucher/${uuid}/voucher_usages`}}]).then();
  }
}
