<ng-template #modal>
  <button mat-flat-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 mat-dialog-title>{{ totalUsage }} {{ 'devaluations' | translate }} ({{ date?.format('MMMM YYYY') }})</h2>
  <h4 mat-dialog-title *ngIf="book">{{ book.name }}</h4>
  <mat-dialog-content class="mat-typography">

    <ngx-chartjs #ref0 type="bar" [data]="dailyUsageData" [options]="options"></ngx-chartjs>
    <table mat-table [dataSource]="dataSource">

      <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column" [ngSwitch]="column">

        <ng-container *ngSwitchCase="'total'">
          <th mat-header-cell *matHeaderCellDef>{{ column | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ getRowTotal(row) }}</td>
          <td mat-footer-cell *matFooterCellDef> {{ totalUsage }} </td>
        </ng-container>

        <ng-container *ngSwitchCase="'day'">
          <th mat-header-cell *matHeaderCellDef>{{ column | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row[column] | translate }}</td>
          <td mat-footer-cell *matFooterCellDef> {{ 'total' | translate }} </td>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
          <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
          <td mat-footer-cell *matFooterCellDef>{{ getTotal(dataSource, column) }}</td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>

    <div fxLayout="row wrap" style="margin-top: 40px">
      <div fxFlex="50%">
        <h3>{{ 'strongest day of the week' | translate }}</h3>
        <ngx-chartjs #ref1 type="bar" [data]="weeklyUsageData" [options]="barOptions"></ngx-chartjs>
      </div>
      <div fxFlex="50%">
        <h3>{{ 'strongest time of day' | translate }}</h3>
        <ngx-chartjs #ref2 type="bar" [data]="hourlyUsageData" [options]="barOptions"></ngx-chartjs>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

