import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FrontendComponent } from '../frontend.component';
import moment from 'moment';
import { APIPlatformPagedCollection, AppService, GolfClub } from '../../../shared/services/app.service';
import { ChartData, ChartOptions } from 'chart.js';
import { ChartjsComponent } from '@ctrl/ngx-chartjs';
import { TranslateService } from '@ngx-translate/core';
import { TokenStorageService } from '../../../shared/services/token-storage.service';
import { HttpParams } from '@angular/common/http';
import { combineLatest } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent extends FrontendComponent implements OnInit {

  @ViewChild('ref0', {static: true}) ref0!: ChartjsComponent;
  @ViewChild('ref1', {static: true}) ref1!: ChartjsComponent;
  // @ViewChild('ref2', {static: true}) ref2!: ChartjsComponent;
  @ViewChild('ref3', {static: true}) ref3!: ChartjsComponent;
  @ViewChild('ref4', {static: true}) ref4!: ChartjsComponent;

  private monthRage = Array.from({length: 12}, (value, key: number) => moment().month(key).format('MMM'));
  // private backgroundColor = ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)'];
  // private borderColor = ['rgb(33,33,33)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)'];

  private backgroundColor = ['#9daf77', '#c5ce9033'];
  private borderColor = ['#77913f', '#c5ce90'];

  public displayedColumns: string[] = ['year', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', 'sum'];
  public displayedColumns2: string[] = ['name', '10-2', '11-2', '12-2', '01-1', '02-1', '03-1', '04-1', '05-1', '06-1', '07-1', '08-1', '09-1', '10-1', '11-1', '12-1', '01-0', '02-0', '03-0', '04-0', '05-0', '06-0', '07-0', 'sum'];
  public dataSource: any[] = [];
  public bookSource: any[] = [];
  public voucherUsageData: ChartData = {datasets: []};
  public bookUsageData: ChartData = {datasets: []};
  public yearlyVoucherUsageData: ChartData = {
    datasets: [
      {
        data: [],
        fill: false,
        backgroundColor: this.backgroundColor,
        borderColor: this.borderColor,
        borderWidth: 1,
      },
    ],
  };
  public monthlyVoucherUsageData: ChartData = {
    datasets: [
      {
        data: [],
        fill: false,
        backgroundColor: this.backgroundColor,
        borderColor: this.borderColor,
        borderWidth: 1,
      },
    ],
  };
  public averageVoucherUsageData: ChartData = {
    datasets: [
      {
        data: [],
        fill: false,
        backgroundColor: this.backgroundColor,
        borderColor: this.borderColor,
        borderWidth: 1,
      },
    ],
  };
  public options: ChartOptions = {responsive: true, plugins: {legend: {display: false}}};
  public horizontalOptions: ChartOptions = {...this.options, indexAxis: 'y'};
  public lineOptions: ChartOptions = {...this.options, plugins: {legend: {display: true}}};
  public currentMonth = moment().format('MM');
  public currentMonthYear = this.currentMonth + '-1';

  constructor(public appService: AppService, public translate: TranslateService, public tokenStorage: TokenStorageService, public renderer: Renderer2, public router: Router, public dialog: MatDialog, public route: ActivatedRoute, public snackBar: MatSnackBar) {
    super(tokenStorage, appService, renderer, dialog, snackBar, router);
    this.init();
  }

  ngOnInit(): void {
    this.dataLoaded.subscribe(() => {
      this.loadChartData(this.golfClub);
    });
  }

  private loadChartData(golfClub: GolfClub): void {
    this.appService.isLoading.next(true);
    const param = new HttpParams().set('club_id', this.appService.getIdFromUrlString(golfClub['@id']));
    const subscriptions = [
      this.appService.list('custom/yearly_voucher_usage', param),
      this.appService.list('custom/monthly_voucher_usage', param),
      this.appService.list('book_golf_clubs', new HttpParams().set('golfClub', this.golfClub['@id']).set('book.status', 'A').set('status', 'A')),
      this.appService.list('custom/book_voucher_usage', param),
      this.appService.list('custom/book_usage_detailed', param),
    ];
    combineLatest(subscriptions).subscribe(
      (result: APIPlatformPagedCollection[]) => {

        this.dataSource = result[3]['hydra:member'];
        this.bookSource = result[4]['hydra:member'];

        // yearly_voucher_usage
        result[0]['hydra:member'].map((query: { year: string, used_voucher: number }) => {
          this.yearlyVoucherUsageData.labels?.push(query.year);
          this.yearlyVoucherUsageData.datasets[0].data.push(query.used_voucher);
        });
        this.yearlyVoucherUsageData.datasets[0].label = this.translate.instant('devaluations');
        this.ref0.chartInstance.update();

        // monthly_voucher_usage
        result[1]['hydra:member'].map((query: { month: string, used_voucher: number }) => {
          this.monthlyVoucherUsageData.labels = this.monthRage;
          this.monthlyVoucherUsageData.datasets[0].data.push(query.used_voucher);
        });
        this.monthlyVoucherUsageData.datasets[0].label = this.translate.instant('devaluations');
        this.ref1.chartInstance.update();

        // average_voucher_usage
        // result[1]['hydra:member'].sort((a: { month: string, used_voucher: number }, b: { month: string, used_voucher: number }) => (a.used_voucher > b.used_voucher) ? 1 : -1).map((query: { month: string, used_voucher: number }) => {
        //   this.averageVoucherUsageData.labels?.push(moment().month(query.month).format('MMM'));
        //   this.averageVoucherUsageData.datasets[0].data.push(+(query.used_voucher / result[2]['hydra:totalItems']).toFixed(1));
        // });
        // this.averageVoucherUsageData.datasets[0].label = this.translate.instant('devaluations');
        // this.ref2.chartInstance.update();

        // voucher_usage
        result[3]['hydra:member'].slice(0, 4).map((query: any, index: number) => {
          this.voucherUsageData.datasets.push({
            label: this.translate.instant('devaluations') + ' ' + query.year,
            data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((i: string) => query[i] || 0),
            borderColor: this.borderColor[index],
            backgroundColor: this.borderColor[index],
          });
          this.voucherUsageData.labels = this.monthRage;
        });
        this.ref3.chartInstance.update();

        // book_usage
        result[4]['hydra:member'].slice(0, 4).map((query: any, index: number) => {
          this.bookUsageData.datasets.push({
            label: this.translate.instant('devaluations') + ' ' + query.name,
            data: ['10-2', '11-2', '12-2', '01-1', '02-1', '03-1', '04-1', '05-1', '06-1', '07-1', '08-1', '09-1', '10-1', '11-1', '12-1', '01-0', '02-0', '03-0', '04-0', '05-0', '06-0', '07-0'].map((i: string) => query[i] || 0),
            borderColor: this.borderColor[index],
            backgroundColor: this.borderColor[index],
          });
          this.bookUsageData.labels = ['10-2', '11-2', '12-2', '01-1', '02-1', '03-1', '04-1', '05-1', '06-1', '07-1', '08-1', '09-1', '10-1', '11-1', '12-1', '01-0', '02-0', '03-0', '04-0', '05-0', '06-0', '07-0'].map(d => this.getMonth(d));
        });
        this.ref4.chartInstance.update();
      },
      () => null,
      () => this.appService.isLoading.next(false)
    );
  }

  public getMonth(value: string): string {
    value = value.split('-')[0];
    return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].includes(value) ? moment().month(+value - 1).format('MMM') : value;
  }

  public getBookName(value: string): string {
    const splitName = value.split(' ');
    return splitName[splitName.length - 1];
  }

  public getTotal(source: any[], column: string): number {
    return source.map(t => t[column]).reduce((acc, value) => acc + value, 0);
  }

  public openBookDetail(index: number, month: string): void {
    const book = this.bookSource[index];
    this.router.navigate([{
      outlets: {
        detail: [
          moment(book['current year']).subtract(month.split('-')[1], 'year').year(),
          month.split('-')[0],
          book.id].join('/')
      }
    }]).then();
  }

  public openDetail(year: number, month: number): void {
    this.router.navigate([{outlets: {detail: [year, month].join('/')}}]).then();
  }

}
