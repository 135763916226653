<div fxLayout="row wrap" fxLayoutAlign="space-between stretch" class="inner">

  <div class="container mat-elevation-z0 table-container">
    <h3>{{ 'book_voucher_usage' | translate }}</h3>
    <ngx-chartjs #ref3 type="line" [data]="voucherUsageData" [options]="lineOptions"></ngx-chartjs>

    <table mat-table [dataSource]="dataSource" class="table tiled" matSort>

      <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column" [ngSwitch]="column" [sticky]="column === currentMonth">
        <th mat-header-cell *matHeaderCellDef>{{ getMonth(column) | translate | uppercase }}</th>

        <ng-container *ngSwitchCase="'year'">
          <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
          <td mat-footer-cell *matFooterCellDef> {{ 'total' | translate }} </td>
        </ng-container>

        <ng-container *ngSwitchCase="'sum'">
          <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
          <td mat-footer-cell *matFooterCellDef>{{ getTotal(bookSource, column) }}</td>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <td mat-cell *matCellDef="let row" (click)="row[column] > 0 ? openDetail(row['year'], +column) : null">{{ row[column] }}</td>
          <td mat-footer-cell *matFooterCellDef>{{ getTotal(dataSource, column) }}</td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
    <mat-hint>{{ 'The data is displayed across all guides.' | translate }}</mat-hint>
  </div>

  <div class="container mat-elevation-z0 table-container">
    <h3>{{ 'book_usage' | translate }}</h3>
    <ngx-chartjs #ref4 type="line" [data]="bookUsageData" [options]="lineOptions"></ngx-chartjs>

    <table mat-table [dataSource]="bookSource" class="table tiled" matSort>

      <ng-container *ngFor="let column of displayedColumns2" [matColumnDef]="column" [ngSwitch]="column" [sticky]="column === currentMonthYear">
        <th mat-header-cell *matHeaderCellDef>{{ (column === 'name' ? 'book' : getMonth(column)) | translate | uppercase }}</th>

        <ng-container *ngSwitchCase="'name'">
          <td mat-cell dir="rtl" *matCellDef="let row" [matTooltip]="row[column]">{{ getBookName(row[column]) }}</td>
          <td mat-footer-cell *matFooterCellDef> {{ 'total' | translate }} </td>
        </ng-container>

        <ng-container *ngSwitchCase="'sum'">
          <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
          <td mat-footer-cell *matFooterCellDef>{{ getTotal(bookSource, column) }}</td>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <td mat-cell *matCellDef="let row; index as i" (click)="row[column] > 0 ? openBookDetail(i, column) : null">{{ row[column] }}</td>
          <td mat-footer-cell *matFooterCellDef>{{ getTotal(bookSource, column) }}</td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns2"></tr>
    </table>

    <mat-hint>{{ 'The data is displayed across the year.' | translate }}</mat-hint>
  </div>

  <div class="container mat-elevation-z0 chart-container">
    <h3>{{ 'monthly_voucher_usage' | translate }}</h3>
    <ngx-chartjs #ref1 type="bar" [data]="monthlyVoucherUsageData" [options]="options"></ngx-chartjs>
  </div>

  <!--  <div class="container mat-elevation-z0 chart-container">-->
  <!--    <h3>{{ 'average_voucher_usage' | translate }}</h3>-->
  <!--    <ngx-chartjs #ref2 type="bar" [data]="averageVoucherUsageData" [options]="horizontalOptions"></ngx-chartjs>-->
  <!--  </div>-->

  <div class="container mat-elevation-z0 chart-container">
    <h3>{{ 'yearly_voucher_usage' | translate }}</h3>
    <ngx-chartjs #ref0 type="bar" [data]="yearlyVoucherUsageData" [options]="options"></ngx-chartjs>
  </div>

</div>
