<ng-template #modal>
  <button mat-flat-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>

  <button *ngIf="currentComponent?.print" mat-flat-button class="action-button" (click)="printPage()">
    <mat-icon>print</mat-icon>
  </button>

  <button *ngIf="currentComponent?.print" mat-flat-button class="action-button" (click)="openDialog()">
    <mat-icon>mail</mat-icon>
  </button>

  <h2 mat-dialog-title>{{ (currentComponent?.action || '') | translate }}</h2>
  <ng-template [ngComponentOutlet]="currentComponent?.component"></ng-template>
</ng-template>

<ng-template #mailModal let-data>
  <h1 mat-dialog-title>{{ 'Send order confirmation' | translate }}: {{ data.delivery_first_name }} {{ data.delivery_last_name }}</h1>
  <mat-dialog-content>
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon>numbers</mat-icon>
        {{ 'order_nr' | translate }}:  {{ data.billing_country_code }}-{{ data.id }} </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>event</mat-icon>
        {{ 'order_date' | translate }}:  {{ data.createdAt| date:"dd.MM.YYYY" }}</mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>paid</mat-icon>
        {{ 'payment_status' | translate }}:  {{ (data.payment_status || 'not-finished') | translate }} </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>mail</mat-icon>
        {{ 'email' | translate }}:  {{ data.delivery_email }} </mat-list-item>
    </mat-list>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase  }}</span>
      </span>
    </button>

    <span class="spacer"></span>

    <button mat-dialog-close mat-stroked-button color="primary" type="submit" [disabled]="data.payment_status !== 'paid'" (click)="sendMail()">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'send' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</ng-template>
