import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { finalize, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenStorageService } from './token-storage.service';
import * as moment from 'moment';
import { Moment, unitOfTime } from 'moment';
import { environment } from '../../../environments/environment';

export interface HydraMember {
  '@context': string;
  '@id': string;
  '@type': string;
}

export interface Order extends HydraMember {
  identifier: string;
  payment_kind: string;
  delivery_salutation: string;
  delivery_first_name: string;
  delivery_last_name: string;
  delivery_street: string;
  delivery_postal_code: string;
  delivery_city: string;
  delivery_country_code: string;
  delivery_email: string;
  delivery_phone: string;
  billing_salutation: string;
  billing_first_name: string;
  billing_last_name: string;
  billing_street: string;
  billing_postal_code: string;
  billing_city: string;
  billing_country_code: string;
  billing_email: string;
  billing_phone: string;
  payment_status: string;
  paid_at: Date;
  paid_status_changed_at: Date;
  guide_own_usage: string;
  updated_at: string;
  billing_address_equals: boolean;
  price_items: number;
  delivery_price: number;
  price: number;
  id: number;
  createdAt: Date;
  lastModified: Date;
  orderItem: OrderItem[];
}

export interface OrderItem extends HydraMember {
  Order: string;
  product_id: string;
  description: string;
  quantity: number;
  price_per_unit: number;
  price: number;
  pos: number;
  updated_at: Date;
  id: number;
  createdAt: Date;
  lastModified: Date;
}

export interface NewsText extends HydraMember {
  changed_by: number;
  changed_tstamp: Date;
  createdAt: Date;
  created_by: number;
  created_tstamp: Date;
  expiry_tstamp: Date;
  id: number;
  lastModified: Date;
  release_tstamp: Date;
  status: string;
  title: string;
  txt: string;
}

export interface GolfClub extends HydraMember {
  club_nr: string;
  vouchers_cnt: number;
  login: string;
  name: string;
  street: string;
  city: string;
  postal_code: string;
  pwd: string;
  country_id: number;
  state_id: number;
  phone: string;
  fax: string;
  email: string;
  website: string;
  holes: string;
  conditions_txt: string;
  desc_txt: string;
  pic: string;
  status: string;
  salutation_id: number;
  title: string;
  first_name: string;
  last_name: string;
  person_email: string;
  entry_id: string;
  picture: string;
  fields: string[];
  region_id: number;
  single_player: boolean;
  bookGolfClub: BookGolfClub[];
  id?: number;
  has_guide?: boolean;
}

export interface Guide extends HydraMember {
  oldId: number;
  book: string;
  serial_nr: number;
  salutation_id: number;
  first_name: string;
  last_name: string;
  email: string;
  street: string;
  postal_code: string;
  city: string;
  country_id: number;
  created_tstamp: Date;
  changed_tstamp: Date;
  created_by: string;
  changed_by: string;
  status: string;
  activation_code: string;
  is_activated: boolean;
  activated_tstamp: Date;
  used_vouchers_cnt: number;
  voucher_usage?: number;
  id: number;
  createdAt: Date;
  lastModified: Date;
}

export interface BookGolfClub extends HydraMember {
  book: Book;
  golfClub: GolfClub;
  vouchersCnt: number;
  usedVouchersCnt?: string;
  remainingVouchersCnt?: string;
  status: string;
  singlePlayer: string;
  name?: string;
  postal_code?: string;
  city?: string;
  country_id?: number;
  id: number;
}

export interface ClubResource {
  vouchers_cnt: number;
  single_player: boolean;
}

export interface GolfClubData {
  id?: number;
  endpoint?: string;
  resource: BookGolfClubResource | ClubResource;
}

export interface BookGolfClubResource {
  book?: string;
  golfClub?: string;
  vouchersCnt: string;
  status: string;
  singlePlayer: boolean;
}

export interface Book extends HydraMember {
  name: string;
  active_from: Date | string;
  active_to: Date | string;
  serial_nr_from: number;
  serial_nr_to: number;
  pic: string;
  status: string;
  vouchers_cnt: number;
  entry_id: string;
  cover_image: string;
  fields: string[];
  bookGolfClub: string[];
  id: number;
  createdAt: Date;
  lastModified: Date;
  single_player?: boolean;
  endpoint?: string;
}

export interface VoucherUsage extends HydraMember {
  bookGolfClub: string;
  changed_by_club: string;
  changed_by_tstamp: Date;
  created_by_club: string;
  created_by_club_browser: string;
  created_by_club_ip: string;
  created_by_tstamp: Date;
  guide: Guide;
  oldId: number;
  single_player: boolean;
  status: string;
  used_quantity: number;
  used_tstamp: Date;
}

export interface Country extends HydraMember {
  code: string;
  createdAt: string;
  entry_id: string;
  fields: {
    country_code: string;
    name_de: string;
  };
  id: number;
  lastModified: string;
  name: string;
  priority: number;
  status: string;
}

export interface APIPlatformPagedCollection {
  '@context'?: string;
  '@id': string;
  '@type'?: string;
  'hydra:member': [];
  'hydra:search'?: {
    '@type'?: string;
    'hydra:mapping'?: [{
      '@type'?: string;
      'property'?: string;
      'required'?: boolean;
      'variable'?: string;
    }];
    'hydra:template'?: string;
    'hydra:variableRepresentation'?: string;
  };
  'hydra:totalItems': number;
  'hydra:view'?: {
    '@id'?: string;
    '@type'?: string;
    'hydra:first'?: string;
    'hydra:last'?: string;
    'hydra:previous'?: string;
    'hydra:next'?: string;
  };
}

export interface ChildItem {
  name: string;
  icon: string;
  disabled?: boolean;
  route: string;
  endpoint?: string;
}

export interface Condition {
  key: string;
  value: string | number | boolean;
}

export interface Action {
  column: string;
  text: string;
  class?: string;
  icon?: string;
  event: (route: string) => any;
  condition?: Condition[];
}

export interface Configuration {
  name: string;
  edit?: boolean;
  icon: string;
  route: string;
  disabled?: boolean;
  filter?: boolean;
  columns?: string[];
  permissions?: string[];
  format?: {
    toDate?: string[],
    toBoolean?: string[]
  };
  actions?: Action[];
  children?: ChildItem[];
}

export interface AppUser extends HydraMember {
  golfclub: GolfClub;
  id: number;
  securityRoles: string;
  token: string;
  active?: boolean;
  email?: string;
  roles?: string[];
  username?: string;
}

@Injectable({
  providedIn: 'root'
})

export class AppService {

  public refresh: EventEmitter<any> = new EventEmitter();
  public isLoading = new BehaviorSubject<boolean>(false);
  public isBuffering = new BehaviorSubject<boolean>(false);
  public loggedIn = new BehaviorSubject<boolean>(false);

  public filterDelay = 1000;

  readonly url = environment.url;

  private appConfiguration: Configuration[] = [
    {name: 'validate_voucher', route: 'validate', icon: 'verified', permissions: ['ROLE_USER']},
    {name: 'earlier_devaluations', route: 'devaluation', icon: 'task_alt', permissions: ['ROLE_USER']},
    {name: 'my_statistics', route: 'statistics', icon: 'bar_chart', permissions: ['ROLE_USER']},
    {
      name: 'guides',
      edit: true,
      icon: 'auto_stories',
      route: 'custom/guide_list',
      columns: ['id', 'name', 'active_from', 'active_to', 'serial_nr_from', 'serial_nr_to', 'associated_clubs', 'associated_guides', 'status'],
      actions: [
        {column: 'assume_clubs', text: 'assume', icon: 'fact_check', class: 'mat-primary', event: (route: string) => this.openAction(route, 'assume_clubs'), condition: [{key: 'associated_clubs', value: 0}]},
        {column: 'assign_clubs', text: 'assign', icon: 'ballot', class: 'mat-primary', event: (route: string) => this.openAction(route, 'assign_clubs')}
      ],
      format: {
        toDate: ['active_from', 'active_to']
      }
    },
    {
      name: 'club',
      edit: true,
      filter: true,
      icon: 'golf_course',
      route: 'golfclubs',
      children: [{name: 'new_club', route: 'add', endpoint: 'new_club', icon: 'add_business'}],
      // actions: [
      //   {column: 'send_login', text: 'send login data', icon: 'contact_mail', class: 'mat-primary', event: (route: string) => this.openAction(route, 'send_login'), condition: [{key: 'status', value: 'A'}]},
      // ],
      columns: ['id', 'name', 'club_nr', 'postal_code', 'city', 'country_id', 'status']
    },
    {
      name: 'user',
      edit: true,
      filter: true,
      icon: 'people',
      route: 'guides',
      children: [{name: 'new_guide', route: 'add', endpoint: 'new_guide', icon: 'person_add'}],
      columns: ['id', 'serial_nr', 'first_name', 'last_name', 'email', 'country_id', 'is_activated', 'status', 'book', 'used_vouchers_cnt'],
      format: {
        toBoolean: ['is_activated']
      },
      actions: [
        {column: 'show_voucher', text: 'voucher', icon: 'task', class: 'mat-primary', event: (route: string) => this.openAction(route, 'show_voucher'), condition: [{key: 'used_vouchers_cnt', value: 1}]},
      ]
    },
    {
      name: 'dashboard', icon: 'bar_chart', route: 'dashboard', columns: ['bookGolfClub', 'status', 'used_quantity'],
      children: [
        {name: 'last_voucher_usage', route: 'show', endpoint: 'last_voucher_usage', icon: 'history'},
        {name: 'monthly_voucher_usage', route: 'show', endpoint: 'voucher_usage', icon: 'bar_chart'},
        {name: 'yearly_voucher_usage', route: 'show', endpoint: 'yearly_voucher_usage', icon: 'signal_cellular_alt'}
      ],
    },
    {
      name: 'orders', icon: 'shopping_cart', route: 'orders', columns: ['id', 'paid_at', 'delivery_first_name', 'delivery_last_name', 'delivery_street', 'delivery_postal_code', 'delivery_city', 'delivery_country_code', 'payment_status', 'price', 'guide_own_usage'],
      filter: true,
      edit: true,
      format: {
        toDate: ['paid_at', 'paid_status_changed_at']
      },
      actions: [
        {column: 'import_order', text: 'import order', icon: 'cloud_upload', class: 'mat-primary', event: (route: string) => this.openAction(route, 'import_order'), condition: [{key: 'payment_status', value: 'paid'}]},
      ]
    },
    {
      name: 'news', icon: 'rss_feed', route: 'newstexts', columns: ['id', 'title', 'release_tstamp', 'expiry_tstamp', 'status'],
      children: [{name: 'add_news', route: 'add', endpoint: 'add_newstexts', icon: 'playlist_add'}],
      edit: true,
      actions: [
        {column: 'delete_news', text: 'delete', icon: 'delete', class: 'mat-warn', event: (route: string) => this.openAction(route, 'delete_news')}
      ],
      format: {
        toDate: ['release_tstamp', 'expiry_tstamp']
      }
    },
  ];

  constructor(private httpClient: HttpClient, private router: Router, private snackBar: MatSnackBar, private tokenStorage: TokenStorageService) {
  }

  public openAction(route: string, component: any): void {
    this.router.navigate([{outlets: {edit: `${route.substr(1)}/${component}`}}]).then();
  }

  public isAdmin(): boolean {
    return (this.tokenStorage.getUser() as AppUser).securityRoles.includes('ROLE_ADMIN');
  }

  public getConfigurations(): Configuration[] {
    return this.appConfiguration;
  }

  public getCurrentConfiguration(route: string): Configuration | undefined {
    return this.appConfiguration.find((configuration: Configuration) => configuration.route === route);
  }

  public list(endpoint: string, params: HttpParams = new HttpParams()): Observable<APIPlatformPagedCollection> {
    return this.httpClient.get<APIPlatformPagedCollection>(`${this.url}/${endpoint}`, {params}).pipe(
      map((response: any) => typeof response === 'object' ? response : {'hydra:member': JSON.parse(response), 'hydra:totalItems': JSON.parse(response).length})
    );
  }

  public getOne(endpoint: string, params: HttpParams): Observable<any> {
    return this.list(endpoint, params).pipe(map((result: APIPlatformPagedCollection) => {
      const retVal: any[] = result['hydra:member'];
      return retVal.length ? retVal[0] : undefined;
    }));
  }

  public get(endpoint: string, id?: string): Observable<any> {
    return this.httpClient.get<any>([this.url, endpoint, id].join('/').replace(/\/$/, ''));
  }

  public patch(endpoint: string, id: string | undefined, body: any, refresh: boolean = true): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/merge-patch+json');
    return this.httpClient.patch<any>([this.url, endpoint, id].join('/'), body, {headers}).pipe(finalize(() => refresh ? this.refresh.emit() : null));
  }

  public delete(endpoint: string, id: string, refresh: boolean = true): Observable<any> {
    return this.httpClient.delete<any>([this.url, endpoint, id].join('/')).pipe(finalize(() => refresh ? this.refresh.emit() : null));
  }

  public post(endpoint: string, body: any, refresh: boolean = true): Observable<any> {
    return this.httpClient.post<any>([this.url, endpoint].join('/'), body).pipe(finalize(() => refresh ? this.refresh.emit() : null));
  }

  public login(username: string, password: string): Observable<any> {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.httpClient.post(this.url + '/authentication_token', {username, password}, httpOptions);
  }

  public getCountries(): Observable<APIPlatformPagedCollection> {
    const params = new HttpParams().set('page', '1').set('order[id]', 'asc').set('itemsPerPage', '100').set('status', 'A');
    return this.list('countries', params);
  }

  public getBooks(activeOnly: boolean = false): Observable<APIPlatformPagedCollection> {
    const params = activeOnly ? new HttpParams().set('page', '1').set('order[id]', 'desc').set('itemsPerPage', '100').set('status', 'A') : new HttpParams().set('page', '1').set('order[id]', 'desc').set('itemsPerPage', '100');
    return this.list('books', params);
  }

  public openSnackBar(message: string): void {
    this.snackBar.open(message, 'OK', {duration: 5000});
  }

  public getIdFromUrlString(urlStr: string): string {
    return urlStr?.substring(urlStr.lastIndexOf('/') + 1);
  }

  public getRangeOfDates(start: Moment, end: Moment, key: unitOfTime.Base, arr: Moment[] = [start.startOf(key)]): Moment[] {
    if (start.isAfter(end)) {
      throw new Error('start must precede end');
    }
    const next = moment(start).add(1, key).startOf(key);
    if (next.isAfter(end, key)) {
      return arr;
    }
    return this.getRangeOfDates(next, end, key, arr.concat(next));
  }

  public sendVoucherEmail(guideId: string): Observable<any> {
    return this.httpClient.get<any>([this.url, 'email', 'voucher'].join('/'), {params: {guide_id: guideId}});
  }

  public getRandomNumberBetween(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public updateUsedVouchersCnt(guideId: string): void {
    this.list('voucher_usages', new HttpParams().set('guide', guideId).set('status', 'A').set('itemsPerPage', '1000')).subscribe({
      next: (result: APIPlatformPagedCollection) => {
        const usedVouchersCnt = (result['hydra:member'] as VoucherUsage[]).reduce((prev: number, cur: VoucherUsage) => prev + cur.used_quantity, 0);
        this.patch('guides', guideId, {used_vouchers_cnt: usedVouchersCnt}).subscribe();
      },
    });
  }
}
