import { ChangeDetectorRef, Component, EventEmitter, Inject, LOCALE_ID, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { APIPlatformPagedCollection, AppService, Book, GolfClub, Guide } from '../../../../shared/services/app.service';
import { HttpParams } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.scss']
})
export class VoucherDetailComponent implements OnInit, OnDestroy {

  @ViewChild('voucherModal', {static: true}) voucherModal: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: string[] = ['used_tstamp', 'book', 'serial_nr', 'full_name', 'email', 'single_player', 'used_quantity'];
  public dataSource: MatTableDataSource<APIPlatformPagedCollection>;

  public filterChanged: EventEmitter<string> = new EventEmitter();
  public bookChanged: EventEmitter<string> = new EventEmitter();
  public dateChanged: EventEmitter<{ start: string, end: string }> = new EventEmitter();

  private dataSubscription: Subscription;
  private golfClubID: string[];

  public golfClub: GolfClub;
  public booksList: Book[] = [];
  public filter: string;
  public startDate: Date | null;
  public endDate: Date | null;
  public bookId: string;

  public defaultPageSizeOptions = [10, 15, 30, 50, 100];
  public defaultPageSize: number;
  public resultsLength: number | undefined;

  constructor(private route: ActivatedRoute, @Inject(LOCALE_ID) private locale: string, private changeDetector: ChangeDetectorRef, public appService: AppService, private translate: TranslateService, public dialog: MatDialog) {
    this.appService.getBooks().subscribe((result: APIPlatformPagedCollection) => this.booksList = result['hydra:member'] as Book[]);
    this.filterChanged.subscribe((text: string) => this.filter = text);
    this.bookChanged.subscribe((id: string) => this.bookId = id);
    this.appService.refresh.subscribe(() => this.initTable());
  }

  ngOnInit(): void {

    this.route.params.subscribe(
      (params: Params) => {
        if (this.dataSubscription) {
          this.dataSubscription.unsubscribe();
        }

        this.golfClubID = params.uuid;
        this.defaultPageSize = Number(localStorage.getItem('pageSize')) || this.defaultPageSizeOptions[0];
        this.filter = '';
        this.bookId = '-';
        this.changeDetector.detectChanges();
        this.appService.get('golfclubs', params.uuid).subscribe(
          (golfclub: GolfClub) => this.golfClub = golfclub,
          () => console.log('error'),
          () => this.initTable()
        );
      });
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  private initTable(): void {
    this.paginator.firstPage();
    this.paginator.pageSize = this.defaultPageSize;
    this.sort.active = this.displayedColumns[0];
    this.sort.direction = 'desc' as SortDirection;

    this.dataSubscription = merge(this.sort.sortChange, this.paginator.page, this.dateChanged.pipe(map(() => this.resetPaging())), this.bookChanged.pipe(map(() => this.resetPaging())), this.filterChanged.pipe(debounceTime(this.appService.filterDelay), map(() => this.resetPaging())))
      .pipe(
        startWith({}),
        switchMap(() => {
          localStorage.setItem('pageSize', String(this.paginator.pageSize));
          this.appService.isLoading.next(true);
          return this.appService.list('voucher_usages', this.getHttpParams()).pipe(
            map((result: APIPlatformPagedCollection) => {
              result['hydra:member'].map((data: any) => {
                const guide: Guide = data.guide;
                data.first_name = guide.first_name;
                data.last_name = guide.last_name;
                data.full_name = (data.first_name || '') + ' ' + (data.last_name || '');
                data.email = guide.email;
                data.serial_nr = guide.serial_nr;
                data.book = this.booksList.find((book: Book) => book['@id'] === guide.book)?.name;
              });
              return result;
            })
          );
        }),
        map((data: APIPlatformPagedCollection) => {
          this.appService.isLoading.next(false);
          this.resultsLength = data['hydra:totalItems'];
          return data;
        }),
        catchError(() => {
          this.appService.isLoading.next(false);
          return observableOf([]);
        })
      ).subscribe((data: any) => this.dataSource = new MatTableDataSource(data['hydra:member']));
  }

  private isNumeric(str: any): boolean {
    if (typeof str !== 'string') {
      return false;
    }
    return !isNaN(Number(str)) && !isNaN(parseFloat(str));
  }

  private getHttpParams(): HttpParams {
    const paramsObj = {
      'bookGolfClub.golfClub.name': this.golfClub.name,
      page: String(this.paginator.pageIndex + 1),
      ['order[' + this.sort.active + ']']: this.sort.direction,
      itemsPerPage: String(this.paginator.pageSize)
    };

    if (['first_name', 'last_name', 'serial_nr', 'email'].includes(this.sort.active)) {
      paramsObj['order[guide.' + this.sort.active + ']'] = this.sort.direction;
    }
    if (this.filter) {
      if (this.isNumeric(this.filter)) {
        paramsObj['guide.serial_nr'] = this.filter;
      } else {
        paramsObj['guide.email'] = this.filter;
      }
    }
    if (this.bookId !== '-') {
      paramsObj['guide.book'] = this.bookId;
    }
    if (this.startDate) {
      paramsObj['used_tstamp[after]'] = this.startDate.toISOString();
    }
    if (this.endDate) {
      paramsObj['used_tstamp[before]'] = this.endDate.toISOString();
    }

    return new HttpParams({fromObject: paramsObj});
  }

  /** reset page index */
  public resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  /** handle table filter */
  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterChanged.emit(filterValue);
  }

  /** format row cell */
  public formatRow(column: string | any, row: string[]): string {

    // format date columns
    if (['used_tstamp'].includes(column) && row[column]) {
      return formatDate(row[column], 'dd.MM.yyyy HH:mm', this.locale);
    }

    // format boolean columns
    if (['single_player'].includes(column)) {
      return `<span class="material-icons primary">${!!row[column] ? 'check_box' : 'check_box_outline_blank'}</span>`;
    }
    return row[column];
  }

  public clearFilter(): void {
    this.filter = '';
    this.bookId = '-';
    this.startDate = null;
    this.endDate = null;
    this.filterChanged.emit('');
  }

  public getVoucherArray(cnt: number): number[] {
    return Array.from({length: cnt}, (value, key) => key + 1);
  }

  public openDialog(dialog: TemplateRef<any>, data: any = {}): void {
    this.dialog.open(dialog, {disableClose: true, width: '50vw', data, autoFocus: false});
  }

  public updateVoucher(id: string, cnt: number, singlePlayer: boolean, data: any): void {
    const voucherID = this.appService.getIdFromUrlString(id);
    const guideID = this.appService.getIdFromUrlString(data?.guide['@id']);
    (cnt === 0 ? this.appService.delete('voucher_usages', voucherID) : this.appService.patch('voucher_usages', voucherID, {used_quantity: cnt, single_player: singlePlayer})).subscribe({complete: () => guideID && this.appService.updateUsedVouchersCnt(guideID)});
  }

}
