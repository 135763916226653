import { Component, OnInit } from '@angular/core';
import { NewsComponent } from '../news.component';

@Component({
  selector: 'app-add-news',
  templateUrl: '../news.component.html',
  styleUrls: ['../news.component.scss']
})
export class AddNewsComponent extends NewsComponent implements OnInit {

  private timeStamp = new Date().toISOString();
  private newsText = {
    title: null,
    txt: null,
    release_tstamp: this.timeStamp,
    expiry_tstamp: this.timeStamp,
    status: null,
    created_tstamp: this.timeStamp,
    changed_tstamp: this.timeStamp,
    created_by: null,
    changed_by: null,
    createdAt: this.timeStamp,
    lastModified: this.timeStamp,
  };

  ngOnInit(): void {

  }

  public onSubmit(): void {
    if (this.form.valid && this.form.touched) {
      this.appService.isLoading.next(true);
      const news = {...this.newsText, ...this.form.getRawValue()};
      this.appService.post('newstexts', news).subscribe(
        () => this.appService.openSnackBar('your data has been created successfully'),
        () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
        () => {
          this.form.markAsUntouched();
          this.form.markAsPristine();
          this.appService.isLoading.next(false);
        }
      );
    }
  }
}
