<form [formGroup]="form" (submit)="onSubmit()" autocomplete="off">

  <mat-dialog-content class="mat-typography" fxLayout="column">

    <mat-form-field>
      <mat-label>{{ 'title' | translate }}</mat-label>
      <input type="text" matInput formControlName="title" required>
    </mat-form-field>

    <angular-editor formControlName="txt" [config]="editorConfig"></angular-editor>

    <mat-form-field>
      <mat-label>{{ 'date_range' | translate }}</mat-label>
      <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
        <input matStartDate formControlName="release_tstamp" [placeholder]="'release_tstamp' | translate">
        <input matEndDate formControlName="expiry_tstamp" [placeholder]="'expiry_tstamp' | translate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'status' | translate }}</mat-label>
      <mat-select formControlName="status" required>
        <mat-option *ngFor="let status of ['A', 'D', 'S']" [value]="status">{{ status | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn">
       <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
       </span>
    </button>

    <span class="spacer"></span>

    <button mat-stroked-button color="accent" type="submit" [disabled]="form.invalid || !form.dirty || !form.touched">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">save_alt</mat-icon>
      </span>
    </button>

    <button mat-dialog-close mat-stroked-button color="primary" type="submit" [disabled]="form.invalid || !form.dirty || !form.touched">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save and close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</form>
