import { Component, OnInit } from '@angular/core';
import { GolfClubsComponent } from '../golfclubs.component';
import { HttpParams } from '@angular/common/http';
import { APIPlatformPagedCollection, Country, GolfClub } from '../../../../../shared/services/app.service';
import { combineLatest, Observable } from 'rxjs';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-add-golfclub',
  templateUrl: '../golfclubs.component.html',
  styleUrls: ['../golfclubs.component.scss']
})
export class AddGolfclubComponent extends GolfClubsComponent implements OnInit {

  public editMode = false;

  ngOnInit(): void {
    const params: HttpParams = new HttpParams().set('order[club_nr]', 'desc');
    const subscriptions: Observable<any>[] = [this.appService.getOne('golfclubs', params), this.appService.getCountries()];
    combineLatest(subscriptions).subscribe({
      next: (result: APIPlatformPagedCollection[]) => {
        const golfclub = result[0] as unknown as GolfClub;
        this.countries = result[1]['hydra:member'] as Country[];
        const clubNumber: string = String((+golfclub.club_nr || golfclub.id || +this.appService.getIdFromUrlString(golfclub['@id'])) + 1);

        this.form.get('login')?.setValidators(Validators.required);
        this.form.get('vouchers_cnt')?.patchValue(2);
        this.form.get('user.securityRoles')?.patchValue('ROLE_USER');
        this.form.get('country_id')?.patchValue(7);
        this.form.get('single_player')?.patchValue(false);
        this.form.get('status')?.patchValue('A');

        this.form.get('login')?.patchValue(clubNumber);
        this.form.get('club_nr')?.patchValue(clubNumber);

        this.observeLogin(this.form.get('login')?.value);

        this.form.get('login')?.valueChanges.subscribe({next: (value: any) => this.observeLogin(value)});
        this.form.get('user.email')?.valueChanges.subscribe({next: (value: any) => this.observeEmail(value)});

        this.form.markAllAsTouched();
      }
    });
  }

  public onSubmit(resetPassword: boolean = false): void {
    if (this.form.valid && this.form.touched) {

      this.appService.isLoading.next(true);
      const defaultClub = {
        street: '',
        city: '',
        postal_code: '',
        state_id: 0,
        phone: '',
        fax: '',
        email: '',
        website: '',
        holes: '',
        conditions_txt: '',
        desc_txt: '',
        pic: '',
        status: '',
        salutation_id: 0,
        title: '',
        first_name: '',
        last_name: '',
        person_email: '',
        entry_id: '',
        picture: '',
        fields: [],
        region_id: 0,
        single_player: true
      };
      const golfClub: GolfClub = {...defaultClub, ...this.form.getRawValue()} as GolfClub;
      golfClub.pwd = '*************'; // hide real password

      this.appService.post('golfclubs', golfClub).subscribe({
        next: (club: GolfClub) => {
          this.appService.post('users', {active: club.status === 'A', isActive: club.status === 'A', email: club.email, username: club.login, securityRoles: this.form.get('user.securityRoles')?.value, password: this.form.get('pwd')?.value, golfclub: club['@id']}).subscribe({
            next: () => this.router.navigate([{outlets: {edit: club['@id'].substr(1) + '/edit_golfclub'}}]).then(),
            complete: () => this.appService.isLoading.next(false)
          });
        }
      });
    }
  }

}
