import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, RoutesRecognized } from '@angular/router';
import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { APIPlatformPagedCollection, AppService, Configuration, NewsText } from './shared/services/app.service';
import { TokenStorageService } from './shared/services/token-storage.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ArcElement, BarController, BarElement, CategoryScale, Chart, DoughnutController, Legend, LinearScale, LineController, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { NgxPermissionsService } from 'ngx-permissions';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({'max-height': '500px', opacity: '1', visibility: 'visible'})),
      state('out', style({'max-height': '0px', opacity: '0', visibility: 'hidden'})),
      transition('in => out', [group([
          animate('400ms ease-in-out', style({opacity: '0'})),
          animate('400ms ease-in-out', style({'max-height': '0px'})),
          animate('700ms ease-in-out', style({visibility: 'hidden'}))
        ]
      )]),
      transition('out => in', [group([
          animate('1ms ease-in-out', style({visibility: 'visible'})),
          animate('600ms ease-in-out', style({'max-height': '500px'})),
          animate('800ms ease-in-out', style({opacity: '1'}))
        ]
      )])
    ]),
  ]
})
export class AppComponent implements OnInit {

  @ViewChild('helpModal', {static: true}) helpModal: TemplateRef<any>;

  public languages: string[] = ['de', 'en', 'it', 'es'];
  private defaultLang = 'de';

  public news: NewsText[] = [];
  public currentIndex = 0;
  public size = 50;
  public offset: SafeStyle;
  public isLoading = true;
  public appConfiguration: Configuration[];

  constructor(public translate: TranslateService, private router: Router, private appService: AppService, public tokenStorageService: TokenStorageService, private sanitizer: DomSanitizer, private permissionsService: NgxPermissionsService, private dialog: MatDialog) {
    this.offset = this.sanitizer.bypassSecurityTrustStyle(`calc(50% - ${this.size / 2}px)`);
    // set language
    const browserLang = localStorage.getItem('lang') || translate.getBrowserLang();
    const language = this.languages.includes(browserLang) ? browserLang : this.defaultLang;

    this.translate.addLangs(this.languages);
    this.translate.setDefaultLang(this.defaultLang);
    this.translate.use(language);

    moment.locale(language);

    this.appConfiguration = this.appService.getConfigurations();

    this.appService.isLoading.subscribe((value: boolean) => {
      Promise.resolve(null).then(() => this.isLoading = value);
    });

    // set current index
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        const url = val.url.split('/').slice(1).join('/').replace(/ *\([^)]*\) */g, '');
        this.currentIndex = this.appConfiguration.map((c: Configuration) => c.route).indexOf(url);
      }
    });

    Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, DoughnutController, ArcElement, LineController, LineElement, PointElement);

    this.appService.loggedIn.subscribe((isLoggedIn: boolean) => isLoggedIn && this.loadNews());
  }

  ngOnInit(): void {
    if (this.tokenStorageService.getToken()) {
      const user = this.tokenStorageService.getUser();
      this.permissionsService.loadPermissions(user.securityRoles.split(','));
      this.loadNews();
    }
  }

  public loadNews(): void {
    const today = moment().set({h: 0, m: 0, s: 0, ms: 0}).toISOString();
    const params = new HttpParams().set('expiry_tstamp[after]', today).set('release_tstamp[before]', today).set('status', 'A');
    this.appService.list('newstexts', params).subscribe(
      (result: APIPlatformPagedCollection) => {
        this.news = result['hydra:member'];
      },
      () => null,
      () => this.appService.isLoading.next(false)
    );
  }

  public dismiss(i: number): void {
    this.news.splice(i, 1);
  }

  public openDialog(): void {
    this.dialog.open(this.helpModal);
  }

  public onClick(index: number): void {
    this.currentIndex = index;
  }

  /** handle action clicks */
  public openRoute(route: string, endpoint?: any): void {
    this.router.navigate(endpoint ? [{outlets: {edit: `${route}/${endpoint}`}}] : [route]).then();
  }

  public onLogout(): void {
    this.tokenStorageService.signOut();
    this.router.navigate(['login']).then();
  }

  public onChangeLanguage(lang: string): void {
    this.translate.use(lang);
    moment.locale(lang);
    localStorage.setItem('lang', String(lang));
  }

}
