import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppService, Order, OrderItem } from '../../../../../shared/services/app.service';
import { combineLatest, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-edit-orders',
  templateUrl: './edit-orders.component.html',
  styleUrls: ['./edit-orders.component.scss']
})

export class EditOrdersComponent implements OnInit {

  public order: Order;
  public products: OrderItem[];

  constructor(private appService: AppService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.appService.isLoading.next(true);
    this.route.params.subscribe((params: Params) => {
      const subscriptions: Observable<any>[] = [this.appService.get('orders', params.uuid), this.appService.list('order_items', new HttpParams().set('order.id', params.uuid))];
      combineLatest(subscriptions).subscribe(
        (result: any[]) => {
          this.order = result[0] as Order;
          this.order.id = Number(this.appService.getIdFromUrlString(this.order['@id']));
          this.products = result[1]['hydra:member'] as OrderItem[];
        },
        () => null,
        () => this.appService.isLoading.next(false)
      );
    });
  }

  public equals(): boolean {
    return this.order.billing_salutation === this.order.delivery_salutation &&
      this.order.billing_first_name === this.order.delivery_first_name &&
      this.order.billing_last_name === this.order.delivery_last_name &&
      this.order.billing_street === this.order.delivery_street &&
      this.order.billing_postal_code === this.order.delivery_postal_code &&
      this.order.billing_city === this.order.delivery_city &&
      this.order.billing_country_code === this.order.delivery_country_code &&
      this.order.billing_email === this.order.delivery_email &&
      this.order.billing_phone === this.order.delivery_phone;
  }

  public getOrderID(): string | number {
    return this.order.identifier.includes('--') ? this.order.identifier.split('--')[0] : this.order.id;
  }

}
