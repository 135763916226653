import { Component, OnInit } from '@angular/core';
import { GuidesComponent } from '../guides.component';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { APIPlatformPagedCollection, Book, Country } from '../../../../../shared/services/app.service';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-add-guide',
  templateUrl: '../guides.component.html',
  styleUrls: ['../guides.component.scss']
})
export class AddGuideComponent extends GuidesComponent implements OnInit {

  private timeStamp = new Date().toISOString();
  private guideObject = {
    oldId: null,
    book: null,
    serial_nr: null,
    salutation_id: null,
    first_name: null,
    last_name: null,
    email: null,
    street: null,
    postal_code: null,
    city: null,
    country_id: null,
    created_tstamp: this.timeStamp,
    changed_tstamp: this.timeStamp,
    created_by: null,
    changed_by: null,
    created_by_club_tstamp: this.timeStamp,
    changed_by_club_tstamp: this.timeStamp,
    created_by_club: null,
    changed_by_club: null,
    status: null,
    created_by_club_ip: null,
    created_by_club_browser: null,
    changed_by_club_ip: null,
    changed_by_club_browser: null,
    vouchers_cnt: '2',
    activation_code: null,
    is_activated: true,
    activated_tstamp: this.timeStamp,
    used_vouchers_cnt: 0,
    createdAt: this.timeStamp,
    lastModified: this.timeStamp
  };
  private serialControl = this.form.get('serial_nr') as FormControl;
  private emailControl = this.form.get('email') as FormControl;

  ngOnInit(): void {
    this.appService.isLoading.next(true);
    const subscriptions: Observable<any>[] = [this.appService.getBooks(true), this.appService.getCountries()];
    combineLatest(subscriptions).subscribe(
      (result: APIPlatformPagedCollection[]) => {
        this.books = result[0]['hydra:member'];
        this.countries = result[1]['hydra:member'] as Country[];
        this.setCurrentBook(this.books[0]);
        this.observeSerial();
        // this.observeEmail();
        this.appService.isLoading.next(false);
      }
    );
  }

  public onSubmit(): void {
    if (this.form.valid && this.form.touched) {
      this.appService.isLoading.next(true);
      const guide = {...this.guideObject, ...this.form.getRawValue()};
      guide.book = (guide.book as Book)['@id'];
      guide.serial_nr = +guide.serial_nr;
      combineLatest([this.checkSerial(guide.serial_nr, guide.book)]).subscribe(
        (result: APIPlatformPagedCollection[]) => {
          if (result[0]['hydra:totalItems'] > 0) {
            this.serialControl.setErrors({alreadyExists: true});
          } else {
            this.addGuide(guide);
          }
        },
        (error) => console.log(error),
        () => this.appService.isLoading.next(false)
      );
    }
  }

  private addGuide(guide: any): void {
    this.appService.post('guides', guide).subscribe(
      () => this.appService.openSnackBar('your data has been created successfully'),
      () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
      () => {
        this.form.markAsUntouched();
        this.form.markAsPristine();
        this.appService.isLoading.next(false);
        this.dialog.closeAll();
      }
    );
  }

  private observeSerial(): void {
    fromEvent(this.serialInput.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      filter(res => res >= this.idRange.serial_nr_from && res <= this.idRange.serial_nr_to),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((serial: string) => {
      this.appService.isLoading.next(true);
      const errors = this.serialControl.errors;
      const bookId = this.appService.getIdFromUrlString((this.form.getRawValue().book as Book)['@id']);
      this.checkSerial(serial, bookId).subscribe(
        (result: APIPlatformPagedCollection) => {
          if (result['hydra:totalItems'] > 0) {
            this.serialControl.setErrors({...errors, alreadyExists: true});
            this.form.get('activation_code')?.reset();
          } else {
            this.setActivationCode();
          }
        },
        (error) => console.log(error),
        () => this.appService.isLoading.next(false),
      );
    });
  }

  private observeEmail(): void {
    fromEvent(this.emailInput.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      filter(res => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((email: string) => {
      this.appService.isLoading.next(true);
      const errors = this.emailControl.errors;
      const bookId = this.appService.getIdFromUrlString((this.form.getRawValue().book as Book)['@id']);
      this.checkEmail(email, bookId).subscribe(
        (result: APIPlatformPagedCollection) => (result['hydra:totalItems'] > 0) ? this.emailControl.setErrors({...errors, alreadyExists: true}) : null,
        (error) => console.log(error),
        () => this.appService.isLoading.next(false),
      );
    });
  }

  private checkEmail(email: string, bookId: string): Observable<APIPlatformPagedCollection> {
    return this.appService.list('guides', new HttpParams().set('email', email).set('book', bookId));
  }

  private checkSerial(serial: string, bookId: string): Observable<APIPlatformPagedCollection> {
    return this.appService.list('guides', new HttpParams().set('serial_nr', serial).set('book', bookId));
  }

}
