import { AfterViewInit, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { APIPlatformPagedCollection, AppService, Book, BookGolfClub, Country, Guide } from '../../../../../shared/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: string[] = ['id', 'name', 'postal_code', 'city', 'country_id', 'vouchersCnt', 'usedVouchersCnt', 'remainingVouchersCnt'];
  public dataSource: MatTableDataSource<never>;

  private dataSubscription: Subscription;
  private routeSubscription: Subscription;
  private countryList: Country[] = [];

  public guide: Guide;
  public book: Book;
  public voucher: any[];
  public defaultPageSizeOptions = [10, 15, 30, 50, 100];
  public defaultPageSize: number;
  public resultsLength: number | undefined;

  constructor(private route: ActivatedRoute, private router: Router, @Inject(LOCALE_ID) private locale: string, private changeDetector: ChangeDetectorRef, private appService: AppService, private translate: TranslateService) {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.params.subscribe((params: Params) => {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      this.defaultPageSize = Number(localStorage.getItem('pageSize')) || this.defaultPageSizeOptions[0];
      this.changeDetector.detectChanges();

      const subscriptions: Observable<any>[] = [
        this.appService.get('guides', params.uuid),
        this.appService.getCountries(),
        this.appService.list('voucher_usages', new HttpParams().set('guide', params.uuid).set('itemsPerPage', '1000').set('status', 'A'))
      ];

      combineLatest(subscriptions).subscribe(
        (result: any[]) => {
          this.guide = result[0] as Guide;
          this.countryList = result[1]['hydra:member'] as Country[];
          this.voucher = result[2]['hydra:member'];
        },
        () => null,
        () => {
          this.appService.get('books', this.appService.getIdFromUrlString(this.guide.book)).subscribe((book: Book) => this.book = book);
          this.initTable(this.guide.book);
        });
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  /** inits table data   */
  private initTable(book: string): void {
    this.appService.isLoading.next(true);
    this.paginator.firstPage();
    this.paginator.pageSize = this.defaultPageSize;
    this.sort.active = this.displayedColumns[0];
    this.sort.direction = 'desc' as SortDirection;

    const params = new HttpParams().set('book[]', book).set('status[]', 'A').set('itemsPerPage', '1000');

    this.appService.list('book_golf_clubs', params).subscribe(
      (result: APIPlatformPagedCollection) => {
        result['hydra:member'].map((bgc: BookGolfClub) => {
          bgc.name = bgc.golfClub.name;
          bgc.postal_code = bgc.golfClub.postal_code;
          bgc.city = bgc.golfClub.city;
          bgc.country_id = bgc.golfClub.country_id;
          const voucherUsageData = this.voucher.filter((v: any) => (v.bookGolfClub as BookGolfClub).golfClub['@id'] === bgc.golfClub['@id']);
          bgc.usedVouchersCnt = voucherUsageData.length > 1 ? voucherUsageData.reduce((a, b) => a.used_quantity + b.used_quantity) : (voucherUsageData[0]?.used_quantity || 0);
          bgc.remainingVouchersCnt = String(Number(bgc.vouchersCnt) - Number(bgc.usedVouchersCnt));
        });
        this.resultsLength = result['hydra:totalItems'];
        this.dataSource = new MatTableDataSource(result['hydra:member']);
      },
      (error: HttpErrorResponse) => console.log(error),
      () => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.appService.isLoading.next(false);
      });
  }

  /** reset page index */
  public resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  /** handle table filter */
  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** get country object */
  public getCountry(column: string | any, row: string[]): Country | undefined {
    return this.countryList.find((c: Country) => c.id === Number(row[column]));
  }

  public onSubmit(): void {

  }

}
