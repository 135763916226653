<div class="container mat-elevation-z0">
  <div *ngIf="dataSource" class="filter-container" style="padding: 20px 0;">
    <mat-form-field>
      <mat-label>{{ 'filter guide number' | translate }}</mat-label>
      <input [(ngModel)]="filter" matInput (keyup)="applyFilter($event)" [placeholder]="'e.g.:' | translate: {value: '12345 ' + ('or' | translate:{value: ('email' | translate)})}">
      <button mat-button *ngIf="filter" matSuffix mat-icon-button aria-label="Clear" (click)="filter=''; filterChanged.emit('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'used_tstamp' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [(ngModel)]="startDate" [placeholder]="'date_from' | translate" #dateRangeStart>
        <input matEndDate [(ngModel)]="endDate" [placeholder]="'date_until' | translate" #dateRangeEnd (dateChange)="dateChanged.emit({start: dateRangeStart.value, end: dateRangeEnd.value})">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'filter guides' | translate }}</mat-label>
      <mat-select [(value)]="bookId" (selectionChange)="bookChanged.emit($event.value)">
        <mat-option *ngIf="bookId" value="-">{{ 'show all' | translate }}</mat-option>
        <mat-option *ngFor="let book of booksList" [value]="book.id">{{ book.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-stroked-button (click)="clearFilter()">
      <mat-icon [matTooltip]="'clear filter' | translate | uppercase" class="menu-icon">clear</mat-icon>
    </button>

  </div>

  <table mat-table [dataSource]="dataSource" class="table striped" matSort (matSortChange)="resetPaging()">

    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | translate | uppercase }}</th>
      <td mat-cell *matCellDef="let row" [innerHTML]="formatRow(column, row)"></td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDialog(voucherModal, row)"></tr>

  </table>

  <!-- Row shown when there is no matching data. -->
  <div *ngIf="dataSource && dataSource.data.length <= 0" style="height: 100px; display: flex; align-items: center; justify-content: center;">
    <span>{{ 'No data matching the filter' | translate }}</span>
  </div>

  <mat-paginator [length]="resultsLength" [pageSizeOptions]="defaultPageSizeOptions" [pageSize]="defaultPageSize" showFirstLastButtons></mat-paginator>
</div>

<button mat-fab color="primary" class="scroll-button" *ngIf="showScrollButton" (click)="scrollToTop()">
  <mat-icon>keyboard_arrow_up</mat-icon>
</button>


<ng-template #voucherModal let-data>
  <h1 mat-dialog-title>{{ 'edit voucher' | translate }}</h1>
  <mat-dialog-content>

    <mat-list>
      <mat-list-item *ngIf="data.guide.email">
        <mat-icon mat-list-icon>person</mat-icon>
        {{ data.guide.last_name }}, {{ data.guide.first_name}} ({{ data.guide.email }})
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>menu_book</mat-icon>
        {{ data.book }}
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>event</mat-icon>
        {{ 'devaluation_date' | translate }}: {{ data.used_tstamp | date:"dd.MM.YYYY" }}
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>group</mat-icon>
        {{ 'single_player' | translate }}:
        <mat-list-item>
          <mat-radio-group [(ngModel)]="data.single_player">
            <mat-radio-button class="voucher-radio-button" [value]="true" color="primary">{{ 'true' | translate }}</mat-radio-button>
            <mat-radio-button class="voucher-radio-button" [value]="false" color="primary">{{ 'false' | translate }}</mat-radio-button>
          </mat-radio-group>
        </mat-list-item>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>golf_course</mat-icon>
        {{ 'used_vouchers_cnt' | translate }}: {{ data.used_quantity }} / {{ data.bookGolfClub.vouchersCnt }}
      </mat-list-item>

      <mat-list-item>
        <mat-radio-group [(ngModel)]="data.used_quantity">
          <mat-radio-button class="voucher-radio-button" [value]="0" color="primary">0 Voucher (Storno)</mat-radio-button>
          <mat-radio-button class="voucher-radio-button" color="primary" *ngFor="let v of getVoucherArray(data.bookGolfClub.vouchersCnt)" [value]="v">{{ 'validate voucher' | translate: {value: v}  }}</mat-radio-button>
        </mat-radio-group>
      </mat-list-item>

    </mat-list>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase  }}</span>
      </span>
    </button>

    <span class="spacer"></span>

    <button mat-dialog-close mat-stroked-button color="primary" type="submit" (click)="updateVoucher(data['@id'], data.used_quantity, data.single_player, data)">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">save</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</ng-template>
