<form [formGroup]="form" (submit)="onSubmit(false)" autocomplete="off">

  <mat-dialog-content class="mat-typography" fxLayout="column">
    <mat-horizontal-stepper>
      <mat-step>
        <ng-template matStepLabel>{{ 'club data' | translate }}</ng-template>

        <span fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
          <mat-form-field fxFlex>
            <mat-label>{{ 'login number' | translate }}</mat-label>
            <input #login type="text" matInput formControlName="login" required (keyup)="form.get('club_nr')?.patchValue(login.value); form.get('user.username')?.patchValue(login.value)"/>
            <mat-error align="end" *ngIf="form.controls.login">{{ 'login number already exists' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>{{ 'password' | translate }}</mat-label>
            <input #password [type]="editMode ? 'password' : 'text'" matInput formControlName="pwd" required [readonly]="editMode"/>
            <button *ngIf="editMode" type="button" mat-icon-button matSuffix (click)="password.value = ''; password.type = 'text'; password.readOnly = false">
              <mat-icon>lock_reset</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field fxFlex="10">
            <mat-label>{{ 'status' | translate }}</mat-label>
            <mat-select formControlName="status" required (selectionChange)="form.get('user.active')?.patchValue($event.value === 'A')">
              <mat-option *ngFor="let status of ['A', 'D', 'O']" [value]="status">{{ status | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

          <button fxFlex mat-stroked-button color="warn" type="button" class="form-button" [disabled]="!editMode || form.invalid || !form.dirty || !form.touched || !form.get('pwd')?.dirty || !form.get('pwd')?.value || !form.get('login')?.value || emailExists" (click)="openDialog(setPassword, {data: {pwd:form.get('pwd')?.value, login: form.get('login')?.value}, disableClose: true})">
            <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
              <span fxShow fxHide.lt-lg>{{ 'reset password' | translate | uppercase }}</span>
              <mat-icon class="menu-icon">lock_reset</mat-icon>
            </span>
          </button>
        </span>
        <span fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
          <mat-form-field fxFlex>
            <mat-label>{{ 'name' | translate }}</mat-label>
            <input type="text" matInput formControlName="name" required/>
          </mat-form-field>

          <mat-form-field formGroupName="user" fxFlex>
            <mat-label>{{ 'personal email' | translate }}</mat-label>
            <input type="email" matInput #login_email formControlName="email" required (keyup)="form.get('person_email')?.patchValue(login_email.value)"/>
            <mat-error align="end" *ngIf="getNestedForm('user').controls.email.getError('email') || getNestedForm('user').controls.email.getError('pattern')">{{ 'please enter a valid email' | translate }}</mat-error>
            <mat-hint class="mat-error" align="end" *ngIf="emailExists">{{ 'email already exists' | translate }}</mat-hint>
            <a [href]="'mailto:' + login_email.value" target="_blank" mat-button *ngIf="login_email.value.length" matSuffix mat-icon-button><mat-icon>email</mat-icon></a>
          </mat-form-field>

          <mat-form-field formGroupName="user" fxFlex="10">
            <mat-label>{{ 'securityRoles' | translate }}</mat-label>
            <mat-select formControlName="securityRoles" required>
              <mat-option *ngFor="let role of ['ROLE_USER', 'ROLE_ADMIN']" [value]="role">{{ role | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

         <button fxFlex mat-stroked-button color="warn" type="button" class="form-button" [disabled]="!editMode || getNestedForm('user').controls.email.invalid || emailExists" (click)="openDialog(sendEmailModal, {email: login_email.value, disableClose: true})">
            <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
              <span fxShow fxHide.lt-lg>{{ 'send reset mail' | translate | uppercase }}</span>
              <mat-icon class="menu-icon">forward_to_inbox</mat-icon>
            </span>
         </button>

        </span>

        <mat-divider style="margin: 20px 0"></mat-divider>

        <span fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
          <mat-form-field fxFlex="20">
          <mat-label>{{ 'salutation.text' | translate }}</mat-label>
          <mat-select formControlName="salutation_id">
            <mat-option *ngFor="let salutation of [1,2]" [value]="salutation">{{ 'salutation.' + salutation | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

          <mat-form-field fxFlex="20">
          <mat-label>{{ 'title' | translate }}</mat-label>
          <input type="text" matInput formControlName="title"/>
        </mat-form-field>

          <mat-form-field fxFlex>
          <mat-label>{{ 'first_name' | translate }}</mat-label>
          <input type="text" matInput formControlName="first_name"/>
        </mat-form-field>

          <mat-form-field fxFlex>
          <mat-label>{{ 'last_name' | translate }}</mat-label>
          <input type="text" matInput formControlName="last_name"/>
        </mat-form-field>
        </span>
        <span fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
          <mat-form-field fxFlex>
            <mat-label>{{ 'postal_code' | translate }}</mat-label>
            <input type="text" #postalCode matInput formControlName="postal_code"/>
            <mat-error align="end" *ngIf="form.controls.postal_code.errors?.pattern">{{ 'please enter a valid postal_code' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>{{ 'city' | translate }}</mat-label>
            <input type="text" matInput formControlName="city"/>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>{{ 'country_id' | translate }}</mat-label>
            <mat-select formControlName="country_id">
              <mat-option *ngFor="let country of countries" [value]="country.id">
                <span class="flag-icon flag-icon-{{ country?.code?.toLowerCase() }}"></span>
                {{country.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>

        <mat-divider style="margin: 20px 0"></mat-divider>

        <span fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">

          <mat-form-field fxFlex>
            <mat-label>{{ 'club email' | translate }}</mat-label>
            <input type="email" matInput #email formControlName="email" required/>
            <mat-error align="end" *ngIf="form.controls.email.getError('email') || form.controls.email.getError('pattern')">{{ 'please enter a valid email' | translate }}</mat-error>
            <a [href]="'mailto:' + email.value" target="_blank" mat-button *ngIf="email.value.length" matSuffix mat-icon-button><mat-icon>email</mat-icon></a>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>{{ 'website' | translate }}</mat-label>
            <input type="text" matInput #website formControlName="website"/>
            <a [href]="website.value" target="_blank" mat-button *ngIf="website.value.length" matSuffix mat-icon-button>
              <mat-icon>open_in_new</mat-icon>
            </a>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>{{ 'voucher_cnt' | translate }}</mat-label>
            <mat-select formControlName="vouchers_cnt" required>
<!--              <mat-option value="0">{{ 'adopt setting from club data' | translate }}</mat-option>-->
              <mat-option *ngFor="let voucher of vouchers" [value]="voucher">{{voucher}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>{{ 'single_player' | translate }}</mat-label>
            <mat-select formControlName="single_player">
              <mat-option *ngFor="let player of [true, false]" [value]="player">{{ (player ? 'true' : 'false') | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

        </span>

      </mat-step>
      <mat-step *ngIf="dataSource">
        <ng-template matStepLabel>{{ 'associated guides' | translate }}</ng-template>

        <table mat-table [dataSource]="dataSource" class="table striped">
          <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column" [ngSwitch]="column">
            <th mat-header-cell *matHeaderCellDef>{{ (column === 'status' ? 'A' : column) | translate | uppercase }}</th>

            <!-- CHECKBOX COLUMN -->
            <ng-container *ngSwitchCase="'status'">
              <td mat-cell *matCellDef="let row; index as i">
                <mat-checkbox [checked]="row[column] === 'A'" color="primary" (change)="onChangeRow($event, row, column, i)"></mat-checkbox>
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="'cover_image'">
              <td mat-cell *matCellDef="let row; index as i">
                <img class="cover_image" [src]="row[column]" alt=""/>
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="'single_player'">
              <td mat-cell *matCellDef="let row; index as i">
                <mat-checkbox [checked]="row[column]" color="primary" (change)="onChangeRow($event, row, column, i)"></mat-checkbox>
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="'vouchers_cnt'">
              <td mat-cell *matCellDef="let row; index as i">
                <mat-form-field appearance="outline" style="max-height: 58px; width: 50%; text-align: center;">
                  <mat-select [value]="row[column] || 0" (selectionChange)="onChangeRow($event, row, column, i)">
                    <mat-option *ngFor="let voucher of vouchers" [value]="voucher">{{voucher}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <!-- DEFAULT COLUMNS -->
            <ng-container *ngSwitchDefault>
              <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
            </ng-container>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
      </span>
    </button>

    <span class="spacer"></span>

    <button mat-stroked-button color="accent" type="submit" [disabled]="form.invalid || !form.dirty || !form.touched || emailExists">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">save_alt</mat-icon>
      </span>
    </button>

    <button *ngIf="editMode" mat-dialog-close mat-stroked-button color="primary" type="submit" [disabled]="form.invalid || !form.dirty || !form.touched || emailExists">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save and close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
    </button>

  </mat-dialog-actions>
</form>

<ng-template #setPassword let-data>
  <h1 mat-dialog-title>{{ 'send login data' | translate }}?</h1>
  <mat-dialog-content>
    <p>{{ 'login' | translate}}: {{ data.data.login }}</p>
    <p>{{ 'password' | translate}}: {{ data.data.pwd }}</p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
      </span>
    </button>

    <span class="spacer"></span>

    <button mat-stroked-button color="primary" (click)="onSubmit(true)" mat-dialog-close>
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'reset' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">lock_reset</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #sendEmailModal let-data>
  <h1 mat-dialog-title>{{ 'send reset mail' | translate }}?</h1>
  <mat-dialog-content>
    <p>{{ 'an email will be sent to following address' | translate }}: {{ data.email }}</p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
      </span>
    </button>

    <span class="spacer"></span>

    <button mat-stroked-button color="primary" (click)="sendLogin(data.email)" mat-dialog-close>
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'send' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">forward_to_inbox</mat-icon>
      </span>
    </button>
  </mat-dialog-actions>
</ng-template>
