<div class="content" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start stretch" fxLayoutGap="20px">
  <div fxFlex="70">
    <ul class="day-grid">
      <li class="list-headline" *ngFor="let weekday of weekDays">{{ weekday }}</li>
      <li *ngFor="let day of dateRage; index as x;">
        <mat-card class="mat-elevation-z0" [ngClass]="day.format('MM') === (date | date: 'MM') ? 'currentMonth' : ''">
          <mat-card-content>
            <button mat-mini-fab class="mat-elevation-z0" [color]="day.format('MM') === (date | date: 'MM') ? isToday(day) ? 'primary' : null : null">{{ day.format('D') }}</button>
          </mat-card-content>
        </mat-card>
      </li>
    </ul>
  </div>
  <div fxFlex="30">
    <ng-container *ngFor="let n of news">
      <mat-card class="news-card mat-elevation-z0">
        <mat-card-header>
          <button color="primary" class="mat-elevation-z0" mat-mini-fab mat-card-avatar>
            <mat-icon>golf_course</mat-icon>
          </button>
          <mat-card-title>{{ n.title }}</mat-card-title>
          <mat-card-subtitle>{{ n.release_tstamp | date }} - {{ n.expiry_tstamp | date }}</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <div [innerHTML]="n.txt"></div>
        </mat-card-content>
      </mat-card>
      <mat-divider></mat-divider>
    </ng-container>
  </div>
</div>

