import { Component, OnInit } from '@angular/core';
import { NewsComponent } from '../news.component';
import { Params } from '@angular/router';

@Component({
  selector: 'app-edit-news',
  templateUrl: '../news.component.html',
  styleUrls: ['../news.component.scss']
})
export class EditNewsComponent extends NewsComponent implements OnInit {

  private params: Params;

  ngOnInit(): void {
    this.editMode = true;
    this.appService.isLoading.next(true);
    this.route.params.subscribe((params: Params) => {
      this.params = params;

      this.appService.get(params.route, params.uuid).subscribe((news: any) => {
        Object.keys(news).map((key: string) => this.form.patchValue({[key]: news[key]}));
        this.appService.isLoading.next(false);
      });
    });
  }

  public onSubmit(): void {
    if (this.form.valid && this.form.touched) {
      this.appService.isLoading.next(true);
      const news = this.form.getRawValue();
      this.appService.patch(this.params.route, this.params.uuid, news).subscribe(
        () => this.appService.openSnackBar('Daten wurden erfolgreich gespeichert.'),
        () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
        () => {
          this.form.markAsUntouched();
          this.form.markAsPristine();
          this.appService.isLoading.next(false);
        }
      );
    }
  }

}
