import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { APIPlatformPagedCollection, AppService, Book, GolfClub } from '../../../../shared/services/app.service';
import { HttpParams } from '@angular/common/http';
import moment, { Moment } from 'moment';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { ChartjsComponent } from '@ctrl/ngx-chartjs';
import { VoucherUsageData } from '../../../frontend/statistics/detail/detail.component';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-voucher-usage',
  templateUrl: './voucher-usage.component.html',
  styleUrls: ['./voucher-usage.component.scss']
})
export class VoucherUsageComponent implements OnInit {

  @ViewChild('ref0', {static: false}) ref0!: ChartjsComponent;

  public golfClubCtrl = new FormControl();
  public golfClubs: GolfClub[];
  public isLoading = false;

  public today: Moment = moment();
  public years: number[] = [];
  public months: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  public startDate: Moment = moment().startOf('month');
  public endDate: Moment = moment().endOf('month');
  public dayRange: Moment[] = [];
  public dailyUsageData: ChartData = {datasets: []};
  public options: ChartOptions = {responsive: true, plugins: {legend: {display: true}}, scales: {xAxes: {stacked: true}, yAxes: {stacked: true}}};
  public dateChanged: EventEmitter<{ year: number, month: number }> = new EventEmitter();

  public singleUsage: number;
  public multiUsage: number;

  private books: Book[];
  private golfClubId = '';
  private backgroundColor = ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)'];
  private borderColor = ['rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)'];

  constructor(private appService: AppService, public translate: TranslateService) {
    this.dateChanged.subscribe((d: { year: number, month: number }) => {
      this.startDate = moment().year(d.year).month(d.month).startOf('month');
      this.endDate = moment().year(d.year).month(d.month).endOf('month');
      this.initData();
    });
  }

  ngOnInit(): void {
    this.getGolfClubs();
    this.appService.isLoading.next(true);
    const yearRange: number[] = [];
    this.appService.getBooks(true).subscribe((result: APIPlatformPagedCollection) => {
      this.books = result['hydra:member'];
      this.books.map((book: Book, index: number) => {
        yearRange.push(moment(book.active_from).year());
        yearRange.push(moment(book.active_to).year());
      });
      this.years = [...new Set(yearRange)].sort();
      this.initData();
    });
  }

  private getGolfClubs(): void {
    this.golfClubCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.golfClubs = [];
          this.isLoading = true;
        }),
        switchMap(value => this.appService.list('golfclubs', new HttpParams().set('status[]', 'A').set('search', value)).pipe(finalize(() => this.isLoading = false))))
      .subscribe((data: APIPlatformPagedCollection) => this.golfClubs = data['hydra:member']);
  }

  private initData(): void {
    this.appService.isLoading.next(true);
    this.dailyUsageData = {datasets: []};
    this.dayRange = this.appService.getRangeOfDates(this.startDate, this.endDate, 'day');
    const param = new HttpParams().set('after_date', this.startDate.toISOString()).set('before_date', this.endDate.toISOString()).set('golf_club_id', this.golfClubId);
    this.appService.list('custom/daily_voucher_usage_total', param).subscribe(
      (result: APIPlatformPagedCollection) => {
        const values: VoucherUsageData[] = result['hydra:member'];
        this.singleUsage = values.reduce((a: number, b: VoucherUsageData) => a + (b.single_player || 0), 0);
        this.multiUsage = values.reduce((a: number, b: VoucherUsageData) => a + (b.multi_player || 0), 0);
        this.dailyUsageData.labels = this.dayRange.map((date: Moment) => date.format('dd DD.MM'));

        const books: Book[] = this.books.filter((b: Book) => this.startDate.year() >= moment(b.active_from).year() && this.endDate.year() <= moment(b.active_to).year());
        books.map((book: Book, index: number) => {
          this.dailyUsageData.datasets.push({label: book.name, data: [], backgroundColor: this.backgroundColor[index + 1], borderColor: this.borderColor[index + 1], borderWidth: 1});
          const bookData: VoucherUsageData[] = values.filter((value: VoucherUsageData) => value.name === book.name);
          this.dayRange.map((date: Moment) => {
            (this.dailyUsageData.datasets.find((set: ChartDataset) => set.label === book.name)?.data || []).push(bookData.filter(v => moment(v.used_tstamp).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')).reduce((a: number, b: VoucherUsageData) => a + (b.sum || 0), 0));
          });
        });
        this.ref0.chartInstance.update();
      },
      () => null,
      () => this.appService.isLoading.next(false)
    );
  }

  public getMonth(month: number): string {
    return moment().month(month).format('MMMM');
  }

  public clubSelected(golfClub: GolfClub): void {
    if (golfClub && golfClub['@id']) {
      this.golfClubId = this.appService.getIdFromUrlString(golfClub['@id']);
      this.initData();
    }
  }

  public displayFn(golfClub: GolfClub): string {
    return golfClub && golfClub.name ? golfClub.name : '';
  }

  public reset(): void {
    this.golfClubId = '';
    this.initData();
  }
}
