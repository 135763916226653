<h4 mat-dialog-title>{{ 'multi_player' | translate }}: {{ multiUsage || 0 }} | {{ 'single_player' | translate }}: {{ singleUsage || 0  }} | {{ 'total' | translate }}: {{ (multiUsage + singleUsage) || 0  }}</h4>
<mat-dialog-content class="mat-typography">

  <div class="filter-container">
    <mat-form-field>
      <mat-label>{{ 'select year' | translate}}</mat-label>
      <mat-select #year [value]="today.year()" (selectionChange)="dateChanged.emit({year: $event.value, month: month.value})">
        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'select month' | translate}}</mat-label>
      <mat-select #month [value]="today.month()" (selectionChange)="dateChanged.emit({year: year.value, month: $event.value})">
        <mat-option *ngFor="let month of months" [value]="month">{{ getMonth(month) }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'filter golf club' | translate }}</mat-label>
      <input #value type="text" matInput [formControl]="golfClubCtrl" [matAutocomplete]="auto">
      <button mat-button *ngIf="value.value" matSuffix mat-icon-button aria-label="Clear" (click)="value.value = ''; reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="clubSelected($event.option.value)">
        <mat-option *ngIf="isLoading" class="is-loading">
          <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
        </mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let option of golfClubs" [value]="option">
            {{option.name}}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <ngx-chartjs #ref0 type="bar" [data]="dailyUsageData" [options]="options"></ngx-chartjs>
</mat-dialog-content>
