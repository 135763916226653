<h4 mat-dialog-title *ngIf="guide && book">{{ 'for' | translate }} {{ guide?.first_name }} {{ guide?.last_name }} - {{ book?.name }}</h4>
<mat-dialog-content class="mat-typography">

  <div class="filter-container">
    <mat-form-field>
      <mat-label>{{ 'filter text' | translate }}</mat-label>
      <input #filter matInput (keyup)="applyFilter($event)" [placeholder]="'filter data' | translate">
      <button mat-button *ngIf="filter.value" matSuffix mat-icon-button aria-label="Clear" (click)="filter.value = ''; dataSource.filter = ''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <table mat-table [dataSource]="dataSource" class="table" matSort matSortDisableClear (matSortChange)="resetPaging()">
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column" [ngSwitch]="column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | translate | uppercase }}</th>

      <!-- COUNTRY COLUMN -->
      <ng-container *ngSwitchCase="'country_id'">
        <td mat-cell *matCellDef="let row">
            <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center" style="height: 58px;">
              <span class="flag-icon flag-icon-{{ getCountry(column, row)?.code?.toLowerCase() }}" [matTooltip]="(getCountry(column, row)?.name || '') | translate"></span>
              <span fxShow fxHide.lt-lg>{{ (getCountry(column, row)?.name || '') | translate }}</span>
            </span>
        </td>
      </ng-container>

      <!-- DEFAULT COLUMNS -->
      <ng-container *ngSwitchDefault>
        <td mat-cell *matCellDef="let row">{{ row[column] }}</td>
      </ng-container>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [length]="resultsLength" [pageSizeOptions]="defaultPageSizeOptions" [pageSize]="defaultPageSize" showFirstLastButtons></mat-paginator>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close color="warn">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">close</mat-icon>
        <span fxShow fxHide.lt-sm>{{ 'close' | translate | uppercase }}</span>
      </span>
  </button>

  <span class="spacer"></span>

  <button mat-stroked-button color="accent" type="submit">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">save_alt</mat-icon>
      </span>
  </button>

  <button mat-dialog-close mat-stroked-button color="primary" type="submit">
      <span fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <span fxShow fxHide.lt-sm>{{ 'save and close' | translate | uppercase }}</span>
        <mat-icon fxHide fxShow.gt-md fxShow.lt-sm class="menu-icon">check</mat-icon>
      </span>
  </button>
</mat-dialog-actions>
