import { Component, OnInit } from '@angular/core';
import { News, NewsComponent } from '../news.component';
import { Params } from '@angular/router';

@Component({
  selector: 'app-delete-news',
  templateUrl: './delete-news.component.html',
  styleUrls: ['../news.component.scss']
})
export class DeleteNewsComponent extends NewsComponent implements OnInit {

  private params: Params;
  public news: News;

  ngOnInit(): void {
    this.editMode = true;
    this.appService.isLoading.next(true);
    this.route.params.subscribe((params: Params) => {
      this.params = params;

      this.appService.get(params.route, params.uuid).subscribe((news: News) => {
        this.news = news;
        this.appService.isLoading.next(false);
      });
    });
  }

  public onSubmit(): void {
    this.appService.isLoading.next(true);
    this.appService.delete(this.params.route, this.params.uuid).subscribe(
      () => this.appService.openSnackBar('your data has been deleted successfully'),
      () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
      () => this.appService.isLoading.next(false)
    );
  }

}
