import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { APIPlatformPagedCollection, AppService, Book, BookGolfClub } from '../../../../../shared/services/app.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { combineLatest, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-assume',
  templateUrl: './assume.component.html',
  styleUrls: ['./assume.component.scss']
})
export class AssumeComponent implements OnInit, OnDestroy {

  private dataSubscription: Subscription = new Subscription();
  private routeSubscription: Subscription = new Subscription();

  public deleteDeprecated = false;
  public form: FormGroup;
  public books: Book[] = [];
  public vouchers: number[] | undefined = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public currentBook: Book | undefined;
  public selectedBook: Book | undefined;
  public deprecatedBookGolfClubIds: number[] = [];
  public currentBookGolfClubIds: number[] = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog, private appService: AppService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      target: new FormControl(null, [Validators.required]),
      origin: new FormControl(null, [Validators.required]),
      voucherCnt: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.params.subscribe((params: Params) => {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      this.appService.getBooks(true).subscribe(
        (result: APIPlatformPagedCollection) => {
          this.books = result['hydra:member'] as Book[];
          this.currentBook = this.books.find((book: Book) => book.id === Number(params.uuid));
          this.form.get('target')?.patchValue(this.currentBook?.id);
        }
      );

      this.appService.list('book_golf_clubs', new HttpParams().set('book', params.uuid).set('itemsPerPage', '1000')).subscribe({
        next: (data: APIPlatformPagedCollection) => data['hydra:member'].map((bgc: BookGolfClub) => bgc.status === 'A' ? this.currentBookGolfClubIds.push(bgc.id) : this.deprecatedBookGolfClubIds.push(bgc.id)),
        error: (error: HttpErrorResponse) => console.log(error)
      });

    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
  }

  public compareObjects(o1: number, o2: number): boolean {
    return o1 === o2;
  }

  public onBookChanged(bookId: number): void {
    this.selectedBook = this.books.find((book: Book) => book.id === bookId);
    this.form.get('voucherCnt')?.patchValue(this.selectedBook?.vouchers_cnt);
  }

  public submit(): void {

    if (this.currentBookGolfClubIds.length) {
      return;
    }

    this.appService.isLoading.next(true);

    if (this.deprecatedBookGolfClubIds.length) {
      const subscriptions: Observable<any>[] = [];
      this.deprecatedBookGolfClubIds.map((id: number) => subscriptions.push(this.appService.delete('book_golf_clubs', String(id), false)));

      combineLatest(subscriptions).subscribe({
        error: () => this.appService.openSnackBar('Ein Fehler ist aufgetreten. Daten konnten nicht gespeichert werden.'),
        complete: () => this.transferClubs()
      });
    } else {
      this.transferClubs();
    }
  }

  private transferClubs(): void {
    this.appService.list('custom/transfereclubs', new HttpParams().set('target', this.form.value.target).set('origin', this.form.value.origin).set('voucherCnt', this.form.value.voucherCnt)).subscribe({
      next: () => this.appService.openSnackBar('Alle Clubs wurden übernommen'),
      error: (error: HttpErrorResponse) => console.log(error),
      complete: () => {
        this.appService.isLoading.next(false);
        this.appService.refresh.emit();
        this.dialog.closeAll();
      }
    });
  }
}
