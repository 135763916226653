import { Component, OnInit, Renderer2 } from '@angular/core';
import { APIPlatformPagedCollection, AppService, AppUser, Book, BookGolfClub, GolfClub, Guide } from '../../shared/services/app.service';
import { TokenStorageService } from '../../shared/services/token-storage.service';
import { HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-frontend',
  templateUrl: './frontend.component.html',
  styleUrls: ['./frontend.component.scss']
})
export class FrontendComponent implements OnInit {

  public guide: Guide[] = [];
  private timeStamp = new Date().toISOString();
  public numbers = [1, 2, 3, 4, 5];
  public dataLoaded = new Subject<boolean>();
  public golfClub: GolfClub;
  public bookGolfClubs: BookGolfClub[] = [];
  public booksList: Book[] = [];
  public loading = true;
  public noGolfClub = false;
  public bgc: BookGolfClub | null;
  public values: number[] = this.numbers.map(() => 0);

  constructor(public tokenStorage: TokenStorageService, public appService: AppService, public renderer: Renderer2, public dialog: MatDialog, public snackBar: MatSnackBar, public router: Router) {

  }

  ngOnInit(): void {
    this.init();
  }

  public init(): void {
    const user: AppUser = this.tokenStorage.getUser();
    this.appService.isLoading.next(true);
    this.appService.get('golfclubs', String(user.golfclub.id)).subscribe(
      (golfClub: GolfClub) => {
        this.golfClub = golfClub;
        this.getBookGolfClubs();
      }
    );
  }

  private getBookGolfClubs(): void {
    if (!this.golfClub) {
      this.appService.isLoading.next(false);
      this.noGolfClub = true;
      return;
    }
    const params = new HttpParams().set('golfClub', this.golfClub['@id']).set('book.status', 'A').set('order[id]', 'desc').set('status', 'A');
    this.appService.list('book_golf_clubs', params).subscribe(
      (result: APIPlatformPagedCollection) => {
        (result['hydra:member'] as BookGolfClub[]).filter((bgc: BookGolfClub) => {
          if (new Date(bgc.book.active_from).toISOString() < this.timeStamp && new Date(bgc.book.active_to).toISOString() >= this.timeStamp) {
            bgc.book.id = Number(this.appService.getIdFromUrlString(bgc.book['@id']));
            this.booksList.push(bgc.book);
            this.bookGolfClubs.push(bgc);
          }
        });
      },
      () => null,
      () => {
        if (this.bookGolfClubs.length <= 1) {
          this.setBGC(this.bookGolfClubs[0]);
        }
        if (this.bookGolfClubs.length === 0) {
          this.noGolfClub = true;
        }
        setTimeout(() => this.loading = false, 1000);
        this.appService.isLoading.next(false);
        this.dataLoaded.next(true);
      });
  }

  public setBGC(bgc: BookGolfClub | null): void {
    if (bgc === null) {
      this.guide = [];
    }
    this.bgc = bgc;
    this.values = this.numbers.map(() => 0);
    if (this.bgc && this.router.url === '/validate') {
      setTimeout(() => {
        const inputElement = this.renderer.selectRootElement(`#id-${this.bgc?.book.id}-1`);
        inputElement.focus();
        inputElement.select();

      }, 200);
    }
  }

}
